import React, { useState, useEffect } from "react";
import {
  Button,
  Container,
  Modal,
  Nav,
  Navbar,
  Dropdown,
} from "react-bootstrap";
import Form from "react-bootstrap/Form";

import NavDropdown from "react-bootstrap/NavDropdown";
import api from "../../../redux/services/api";
import {
  LOGOUT,
  DELETE_ACCOUNT,
  HOST_COUNTRY,
} from "../../../redux/reduxConstants/EndPoints";
import {
  deleteCookie,
  getCookie,
  getIpAddress,
  generateSession,
  setCookie,
} from "../../../lib/helpers";
import * as routes from "../../constant/Routes";

const Header = ({ history }) => {
  const baseUrl = process.env.REACT_APP_API_BASEURL;
  const token = getCookie("token");
  const email = getCookie("email");

  const currentUrl = window.location.href;
  const splitArray = currentUrl.split("/");

  const mykey = "REACT_APP_HOST_COUNTRY_" + splitArray[3].toUpperCase();

  const country_id_prev =
    splitArray && splitArray.length > 4 ? `${process.env[mykey]}` : 1;

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [ipAddress, setIpAddress] = useState();
  const [location, setLocation] = useState("");

  const session_id = getCookie("session_id");

  const [lastActiveTime, setLastActiveTime] = useState(new Date());
  const [countries, setCountries] = useState([
    {
      Host_Country_ID: "1",
      Host_Country_Code: "kuwait",
      Host_Country: "Kuwait",
      Host_Country_Bilingual: "\u0627\u0644\u0643\u0648\u064a\u062a",
      Host_Country_image_Link:
        "https://s3.amazonaws.com/geniesaves.com/dist/assets/images/Country-flags/kuwait-flag.jpg",
      Host_Country_Currency: "KWD",
      Is_Active: "1",
    },
  ]);
  const [Country_ID, setCountryId] = useState(
    getCookie("country_id") ? getCookie("country_id") : 1
  );

  const [country_name, setCountryName] = useState(
    getCookie("country_name") ? getCookie("country_name") : "kuwait"
  );
  // Function to update last active time in sessionStorage
  const updateLastActiveTime = () => {
    sessionStorage.setItem("lastActiveTime", new Date().getTime());
  };

  // Function to check if the page has been inactive for the last 20 minutes
  const checkInactiveTime = () => {
    const lastActiveTime = parseInt(sessionStorage.getItem("lastActiveTime"));

    console.log("lastActiveTime", lastActiveTime);
    if (!lastActiveTime) return; // No previous activity

    const twentyMinutesAgo = new Date().getTime() - 20 * 60 * 1000; // 2 minutes ago

    console.log(twentyMinutesAgo);
    if (lastActiveTime <= twentyMinutesAgo) {
      console.log("The page has been inactive for the last 2 minutes");

      const ip = getIpAddress();

      const p = Promise.resolve(ip);

      p.then((value) => {
        generateSession(value.data.ip, value.data.city);
      }).catch((err) => {
        console.log(err);
      });

      // generateSession(value.data.ip, value.data.city);
    }
  };

  // Effect to listen for visibility changes and update last active time
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        // The application is active
        checkInactiveTime();
        updateLastActiveTime();
        console.log("Application is active");
      } else {
        // The application is not active
        console.log("Application is not active");
      }
    };

    // Add event listener for visibility change
    document.addEventListener("visibilitychange", handleVisibilityChange);

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []); // Empty dependency array to run the effect only once on mount

  // // Effect to check for inactivity every 2 minutes
  // useEffect(() => {
  //   const interval = setInterval(checkInactiveTime, 2 * 60 * 1000); // Check every 2 minutes
  //   return () => clearInterval(interval);
  // }, []);

  useEffect(() => {
    if (!session_id) {
      updateLastActiveTime();
      const ip = getIpAddress();

      const p = Promise.resolve(ip);

      p.then((value) => {
        setIpAddress(value.data.ip);
        setLocation(value.data.city);
      }).catch((err) => {
        console.log(err);
      });
    }
  }, []);

  useEffect(() => {
    if (!session_id && ipAddress) {
      generateSession(ipAddress, location);
    }
  }, [ipAddress]);

  useEffect(() => {
    fetchCountries();
  }, []);

  useEffect(() => {
    // setSelectedValue(
    //   countries &&
    //     countries?.length > 0 &&
    //     countries[countries.findIndex((x) => x.Host_Country_ID == Country_ID)][
    //       "Host_Country"
    //     ]
    // );
    if (countries && countries.length > 0) {
      const selectedCountry = countries.find(
        (x) => x.Host_Country_ID == Country_ID
      );
      if (selectedCountry) {
        setSelectedValue(selectedCountry.Host_Country);
      }
    }
  }, [Country_ID, countries]);

  const fetchCountries = () => {
    api(baseUrl)
      .get(HOST_COUNTRY)
      .then((res) => {
        if (res.data) {
          setCountries(res.data.data);
        }
      })
      .catch((e) => console.log(e));
  };

  const deleteAccount = () => {
    handleClose();
    const headers = {
      Authorization: "Bearer " + token,
    };
    const session_id = getCookie("unique_session_id")
      ? getCookie("unique_session_id")
      : null;
    const user_id = getCookie("user_id") ? getCookie("user_id") : null;

    const country_id = getCookie("country_id") ? getCookie("country_id") : 1;

    api(baseUrl, headers)
      .post(DELETE_ACCOUNT, { user_id, session_id, country_id })
      .then((res) => {
        if (res.data.success) {
          deleteCookie("token");
          deleteCookie("user_id");
          deleteCookie("email");
          window.location.replace(routes.HOME_ROUTE);
        }
      })
      .catch((e) => console.log(e));
  };
  const handleLogout = () => {
    const headers = {
      Authorization: "Bearer " + token,
    };
    api(baseUrl, headers)
      .get(LOGOUT)
      .then((res) => {
        if (res.data.success) {
          deleteCookie("token");
          deleteCookie("user_id");
          deleteCookie("email");
          window.location.replace(routes.HOME_ROUTE);
        }
      })
      .catch((e) => console.log(e));
  };

  const links = [
    {
      name: "Home",
      link: routes.HOME_ROUTE,
    },
    {
      name: "Offers",
      link:
        routes.BASE_SEARCH_ROUTE +
        "?query=&page=1&page_size=20&order_by=3&country_id=" +
        Country_ID,
    },

    {
      name: "Banners",
      link: routes.BASE_BANNER_ROUTE,
    },
    {
      name: "Leaflets",
      link: routes.BASE_LEAFLET_ROUTE,
    },
  ];

  const getAccountName = () => {
    const email = getCookie("email");
    return (
      <>
        <div className="d-user">
          <div className="user_initial">{email ? email.charAt(0) : "U"}</div>
          <span></span>
        </div>
      </>
    );
  };

  const handleLink = () => {
    localStorage.setItem("previousUrl", window.location.href);
  };

  // const handleNationality = (e) => {
  //   setCountryId(e.target.value);
  //   setCookie("country_id", e.target.value);
  // };

  const [isOpenDropdown, setIsOpenDropdown] = useState(false); // State to manage dropdown visibility
  const [selectedValue, setSelectedValue] = useState("Kuwait"); // State to store selected value
  const [countryImage, setCountryImage] = useState("");
  // Function to toggle dropdown visibility
  const showDropdown = () => {
    setIsOpenDropdown(!isOpenDropdown);
  };

  const closeDropdown = () => {
    setIsOpenDropdown(!isOpenDropdown);
  };

  // Function to handle selection of an item
  const handleItemClick = (value) => {
    if (getCookie("country_id") && getCookie("country_name")) {
      deleteCookie("country_id");
      deleteCookie("country_name");
    }
    // console.log(value);
    setCookie("country_id", value.Host_Country_ID);
    setCookie("country_name", value.Host_Country_Code.replace(/ /g, "-"));

    const currentUrl = window.location.href;
    const splitArray = currentUrl.split("/");

    if (splitArray && splitArray.length > 4) {
      console.log(splitArray);
      splitArray[3] = value.Host_Country_Code.replace(/ /g, "-");

      window.location.href = splitArray.join("/");
    } else {
      window.location.reload();
    }

    setSelectedValue(value.Host_Country);
    setCountryId(value.Host_Country_ID);
    setCountryName(value.Host_Country_Code.replace(/ /g, "-"));
    setCountryImage(value.Host_Country_image_Link);
    setIsOpenDropdown(false); // Close the dropdown after selection
  };

  const headerlogowidth = {
    width: "91px",
    height: "auto",
  };
  const menuflag = {
    width: "24px",
    height: "auto",
  };

  console.log("countries", countries);
  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        className="bg-body-tertiary bg-light"
        fixed="top"
      >
        <Container>
          <Navbar.Brand href="/">
            <img
              src={`${process.env.REACT_APP_HOSTNAME}/dist/assets/images/genie-saves-logo2.svg`}
              alt="logo"
              style={headerlogowidth}
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mx-auto">
              {links &&
                links.map((item) => {
                  return (
                    <Nav.Link
                      href={
                        item.name === "Home"
                          ? process.env.REACT_APP_HOSTNAME + item.link
                          : process.env.REACT_APP_HOSTNAME +
                            "/" +
                            encodeURIComponent(country_name).replace(
                              /%20/g,
                              "-"
                            ) +
                            item.link
                      }
                      key={item.name}
                    >
                      {item.name}
                    </Nav.Link>
                  );
                })}
            </Nav>
            <Nav>
              {token ? (
                <NavDropdown title={getAccountName()} id="basic-nav-dropdown">
                  <NavDropdown.Item href={routes.MY_PROFILE}>
                    My Profile
                  </NavDropdown.Item>
                  <NavDropdown.Item href={routes.FAVOURITES}>
                    My Favourites
                  </NavDropdown.Item>
                  <NavDropdown.Item href={routes.CHANGE_PASSWORD}>
                    Change Password
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={handleShow}>
                    Unregister
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={handleLogout}>
                    Logout
                  </NavDropdown.Item>
                </NavDropdown>
              ) : (
                <div className="d-flex align-items-center">
                  <Nav.Link
                    href={routes.LOGIN}
                    className="login"
                    onClick={handleLink}
                  >
                    {" "}
                    Login
                  </Nav.Link>
                  <span className="text-divide"> or </span>
                  <Nav.Link href={routes.REGISTER} className="login">
                    {" "}
                    Signup
                  </Nav.Link>
                  {/* <i className="fa fa-user" aria-hidden="true"></i>  */}
                </div>
              )}
            </Nav>

            <div className="country-dropdown">
              <button onClick={() => showDropdown()}>
                {Country_ID ? (
                  <div className="country-name">
                    <img
                      // src={
                      //   countries &&
                      //   countries.length > 0 &&
                      //   countries[
                      //     countries.findIndex(
                      //       (x) => x.Host_Country_ID == Country_ID
                      //     )
                      //   ]["Host_Country_image_Link"]
                      // }
                      src={
                        countries && countries.length > 0
                          ? (() => {
                              const index = countries.findIndex(
                                (x) => x.Host_Country_ID == Country_ID
                              );
                              return (
                                index !== -1 &&
                                countries[index]?.Host_Country_image_Link
                              );
                            })()
                          : ""
                      }
                      alt="flag"
                      style={menuflag}
                    />
                    {selectedValue}
                  </div>
                ) : (
                  "Select a country"
                )}
              </button>
              {isOpenDropdown && (
                <ul className="" style={{ display: "block" }}>
                  {countries &&
                    countries.length > 0 &&
                    countries.map((item) => {
                      return (
                        <li
                          onClick={() =>
                            item.Is_Active === "1" && handleItemClick(item)
                          }
                          className={
                            item.Is_Active === "1" ? "active" : "disabled"
                          }
                        >
                          <img src={item.Host_Country_image_Link} alt="flag" />
                          {item.Host_Country}
                        </li>
                      );
                    })}
                </ul>
              )}
              {/* <div>Selected value: {selectedValue}</div> */}
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Modal show={show} onHide={handleClose} className="postFullscreen">
        <Modal.Header closeButton>
          <Modal.Title>Delete Account</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>Are you sure to delete account?</div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={deleteAccount}>
            Delete
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Header;
