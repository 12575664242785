import React from "react";

const Badges = ({
  selectedCategories,
  selectedSubCategories,
  handleRemoveCategory,
  handleRemoveSubCategory,
}) => {
  return (
    <>
      <div className="col-sm-12 mb-4 mt-sm-0 mt-4 category-tags">
        {selectedCategories && selectedCategories.length > 0
          ? selectedCategories.map((item) => {
              return (
                <span className="badge badge-primary">
                  {item}
                  <button
                    type="button"
                    className="close"
                    aria-label="Dismiss"
                    onClick={() => {
                      handleRemoveCategory(item);
                    }}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </span>
              );
            })
          : ""}
        {selectedSubCategories && selectedSubCategories.length > 0
          ? selectedSubCategories.map((item) => {
              return (
                <span className="badge badge-primary">
                  {item}
                  <button
                    type="button"
                    className="close"
                    aria-label="Dismiss"
                    onClick={() => {
                      handleRemoveSubCategory(item);
                    }}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </span>
              );
            })
          : ""}
      </div>
    </>
  );
};

export default Badges;
