import React, { useEffect, useState } from "react";

import api from "../../redux/services/api";
import { CATEGORIES } from "../../redux/reduxConstants/EndPoints";
import * as images from "../constant/Assets";
import * as routes from "../constant/Routes";
import { Row } from "react-bootstrap";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { getCookie } from "../../lib/helpers";
import LazyLoad from "react-lazyload";

const Categories = ({ history }) => {
  const baseUrl = process.env.REACT_APP_API_BASEURL;
  const [categories, setCategories] = useState([]);

  const country_name = getCookie("country_name")
    ? getCookie("country_name")
    : "kuwait";

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = () => {
    const country_id = getCookie("country_id") ? getCookie("country_id") : 1;

    api(baseUrl)
      .get(CATEGORIES + "?country_id=" + country_id)
      .then((res) => {
        if (res.data.success) {
          setCategories(res.data.data);
        }
      })
      .catch((e) => console.log(e));
  };

  const handleCategorySearch = (item) => {
    const country_id = getCookie("country_id") ? getCookie("country_id") : 1;
    history.push({
      pathname: country_name + routes.BASE_SEARCH_ROUTE,
      search: "?category=" + item.Category,
      state: {
        selectedCategory: item.Category,
      },
    });
  };

  const responsiveOptions = {
    0: {
      items: 5,
    },
    600: {
      items: 5,
    },
    1000: {
      items: 10,
    },
  };

  const categoryImgWidth = {
    width: "50px",
    height: "auto",
  };

  // const categoryImgWidth = {
  //   width: "50px",
  //   height: "auto",
  // };

  return (
    <section id="categories" className="mt-5 text-center">
      <Row>
        <h2 className="section-title mb-4">Categories</h2>
      </Row>
      {categories && categories.length > 0 ? (
        <Row>
          {/* state= {
        responsive={
            0: {
                items: 1,
            },
            450: {
                items: 2,
            },
            600: {
                items: 3,
            },
            1000: {
                items: 4,
            },
        },
    } */}

          <OwlCarousel
            className="owl-theme"
            // loop
            nav
            margin={30}
            responsiveClass={true}
            responsive={responsiveOptions}
          >
            {categories.map((item) => {
              const imageName = item.Category.replace(",", "")
                .replace(" ", "_")
                .replace("&", "and")
                .replace(" ", "_")
                .replace(" ", "_")
                .replace(",", "")
                .replace(".", "");

              return (
                <div
                  className="item"
                  onClick={() => handleCategorySearch(item)}
                  style={{ cursor: "pointer" }}
                >
                  <div className="cat-item mobile 2">
                    <div className="cat-img 11">
                      <img
                        src={
                          item && item.Image
                            ? item.Image
                            : `${process.env.REACT_APP_HOSTNAME}/dist/assets/images/Smartphones,_Tablets_&_Wearables.svg`
                        }
                        alt="Category image"
                        style={categoryImgWidth}
                      />
                    </div>
                    <div className="cat-txt">
                      <span>{item.Category}</span>
                    </div>
                  </div>
                </div>
              );
            })}
          </OwlCarousel>
        </Row>
      ) : (
        ""
      )}
    </section>
  );
};

export default Categories;
