import React, { useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import api from "../../redux/services/api";
import { SORT_LIST } from "../../redux/reduxConstants/EndPoints";
import { getCookie } from "../../lib/helpers";

const SortFilter = ({ handleSort, title, orderBy, setTitle }) => {
  const [sortList, setSortList] = useState([]);
  const baseUrl = process.env.REACT_APP_API_BASEURL;

  useEffect(() => {
    fetchSortList();
  }, []);

  useEffect(() => {
    const titleTemp =
      sortList &&
      sortList.length > 0 &&
      sortList[sortList.findIndex((x) => x.Seq == orderBy)]
        ? sortList[sortList.findIndex((x) => x.Seq == orderBy)]["Label"]
        : "";

    setTitle(titleTemp);
  }, [sortList]);

  const fetchSortList = () => {
    const Country_ID = getCookie("country_id") ? getCookie("country_id") : 1;
    api(baseUrl)
      .get(SORT_LIST + "?country_id=" + Country_ID)
      .then((res) => {
        if (res.data.success) {
          setSortList(res.data.data);
        }
      })
      .catch((e) => console.log(e));
  };

  return (
    <Dropdown className="mx-2 d-flex justify-content-end">
      <Dropdown.Toggle variant="success" id="dropdown-sort">
        <i className="fa fa-exchange" aria-hidden="true"></i>{" "}
        {title ? title : "Sort by"}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {sortList &&
          sortList.length > 0 &&
          sortList.map((item) => {
            return (
              <Dropdown.Item
                onClick={() => {
                  handleSort(item.Seq, item.Sort_By, item.Label);
                }}
              >
                {item.Label}
              </Dropdown.Item>
            );
          })}
      </Dropdown.Menu>
      {/* <Dropdown.Menu>
        <Dropdown.Item
          onClick={() => {
            handleSort("Selling_Price", "asc", "Price Low to High");
          }}
        >
          Price Low to High
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => {
            handleSort("Selling_Price", "desc", "Price High to Low");
          }}
        >
          Price High to Low
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => {
            handleSort("Discount_Percent", "desc", "Discount High to Low");
          }}
        >
          Discount % High to Low
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => {
            handleSort("Category", "asc", "Category A-Z");
          }}
        >
          Category A-Z
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => {
            handleSort("Category", "desc", "Category Z-A");
          }}
        >
          Category Z-A
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => {
            handleSort("item_name", "asc", "Title A-Z");
          }}
        >
          Title A-Z
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => {
            handleSort("item_name", "desc", "Title Z-A");
          }}
        >
          Title Z-A
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => {
            handleSort("Brand", "asc", "Brand A-Z");
          }}
        >
          Brand A-Z
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => {
            handleSort("Brand", "desc", "Brand Z-A");
          }}
        >
          Brand Z-A
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => {
            handleSort("Unit", "asc", "Unit Asc");
          }}
        >
          Unit
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => {
            handleSort("Calc_Unit", "asc", "Price Std Asc");
          }}
        >
          Price Std
        </Dropdown.Item>
      </Dropdown.Menu> */}
    </Dropdown>
  );
};

export default SortFilter;
