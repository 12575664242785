import React, { useEffect, useState, useRef } from "react";
import api from "../../redux/services/api";
import {
  Col,
  Container,
  Form,
  Row,
  Dropdown,
  Modal,
  Button,
  Tooltip,
  Overlay,
} from "react-bootstrap";
import {
  CATEGORIES,
  SUBCATEGORIES,
  ALL_LEAFLETS,
  VENDORS,
  TRIGGER,
} from "../../redux/reduxConstants/EndPoints";
import Pagination from "../../uikit/Paginate";
import LeafletList from "./LeafletList";
import useDeviceDetection from "../../hooks/useDeviceDetection";
import { getCookie } from "../../lib/helpers";
import CommunityLoaderCircularDash from "../../uikit/CommunityLoaderCircularDash";
import * as images from "../constant/Assets";
import { Helmet } from "react-helmet";

const Leaflet = ({ history }) => {
  const device = useDeviceDetection();

  let fullSlug = "";
  let slugString = "";
  let slug = "";
  let searchArr = "";
  let searchText = "";

  let arrVendor = [];
  let arrCategory = [];
  // let country_id_prev = 1;
  const currentUrl = window.location.href;
  const splitArray = currentUrl.split("/");

  const mykey = "REACT_APP_HOST_COUNTRY_" + splitArray[3].toUpperCase();
  const country_id_prev =
    splitArray && splitArray.length > 4 ? `${process.env[mykey]}` : 1;

  if (window !== undefined && typeof window !== "undefined") {
    const paramArray = window.location.href.split("/");

    fullSlug = paramArray[paramArray.length - 1];

    if (fullSlug.includes("?")) {
      slugString = fullSlug.split("?");
      slug = slugString[1];

      if (slug) {
        searchArr = slug.split("=");
        let searchUrl = searchArr[1];

        if (searchArr[0] == "query" && searchUrl) {
          let searchUrlArr = searchUrl.split("&");
          if (searchUrlArr && searchUrlArr.length > 0) {
            searchText = searchUrlArr[0];
          }
        }
      }
    } else {
      slug = paramArray[paramArray.length - 1];
    }

    const query = new URLSearchParams(history.location.search);

    let vendor = query.get("vendor");

    if (vendor && "" !== vendor) {
      vendor = vendor
        .replace("_", " ")
        .replace("and_", "and ")
        .replace("and", "&");
      arrVendor = vendor.split(",");
    }

    let category = query.get("category");

    if (category && "" !== category) {
      category = category
        .replace("_", " ")
        .replace("and_", "and ")
        .replace("and", "&");

      arrCategory = category.split("|");

      arrCategory = arrCategory.map((item) => {
        if (item.includes("|")) {
          item = item.replace("|", ", ");
        }
        if (item.includes("addcomma")) {
          item = item.replace(" addcomma", ",");
        }
        return item;
      });
    }
  }

  const [leaflets, setLeaflets] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(15);
  const [loading, setLoading] = useState(false);

  const [categories, setCategories] = useState([]);
  const [subCategories, setSubcategories] = useState([]);
  const [actualSubcategories, setActualSubCategories] = useState([]);
  const [actualCategories, setActualCategories] = useState([]);

  const [country_idPrev, setCountryIdPrev] = useState(
    country_id_prev ? country_id_prev : 1
  );

  const [selectedCategories, setSelectedCategories] = useState(
    history &&
      history.location &&
      history.location.state &&
      history.location.state !== undefined &&
      history.location.state.selectedCategory
      ? [history.location.state.selectedCategory]
      : arrCategory
      ? arrCategory
      : []
  );
  const [selectedSubCategories, setSelectedSubCategories] = useState([]);

  const [totalCount, setTotalCount] = useState(0);

  const [vendors, setVendors] = useState([]);
  const [selectedVendors, setSelectedVendors] = useState(
    arrVendor ? arrVendor : []
  );
  const [isDataEmpty, setIsDataEmpty] = useState(false);

  const [isShowFilter, setIsShowFilter] = useState(true);
  const handleCloseFilter = () => setIsShowFilter(false);
  const handleShowFilter = () => setIsShowFilter(true);

  const [isShowMobFilter, setIsMobShowFilter] = useState(false);
  const handleCloseMobileFilter = () => setIsMobShowFilter(false);
  const handleShowMobileFilter = () => {
    setIsMobShowFilter(true);
  };

  //  for tooltip =/daksha
  const [show1, setShow1] = useState(false);
  const target1 = useRef(null);

  const [show4, setShow4] = useState(false);
  const target4 = useRef(null);

  const [show4m, setShow4m] = useState(false);
  const target4m = useRef(null);

  const prevSelectedCategoriesRef = useRef([]);
  const prevSelectedVendorsRef = useRef([]);
  const prevLimitRef = useRef(limit);

  useEffect(() => {
    const subCategoriesTemp = [];
    if (subCategories && subCategories.length > 0) {
      subCategories.forEach((item) => {
        const Category = item.Category;
        subCategoriesTemp[Category] = subCategoriesTemp[Category] || [];
        subCategoriesTemp[Category].push(item.Sub_Category);
      });
      const resultKeys = Object.keys(subCategoriesTemp);
      const resultArray = Object.values(subCategoriesTemp);

      setActualSubCategories(resultArray);
      setActualCategories(resultKeys);
    }
  }, [subCategories]);

  const handleCategories = (e) => {
    setPage(1);
    const prevValues = [...selectedCategories];

    if (e.target.checked) {
      prevValues.push(e.target.value);
      setSelectedCategories(prevValues);
    } else {
      const newArray = prevValues.filter((item) => item !== e.target.value);
      setSelectedCategories(newArray);
    }
  };

  const handleSubcategories = (e, key) => {
    const prevValues = [...selectedSubCategories];
    const prevCategories = [...selectedCategories];

    const removeCategory = actualCategories[key];

    if (removeCategory) {
      const newArray = prevCategories.filter((item) => item !== removeCategory);
      setSelectedCategories(newArray);
    }
    if (e.target.checked) {
      prevValues.push(e.target.value);
      setSelectedSubCategories(prevValues);
    } else {
      const newArray = prevValues.filter((item) => item !== e.target.value);
      setSelectedSubCategories(newArray);
    }
  };

  useEffect(() => {
    document.title = "Leaflets | Genie Saves";
  }, []);

  useEffect(() => {
    setTimeout(() => {
      fetchLeafts();
    }, 1000);

    fetchVendors();
  }, []);

  useEffect(() => {
    if ("Mobile" !== device) {
      const prevCategories = prevSelectedCategoriesRef.current;
      const currentCategories = [...selectedCategories];

      const prevVendors = prevSelectedVendorsRef.current;
      const currentVendors = [...selectedVendors];

      if (
        JSON.stringify(prevCategories) !== JSON.stringify(selectedCategories) ||
        JSON.stringify(prevVendors) !== JSON.stringify(selectedVendors)
      ) {
        setTimeout(() => {
          fetchLeafts();

          prevSelectedCategoriesRef.current = currentCategories;
          prevSelectedVendorsRef.current = currentVendors;

          let categoryText = "";
          if (selectedCategories && selectedCategories.length > 0) {
            const newselectedCategories = selectedCategories.map((item) => {
              return item
                .replace(/ & /g, "_and_")
                .replace(/&/g, "and")
                .replace(",", " addcomma");
            });

            let category = newselectedCategories.join("|");

            categoryText = "&category=" + category;
          }

          let vendorText = "";
          if (selectedVendors && selectedVendors.length > 0) {
            const vendor = selectedVendors.join(",");
            vendorText = "&vendor=" + vendor;
          }
          const country_idSelected = getCookie("country_id")
            ? getCookie("country_id")
            : country_id_prev
            ? country_id_prev
            : 1;
          const countryText = "&country_id=" + country_idSelected;
          let url = `?page=1&page_size=${limit}${vendorText}${categoryText}`;
          history.replace({
            pathname: history.location.pathname,
            search: url,
          });
        }, 1000);
      }
    }
  }, [selectedVendors, selectedCategories]);

  useEffect(() => {
    if (prevLimitRef.current !== limit) {
      setTimeout(() => {
        fetchLeafts();

        let categoryText = "";
        if (selectedCategories && selectedCategories.length > 0) {
          const newselectedCategories = selectedCategories.map((item) => {
            return item
              .replace(/ & /g, "_and_")
              .replace(/&/g, "and")
              .replace(",", " addcomma");
          });

          let category = newselectedCategories.join("|");

          categoryText = "&category=" + category;
        }

        let vendorText = "";
        if (selectedVendors && selectedVendors.length > 0) {
          const vendor = selectedVendors.join(",");
          vendorText = "&vendor=" + vendor;
        }
        const country_idSelected = getCookie("country_id")
          ? getCookie("country_id")
          : country_id_prev
          ? country_id_prev
          : 1;
        const countryText = "&country_id=" + country_idSelected;
        let url = `?page=1&page_size=${limit}${vendorText}${categoryText}`;
        history.replace({
          pathname: history.location.pathname,
          search: url,
        });
      }, 1000);
    }
  }, [limit]);

  const fetchVendors = () => {
    const country_id = getCookie("country_id")
      ? getCookie("country_id")
      : country_id_prev
      ? country_id_prev
      : 1;
    api(baseUrl)
      .get(VENDORS + "?category=Leaflets&country_id=" + country_id)
      .then((res) => {
        if (res.data.success) {
          setVendors(res.data.data);
        }
      })
      .catch((e) => console.log(e));
  };

  const baseUrl = process.env.REACT_APP_API_BASEURL;

  const fetchLeafts = () => {
    setLoading(true);

    const offset_rows = (page - 1) * limit;
    let category = "*";
    if (selectedCategories && selectedCategories.length > 0) {
      const newselectedCategories = selectedCategories.map((item) => {
        return item.replace(/ & /g, "_and_").replace(/&/g, "and");
      });

      category = newselectedCategories.join("|");
    }

    let vendor = "*";
    if (selectedVendors && selectedVendors.length > 0) {
      vendor = selectedVendors.join(",");
    }

    setLoading(true);

    const session_id = getCookie("unique_session_id")
      ? getCookie("unique_session_id")
      : null;

    const country_id = getCookie("country_id")
      ? getCookie("country_id")
      : country_id_prev
      ? country_id_prev
      : 1;

    api(baseUrl)
      .get(
        ALL_LEAFLETS +
          "?num_of_rows_required=" +
          limit +
          "&Start_offset=" +
          offset_rows +
          "&Category=" +
          category +
          "&Vendor=" +
          vendor +
          "&session_id=" +
          session_id +
          "&country_id=" +
          country_id
      )
      .then((res) => {
        setLoading(false);
        if (res.data.success) {
          if (res.data.data.length > 0) {
            setLeaflets(res.data.data);
          } else {
            setIsDataEmpty(true);
            setLeaflets([]);
          }
          setTotalCount(res.data.totalCount);
        }
      })
      .catch((e) => console.log(e));
  };

  const handlePageClick = (currentpage) => {
    window.scroll(0, 0);

    setPage(currentpage.selected + 1);
    const currentPageSelected = currentpage.selected + 1;
    const offset_rows = (currentPageSelected - 1) * limit;

    setLoading(true);
    let category = "*";
    if (selectedCategories && selectedCategories.length > 0) {
      const newselectedCategories = selectedCategories.map((item) => {
        return item.replace(/ & /g, "_and_").replace(/&/g, "and");
      });
      category = newselectedCategories.join("|");
    }

    let vendor = "*";
    if (selectedVendors && selectedVendors.length > 0) {
      vendor = selectedVendors.join(",");
    }

    const session_id = getCookie("unique_session_id")
      ? getCookie("unique_session_id")
      : null;

    const country_id = getCookie("country_id")
      ? getCookie("country_id")
      : country_id_prev
      ? country_id_prev
      : 1;

    api(baseUrl)
      .get(
        ALL_LEAFLETS +
          "?days_tolerance=-35&num_of_rows_required=" +
          limit +
          "&Start_offset=" +
          offset_rows +
          "&Category=" +
          category +
          "&Vendor=" +
          vendor +
          "&session_id=" +
          session_id +
          "&country_id=" +
          country_id
      )
      .then((res) => {
        setLoading(false);
        if (res.data.success) {
          if (res.data.data.length > 0) {
            setLeaflets(res.data.data);
          } else {
            setIsDataEmpty(true);
            setLeaflets([]);
          }
          setTotalCount(res.data.totalCount);
        }
      })
      .catch((e) => console.log(e));

    let categoryText = "";
    if (selectedCategories && selectedCategories.length > 0) {
      const newselectedCategories = selectedCategories.map((item) => {
        return item
          .replace(/ & /g, "_and_")
          .replace(/&/g, "and")
          .replace(",", " addcomma");
      });

      let category = newselectedCategories.join("|");

      categoryText = "&category=" + category;
    }

    let vendorText = "";
    if (selectedVendors && selectedVendors.length > 0) {
      const vendor = selectedVendors.join(",");
      vendorText = "&vendor=" + vendor;
    }
    let url = `?page=${currentPageSelected}&page_size=${limit}${vendorText}${categoryText}`;
    history.replace({
      pathname: history.location.pathname,
      search: url,
    });
  };

  useEffect(() => {
    fetchCategories();
    fetchSubcategories();
  }, []);

  useEffect(() => {
    const subCategoriesTemp = [];
    if (subCategories && subCategories.length > 0) {
      subCategories.forEach((item) => {
        const Category = item.Category;
        subCategoriesTemp[Category] = subCategoriesTemp[Category] || [];
        subCategoriesTemp[Category].push(item.Sub_Category);
      });
      const resultKeys = Object.keys(subCategoriesTemp);
      const resultArray = Object.values(subCategoriesTemp);

      setActualSubCategories(resultArray);
      setActualCategories(resultKeys);
    }
  }, [subCategories]);

  const fetchCategories = () => {
    const country_id = getCookie("country_id")
      ? getCookie("country_id")
      : country_id_prev
      ? country_id_prev
      : 1;
    api(baseUrl)
      .get(CATEGORIES + "?country_id=" + country_id)
      .then((res) => {
        if (res.data.success) {
          setCategories(res.data.data);
        }
      })
      .catch((e) => console.log(e));
  };

  const fetchSubcategories = () => {
    const country_id = getCookie("country_id")
      ? getCookie("country_id")
      : country_id_prev
      ? country_id_prev
      : 1;
    api(baseUrl)
      .get(SUBCATEGORIES + "?country_id=" + country_id)
      .then((res) => {
        if (res.data.success) {
          setSubcategories(res.data.data);
        }
      })
      .catch((e) => console.log(e));
  };

  const handleVendor = (e) => {
    setPage(1);
    const prevValues = [...selectedVendors];

    if (e.target.checked) {
      prevValues.push(e.target.value);
      setSelectedVendors(prevValues);
    } else {
      const newArray = prevValues.filter((item) => item !== e.target.value);
      setSelectedVendors(newArray);
    }
  };

  const handleApplyFilter = () => {
    if ("Mobile" === device) {
      handleCloseMobileFilter();
      fetchLeafts();
    }
  };

  // start-code

  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://geniesaves.com/kuwait/leaflets/" />
      </Helmet>
      <div className="Leaflet mt-nav">
        {loading && <CommunityLoaderCircularDash isbackground={false} />}
        <Container className="mb-5 mt-4">
          <section
            id="home__leaflet"
            className="mt-5 text-center item-design leaflet-detail"
          >
            <Row>
              <Col className="d-flex1 justify-content-center align-items-center title-wrap mt-sm-4 mt-2 mb-sm-5 mb-0 col ">
                <p>— LEAFLETS —</p>
                <h1 className="section-title mb-1">
                  Discover the Latest Digital Leaflets from Kuwait's Ecommerce
                  Sites
                </h1>
                <span></span>
              </Col>
              <Row>
                <div className="col-sm-2 mb-3 for-desktop">
                  <div className="d-flex align-items-center">
                    <button
                      className="btn-simple btn-show-filter"
                      type="button"
                      onClick={() => setIsShowFilter(!isShowFilter)}
                    >
                      {isShowFilter ? "Hide Filter" : "Show Filter"}
                    </button>

                    <i
                      className="fa fa-info-circle"
                      aria-hidden="true"
                      ref={target1}
                      onClick={() => setShow1(!show1)}
                    ></i>
                    <Overlay
                      target={target1.current}
                      show={show1}
                      placement="right"
                    >
                      {(props) => (
                        <Tooltip id="overlay-example" {...props}>
                          <p align="left">
                            Show Filter: Show the Filter pane, which is
                            currently hidden. With that you will have many
                            different filtering options.<br></br>
                            <br></br>
                            Hide Filter: Hide Filter pane, so that you can see
                            the main list with full page. Note that you can
                            bring back the Filter pane anytime when you click
                            the ‘Show Filter’ again.
                          </p>
                        </Tooltip>
                      )}
                    </Overlay>
                  </div>
                  {/* <button
                  className="btn-simple btn-show-filter"
                  type="button"
                  onClick={() => setIsShowFilter(!isShowFilter)}
                >
                  {isShowFilter ? "Hide Filter" : "Show Filter"}
                </button> */}
                </div>
              </Row>
            </Row>

            <Row className="mt-4">
              {isShowFilter && (
                <section className="col-sm-3 cat-left">
                  <section className="cat-for-desktop">
                    <div className="filter-layout">
                      <h6>
                        Vendors with Category{" "}
                        <i
                          className="fa fa-info-circle"
                          aria-hidden="true"
                          ref={target4}
                          onClick={() => setShow4(!show4)}
                        ></i>
                        <Overlay
                          target={target4.current}
                          show={show4}
                          placement="right"
                        >
                          {(props) => (
                            <Tooltip id="overlay-example" {...props}>
                              <p>
                                when you select one (or more) category, the
                                vendor list will be changed with who sells such
                                Category of products. With that the listed items
                                will change accordingly.
                              </p>
                            </Tooltip>
                          )}
                        </Overlay>
                      </h6>

                      {categories && categories.length > 0 ? (
                        <>
                          {categories.map((item, index) => {
                            return (
                              <>
                                <Form.Check
                                  type="checkbox"
                                  id={index}
                                  label={item.Category}
                                  value={item.Category}
                                  onChange={handleCategories}
                                  checked={selectedCategories.includes(
                                    item.Category
                                  )}
                                />
                              </>
                            );
                          })}
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="mt-4 vendors-filter">
                      <h6>Vendors</h6>
                      {vendors && vendors.length > 0
                        ? vendors.map((item, index) => {
                            return (
                              <Form.Check
                                type="checkbox"
                                id={index}
                                label={item.Vendor}
                                value={item.Vendor}
                                onChange={handleVendor}
                                checked={selectedVendors.includes(item.Vendor)}
                              />
                            );
                          })
                        : ""}
                    </div>
                  </section>
                </section>
              )}
              <section className="cat-for-mobile">
                <div className="catfilter-mob mb-4">
                  <button className="btn" onClick={handleShowMobileFilter}>
                    <img src={images.filter}></img> Filter
                  </button>
                </div>
                <Modal
                  show={isShowMobFilter}
                  onHide={handleCloseMobileFilter}
                  className="mob-filter-popup"
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Filter </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="filter-layout">
                      <h6>
                        Vendors with Category{" "}
                        <i
                          className="fa fa-info-circle"
                          aria-hidden="true"
                          ref={target4m}
                          onClick={() => setShow4m(!show4m)}
                        ></i>
                        <Overlay
                          target={target4m.current}
                          show={show4m}
                          placement="bottom"
                        >
                          {(props) => (
                            <Tooltip id="overlay-example" {...props}>
                              <p>
                                when you select one (or more) category, the
                                vendor list will be changed with who sells such
                                Category of products. With that the listed items
                                will change accordingly.
                              </p>
                            </Tooltip>
                          )}
                        </Overlay>
                      </h6>
                      {categories && categories.length > 0 ? (
                        <>
                          {categories.map((item, index) => {
                            return (
                              <>
                                <Form.Check
                                  type="checkbox"
                                  id={index}
                                  label={item.Category}
                                  value={item.Category}
                                  onChange={handleCategories}
                                  checked={selectedCategories.includes(
                                    item.Category
                                  )}
                                />
                              </>
                            );
                          })}
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="mt-4 vendors-filter">
                      <h6>Vendors</h6>
                      {vendors && vendors.length > 0
                        ? vendors.map((item, index) => {
                            return (
                              <Form.Check
                                type="checkbox"
                                id={index}
                                label={item.Vendor}
                                value={item.Vendor}
                                onChange={handleVendor}
                                checked={selectedVendors.includes(item.Vendor)}
                              />
                            );
                          })
                        : ""}
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleApplyFilter}>
                      Apply
                    </Button>
                    <Button
                      variant="secondary"
                      onClick={handleCloseMobileFilter}
                    >
                      Close
                    </Button>
                  </Modal.Footer>
                </Modal>
              </section>

              <section
                className={
                  isShowFilter
                    ? "col-sm-9 leaflet-list"
                    : "col-sm-12 leaflet-list"
                }
              >
                <Row>
                  <LeafletList
                    leaflets={leaflets}
                    history={history}
                    isDataEmpty={isDataEmpty}
                  />
                </Row>
                <Row className="">
                  <Col md={9} xs={12} className="pagination">
                    <Pagination
                      totalCount={totalCount}
                      limitValue={limit}
                      currentPage={page}
                      handlePageClick={handlePageClick}
                    />
                  </Col>
                  <Col>
                    {totalCount > 15 ? (
                      <Dropdown className="mx-2 d-flex justify-content-end page-size">
                        <span className="text">Page Size</span>
                        <Dropdown.Toggle variant="success" id="dropdown-sort">
                          {limit}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() => {
                              setPage(1);
                              setLimit(15);
                            }}
                          >
                            15
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => {
                              setPage(1);
                              setLimit(30);
                            }}
                          >
                            30
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => {
                              setPage(1);
                              setLimit(60);
                            }}
                          >
                            60
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => {
                              setPage(1);
                              setLimit(90);
                            }}
                          >
                            90
                          </Dropdown.Item>

                          <Dropdown.Item
                            onClick={() => {
                              setPage(1);
                              setLimit(120);
                            }}
                          >
                            120
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
              </section>
            </Row>
          </section>
        </Container>
      </div>
    </>
  );
};
// end-code
export default Leaflet;
