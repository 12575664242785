import { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import * as images from "../constant/Assets";
import * as routes from "../constant/Routes";
import { Helmet } from "react-helmet";

function Termsandconditions() {
  useEffect(() => {
    document.title = "Terms & Conditions | Genie Saves";
  }, []);

  return (
    <>
    <Helmet>
        <link rel="canonical" href="https://geniesaves.com/terms-and-conditions/" />
    </Helmet>
      <Container className="mt-nav">
        <Row>
          <Col>
            <h2 className="section-title m-5 text-center">
              Terms and conditions
            </h2>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="about-content mb-5">
              <h5>Welcome to GenieSaves.com! </h5>
              <p>
                These Terms of Use explain the rules for using our website. By
                using{" "}
                <a href="https://geniesaves.com/" className="tnc-links">
                  GenieSaves.com
                </a>
                , you agree to these terms.
              </p>
              <br></br>

              <h5>What We Do </h5>
              <p>
                <a href="https://geniesaves.com/" className="tnc-links">
                  GenieSaves.com
                </a>{" "}
                helps you find the best deals on products sold online in the
                Middle East. We search various online stores and track
                historical prices, similar to how Google searches websites. We
                don't endorse any particular store - our goal is to help you
                make informed purchasing decisions.
              </p>
              <br></br>

              <h5>Using GenieSaves.com </h5>
              <p>
                Anyone can use{" "}
                <a href="https://geniesaves.com/" className="tnc-links">
                  GenieSaves.com
                </a>{" "}
                for free, unless restricted by local laws. If you experience
                access issues, please contact us at{" "}
                <a href="mailto:support@geniesaves.com" className="tnc-links">
                  support@geniesaves.com
                </a>
                .
              </p>
              <br></br>

              <h5>Our Services </h5>
              <p>
                Currently,{" "}
                <a href="https://geniesaves.com/" className="tnc-links">
                  GenieSaves.com
                </a>{" "}
                is free to use. We reserve the right to change our pricing model
                in the future.
              </p>
              <br></br>

              <h5>Data and Content</h5>
              <p>
                <a href="https://geniesaves.com/" className="tnc-links">
                  GenieSaves.com
                </a>{" "}
                collects product information and prices from online stores and
                stores this data in our system. This data, including historical
                price trends, is considered our property.
              </p>
              <br></br>

              <h5>Using Our Data</h5>
              <p>
                You cannot copy, download, or scrape data from{" "}
                <a href="https://geniesaves.com/" className="tnc-links">
                  GenieSaves.com
                </a>{" "}
                without our written permission. This includes using our data on
                other websites, apps, or offline publications.{" "}
              </p>
              <p>
                We strive for accurate information, but we cannot guarantee it's
                always perfect. Consider the data for informational purposes
                only.
              </p>
              <br></br>

              <h5>Intellectual Property</h5>
              <p>
                Trademarks and product information belong to their respective
                owners. You cannot use trademarks or intellectual property of
                others on{" "}
                <a href="https://geniesaves.com/" className="tnc-links">
                  GenieSaves.com
                </a>
                .
              </p>
              <br></br>

              <h5>Your Responsibilities </h5>
              <p>
                When using our website, please provide accurate information.
                Don't attempt to harm the performance or security of{" "}
                <a href="https://geniesaves.com/" className="tnc-links">
                  GenieSaves.com
                </a>
                . You also cannot extract data through scraping or by
                reverse-engineering our website.
              </p>
              <br></br>

              <h5>Affiliates and Advertising </h5>
              <p>
                In the future, we may partner with online stores through
                affiliate programs. This means we may earn a commission if you
                purchase something from a store you discovered on{" "}
                <a href="https://geniesaves.com/" className="tnc-links">
                  GenieSaves.com
                </a>
                . Regardless of partnerships, we remain neutral and prioritize
                user value.
              </p>
              <p>
                We may also allow online stores to advertise on{" "}
                <a href="https://geniesaves.com/" className="tnc-links">
                  GenieSaves.com
                </a>
                . These ads are clearly marked and do not represent
                endorsements.
              </p>
              <br></br>

              <h5>Termination </h5>
              <p>
                We reserve the right to block or terminate your access to{" "}
                <a href="https://geniesaves.com/" className="tnc-links">
                  GenieSaves.com
                </a>{" "}
                for violating our Terms of Use. We may also take legal action
                for such violations.
              </p>
              <br></br>

              <h5>Your Agreement </h5>
              <p>
                By using{" "}
                <a href="https://geniesaves.com/" className="tnc-links">
                  GenieSaves.com
                </a>
                , you agree to these Terms of Use.
              </p>
              <br></br>

              <h5>Changes to Terms of Use </h5>
              <p>
                We may update these Terms of Use. We recommend checking this
                page periodically for changes.
              </p>
              <br></br>

              <h5>Contact Us </h5>
              <p>
                For questions or suggestions, please contact us at{" "}
                <a href="mailto:support@geniesaves.com" className="tnc-links">
                  support@geniesaves.com
                </a>
                .
              </p>
              <br></br>

              <h5>Last Updated </h5>
              <p>Date: 30-04-2024</p>
              <br></br>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
export default Termsandconditions;
