import React, { useEffect, useState } from "react";

import api from "../../redux/services/api";
import { BANNERS, TRIGGER } from "../../redux/reduxConstants/EndPoints";
import * as images from "../constant/Assets";
import { Row } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
import { getCookie } from "../../lib/helpers";
import { TRIGGER_BANNER } from "../constant/Triggers";
import LazyLoad from "react-lazyload";

const Banners = () => {
  const baseUrl = process.env.REACT_APP_API_BASEURL;
  const [banners, setBanners] = useState([]);

  useEffect(() => {
    fetchBanners();
  }, []);

  const fetchBanners = () => {
    const Country_ID = getCookie("country_id") ? getCookie("country_id") : 1;
    api(baseUrl)
      .get(BANNERS + "?num_of_rows_required=10&country_id=" + Country_ID)
      .then((res) => {
        if (res.data.success) {
          setBanners(res.data.data);
        }
      })
      .catch((e) => console.log(e));
  };

  const handleTrigger = (item) => {
    const user_id = getCookie("user_id");
    const session_id = getCookie("unique_session_id")
      ? getCookie("unique_session_id")
      : null;
    const data = {
      country_id: getCookie("country_id") ? getCookie("country_id") : 1,
      user_id: user_id,
      vendor: item.Vendor,
      item_key: item.Item_Key ? item.Item_Key : "",
      session_id: session_id,
      subject: TRIGGER_BANNER,
      external_link: item.Banner_Link,
      vendor_id: item.Vendor_ID,
    };
    api(baseUrl)
      .post(TRIGGER, data)
      .then((res) => {
        if (res.data.success) {
        }
      })
      .catch((e) => console.log(e));
  };

  const handleLink = (item) => {
    handleTrigger(item);
    window.open(item.Banner_Link, "_blank");
  };

  return (
    <Row>
      {banners && banners.length > 0 ? (
        <Carousel fade>
          {banners.map((item) => {
            let vendorName = item.Vendor.replace(" ", "-").toLowerCase();
            return (
              <Carousel.Item>
                <a
                  // href={
                  //   item.Banner_Link && "" !== item.Banner_Link
                  //     ? item.Banner_Link
                  //     : "#"
                  // }
                  // target="_blank"
                  onClick={() => handleLink(item)}
                >
                  <img
                    src={
                      item.Banner_Image && "" !== item.Banner_Image
                        ? item.Banner_Image
                        : images.homeBannerImage
                    }
                    alt="banner"
                    loading="lazy"
                  />
                  <span className="banner-vendor-logo">
                    <img
                      src={
                        item.Vendor_logo
                          ? item.Vendor_logo
                          : `${process.env.REACT_APP_HOSTNAME}/dist/assets/images/default-logo-sm.png`
                      }
                      alt="image"
                    ></img>
                  </span>
                </a>
              </Carousel.Item>
            );
          })}
        </Carousel>
      ) : (
        ""
      )}
    </Row>
  );
};

export default Banners;
