import { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import * as images from "../constant/Assets";
import * as routes from "../constant/Routes";
import { Helmet } from "react-helmet";

function Diclaimer() {
  useEffect(() => {
    document.title = "Disclaimer | Genie Saves";
  }, []);

  return (
    <>
    <Helmet>
        <link rel="canonical" href="https://geniesaves.com/disclaimer/" />
    </Helmet>
      <Container className="mt-nav">
        <Row>
          <Col>
            <h2 className="section-title m-5 text-center">Diclaimer</h2>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="about-content mb-5">
              <p>
                Genie Saves is a digital platform that aggregates and curates
                information about products, offers, banners, and leaflets from
                various online websites to help consumers in Kuwait make
                informed purchasing decisions. We strive to provide near
                real-time information to empower our users to save time and
                money by discovering the right offers at the right time and
                place.
              </p>
              <br></br>

              <p>
                Please note that Genie Saves is not a seller, distributor, or
                manufacturer of products. We do not participate in the actual
                transaction between buyers and sellers. All product names,
                trademarks, service marks, logos, brands, industrial models, and
                designs featured or referred to on our website belong to their
                respective owners and/or right holders and are used only to
                provide the information necessary for our users.
              </p>
              <br></br>

              <p>
                Genie Saves does not guarantee the accuracy or completeness of
                the information provided, as it is subject to change by the
                respective vendors at any time without notice. We encourage our
                users to always verify the current offers and the terms and
                conditions on the vendors' websites. Genie Saves shall not be
                held liable for any discrepancies or for the availability of
                products and offers.
              </p>
              <br></br>

              <p>
                The use of our services does not imply endorsement of any
                product or service, and Genie Saves disclaims any liability for
                any inaccuracies or omissions in the information provided on our
                platform. By using Genie Saves, you agree that we are not
                responsible for any decisions made based on the information we
                provide.
              </p>
              <br></br>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
export default Diclaimer;
