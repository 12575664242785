import React from "react";
import { Button, Modal } from "react-bootstrap";
import * as routes from "../constant/Routes";

const WishlistModal = ({ isShowPopup, handleClose, history }) => {
  return (
    <Modal show={isShowPopup} onHide={handleClose} className="postFullscreen">
      <Modal.Header closeButton>
        <Modal.Title>Add to Whishlist</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          You need to login or sign up to add products to your wishlist.
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          onClick={() => {
            localStorage.setItem("previousUrl", window.location.href);
            history.push({ pathname: routes.LOGIN });
          }}
        >
          Login
        </Button>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default WishlistModal;
