import React from "react";
import LeafletItem from "./LeafletItem";

const LeafletList = ({ leaflets, history, isDataEmpty }) => {
  return (
    <>
      {leaflets && leaflets.length > 0 ? (
        leaflets.map((item, index) => {
          let vendorName = item.vendor.replace(" ", "-").toLowerCase();

          return (
            <div className="item col-sm-4 col-6 pb-4">
              <LeafletItem
                item={item}
                vendorName={vendorName}
                history={history}
                index={index}
              />
            </div>
          );
        })
      ) : isDataEmpty ? (
        <span className="nodata">No data found</span>
      ) : (
        <span className="nodata">Loading...</span>
      )}
    </>
  );
};

export default LeafletList;
