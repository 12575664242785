import React, { useEffect, useState } from "react";
import moment from "moment";
import api from "../../redux/services/api";
import { ALL_LEAFLETS } from "../../redux/reduxConstants/EndPoints";
import * as images from "../constant/Assets";
import * as routes from "../constant/Routes";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import LeafletItem from "../leaflet/LeafletItem";
import { getCookie } from "../../lib/helpers";

const Leaflets = ({ history }) => {
  const baseUrl = process.env.REACT_APP_API_BASEURL;
  const [leaflets, setLeaflets] = useState([]);

  const country_name = getCookie("country_name")
    ? getCookie("country_name")
    : "kuwait";

  useEffect(() => {
    setTimeout(() => {
      fetchLeafts();
    }, 2000);
  }, []);

  const fetchLeafts = () => {
    const session_id = getCookie("unique_session_id")
      ? getCookie("unique_session_id")
      : null;
    const Country_ID = getCookie("country_id") ? getCookie("country_id") : 1;
    api(baseUrl)
      .get(
        ALL_LEAFLETS +
          "?num_of_rows_required=10&Start_offset=0&session_id=" +
          session_id +
          "&country_id=" +
          Country_ID
      )
      .then((res) => {
        if (res.data.success) {
          setLeaflets(res.data.data);
        }
      })
      .catch((e) => console.log(e));
  };

  return (
    <>
      {leaflets && leaflets.length > 0 ? (
        <section
          id="home__leaflet"
          className="mt-0 mt-sm-5 text-center item-design"
        >
          <Row>
            <Col className="d-flex justify-content-between align-items-center1 title-wrap mt-5 mb-4">
              <h2 className="section-title mb-1">Leaflets</h2>

              <a href={country_name + routes.BASE_LEAFLET_ROUTE}>View All</a>
            </Col>
          </Row>

          <Row>
            <OwlCarousel
              className="owl-theme"
              loop
              nav
              margin={20}
              responsiveClass={true}
              responsive={{
                1: {
                  items: 2,
                },
                1025: {
                  items: 4,
                },
              }}
            >
              {leaflets &&
                leaflets.length > 0 &&
                leaflets.map((item) => {
                  let vendorName = item.vendor.replace(" ", "-").toLowerCase();

                  return (
                    <div className="item">
                      <LeafletItem
                        item={item}
                        vendorName={vendorName}
                        history={history}
                      />
                    </div>
                  );
                })}
            </OwlCarousel>
          </Row>
        </section>
      ) : (
        ""
      )}
    </>
  );
};

export default Leaflets;
