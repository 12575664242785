import React from "react";
import { Col, Row, Dropdown } from "react-bootstrap";
import FavIcon from "../icon/FavIcon";
import * as routes from "../constant/Routes";
import * as images from "../constant/Assets";
import { getCookie } from "../../lib/helpers";

const ProductItem = ({
  item,
  handleAddToFavourites,
  handleRemoveFavourites,
  vendorName,
  isFromHome,
}) => {
  const handleProductDetail = (item) => {
    const country_id = getCookie("country_id") ? getCookie("country_id") : 1;
    sessionStorage.setItem("isFromHome", isFromHome);

    const countryName = getCookie("country_name")
      ? getCookie("country_name")
      : "kuwait";

    window.open(
      process.env.REACT_APP_HOSTNAME +
        "/" +
        countryName +
        "/" +
        item.Vendor +
        routes.BASE_PRODUCTDETAIL +
        "/" +
        encodeURIComponent(item.Item_name).replace(/%20/g, "-") +
        "/" +
        item.Item_Key,
      "_blank"
    );
  };

  return (
    <div className="item">
      <div className="main-item-wrap">
        <div className="img-wrap">
          {item.Discount_Percent > 0 ? (
            <div className="offer-tag">
              {item.Discount_Percent > 0
                ? Math.floor(item.Discount_Percent)
                : 0}
              % OFF
            </div>
          ) : (
            ""
          )}

          <FavIcon
            product={item}
            handleAddToFavourites={handleAddToFavourites}
            handleRemoveFavourites={handleRemoveFavourites}
          />
          <a
            // href={
            //   routes.PRODUCTDETAIL +
            //   "?Vendor=" +
            //   item.Vendor +
            //   "&Item_Key=" +
            //   item.Item_Key
            // }
            // target="_blank"
            onClick={() => handleProductDetail(item)}
          >
            <img src={item.Item_Image_URL} alt="img" className="img-fluid" />
          </a>
        </div>
        <div
          className="item-desc"
          onClick={() => handleProductDetail(item)}
          style={{
            cursor: "pointer",
          }}
        >
          <img
            src={
              item.Vendor_logo
                ? item.Vendor_logo
                : `${process.env.REACT_APP_HOSTNAME}/dist/assets/images/default-logo-sm.png`
            }
            alt="img"
          />
          <h5>{item.Brand}</h5>
          <p>{item.Item_name}</p>
        </div>
        <div
          className="price"
          onClick={() => handleProductDetail(item)}
          style={{
            cursor: "pointer",
          }}
        >
          <span className="kdprice">
            KD {Number(item.Selling_Price).toFixed(3)}{" "}
          </span>
          {item.Discount_Percent > 0 ? (
            <small>
              <strike>{Number(item.Regular_Price).toFixed(3)}</strike>
            </small>
          ) : (
            ""
          )}{" "}
          <span className="price-incre-decre-icon">
            {item.Trend_7day &&
              item.Trend_7day !== null &&
              item.Trend_7day.toLowerCase() === "down" && (
                <div className="p-decre">
                  <i className="fa fa-arrow-circle-down" aria-hidden="true"></i>
                </div>
              )}
            {item.Trend_7day &&
              item.Trend_7day !== null &&
              item.Trend_7day.toLowerCase() === "up" && (
                <div className="p-incre">
                  <i className="fa fa-arrow-circle-up" aria-hidden="true"></i>
                </div>
              )}
          </span>
          {null !== item.Selling_Price_Std && item.Selling_Price_Std > 0 ? (
            <small className="unit">
              {" "}
              ({Number(item.Selling_Price_Std).toFixed(3)}/{item.Calc_Unit2})
            </small>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};
export default ProductItem;
