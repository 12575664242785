import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Row,
  Accordion,
  Dropdown,
  Modal,
  Tooltip,
  Overlay,
} from "react-bootstrap";
import * as images from "../constant/Assets";

import api from "../../redux/services/api";
import {
  SEARCH,
  CATEGORIES,
  SUBCATEGORIES,
  ADD_FAVOURITES,
  REMOVE_FAVOURITES,
  VENDORS,
  BRANDS,
  SORT_LIST,
} from "../../redux/reduxConstants/EndPoints";
import Pagination from "../../uikit/Paginate";
import CommunityLoaderCircularDash from "../../uikit/CommunityLoaderCircularDash";
import ReactSlider from "react-slider";
import { deleteCookie, getCookie } from "../../lib/helpers";

import * as routes from "../constant/Routes";
import useDeviceDetection from "../../hooks/useDeviceDetection";
import WishlistModal from "../modal/WishlistModal";
import ProductList from "./ProductList";
import Badges from "./Badges";
import SortFilter from "./SortFilter";
import CategoryFilter from "./CategoryFilter";
import PriceFilter from "./PriceFIlter";
import VendorFilter from "./VendorFilter";
import BrandFilter from "./BrandFilter";
import OtherFilter from "./OtherFilter";

const SearchDetails = ({ history }) => {
  const device = useDeviceDetection();

  let fullSlug = "";
  let slugString = "";
  let slug = "";
  let searchArr = "";
  let searchText = "";
  let arrSubCategory = [];
  let arrVendor = [];
  let arrCategory = [];
  let arrBrand = [];
  let strOrderBy = "";
  let strSort = "";
  // let postCountry_idPrev = 1;
  let priceFrom = "";
  let priceTo = "";
  let urlPriceDropped = "";
  let urlavailableOnly = "";
  let urlonlyDiscounted = "";
  let urlexclude = "";

  const currentUrl = window.location.href;
  const splitArray = currentUrl.split("/");

  const mykey = "REACT_APP_HOST_COUNTRY_" + splitArray[3].toUpperCase();

  const postCountry_idPrev =
    splitArray && splitArray.length > 4 ? `${process.env[mykey]}` : 1;

  if (window !== undefined && typeof window !== "undefined") {
    const paramArray = window.location.href.split("/");

    fullSlug = paramArray[paramArray.length - 1];

    if (fullSlug.includes("?")) {
      slugString = fullSlug.split("?");
      slug = slugString[1];

      if (slug) {
        searchArr = slug.split("=");
        let searchUrl = searchArr[1];

        if (searchArr[0] == "query" && searchUrl) {
          let searchUrlArr = searchUrl.split("&");
          if (searchUrlArr && searchUrlArr.length > 0) {
            searchText = searchUrlArr[0];
          }
        }
      }
    } else {
      slug = paramArray[paramArray.length - 1];
    }

    const query = new URLSearchParams(history.location.search);

    let orderby = query.get("order_by");

    if (orderby && "" !== orderby) {
      strOrderBy = orderby;
    }

    let sortStr = query.get("sort");

    if (sortStr && "" !== sortStr) {
      strSort = sortStr;
    }

    let sub_category = query.get("sub_category");

    if (sub_category && "" !== sub_category) {
      sub_category = sub_category
        .replace("_", " ")
        .replace("and_", "and ")
        .replace("and", "&");
      arrSubCategory = sub_category.split("|");
      arrSubCategory = arrSubCategory.map((item) => {
        if (item.includes("|")) {
          item = item.replace("|", ", ");
        }
        if (item.includes("addcomma")) {
          item = item.replace(" addcomma", ",");
        }
        return item;
      });
    }

    // let postCountry_idPrev = query.get("country_id");

    priceFrom = query.get("price_from");
    priceTo = query.get("price_to");
    let vendor = query.get("vendor");

    if (vendor && "" !== vendor) {
      vendor = vendor
        .replace("_", " ")
        .replace("and_", "and ")
        .replace("and", "&");
      arrVendor = vendor.split(",");
    }

    let brand = query.get("brand");

    if (brand && "" !== brand) {
      brand = brand
        .replace("_", " ")
        .replace("and_", "and ")
        .replace("and", "&");
      arrBrand = brand.split(",");
    }

    let category = query.get("category");
    urlPriceDropped = query.get("only_pricedroped");
    urlavailableOnly = query.get("available_only");
    urlonlyDiscounted = query.get("only_discounted");
    urlexclude = query.get("exclude_accessory");

    if (category && "" !== category) {
      category = category
        .replace("_", " ")
        .replace("and_", "and ")
        .replace("and", "&");

      arrCategory = category.split("|");
      arrCategory = arrCategory.map((item) => {
        if (item.includes("|")) {
          item = item.replace("|", ", ");
        }
        if (item.includes("addcomma")) {
          item = item.replace(" addcomma", ",");
        }
        return item;
      });
    }
  }

  const [value, setValue] = useState(
    priceTo && priceFrom ? [priceFrom, priceTo] : []
  );
  const [productList, setProductList] = useState([]);
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(false);

  const [categories, setCategories] = useState([]);
  const [subCategories, setSubcategories] = useState([]);
  const [actualSubcategories, setActualSubCategories] = useState([]);
  const [actualCategories, setActualCategories] = useState([]);

  const [selectedCategories, setSelectedCategories] = useState(
    history &&
      history.location &&
      history.location.state &&
      history.location.state !== undefined &&
      history.location.state.selectedCategory
      ? [history.location.state.selectedCategory]
      : arrCategory
      ? arrCategory
      : []
  );
  const [selectedSubCategories, setSelectedSubCategories] = useState(
    arrSubCategory ? arrSubCategory : []
  );
  const [totalCount, setTotalCount] = useState(0);
  const [categoryCount, setCategoryCount] = useState([]);
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState();

  const [OrderBy, setOrderBy] = useState(strOrderBy ? strOrderBy : "0");
  const [sort, setSort] = useState(strSort ? strSort : "desc");

  const [vendors, setVendors] = useState();
  const [selectedVendors, setSelectedVendors] = useState(
    arrVendor ? arrVendor : []
  );
  const [title, setTitle] = useState(
    strOrderBy === "Selling_Price" && strSort === "asc"
      ? "Price Low to High"
      : strOrderBy === "Selling_Price" && strSort === "desc"
      ? "Price High to Low"
      : strOrderBy === "Discount_Percent" && strSort === "desc"
      ? "Discount High to Low"
      : strOrderBy === "Category" && strSort === "asc"
      ? "Category Asc"
      : strOrderBy === "Category" && strSort === "desc"
      ? "Category Desc"
      : strOrderBy === "item_name" && strSort === "desc"
      ? "Title Desc"
      : strOrderBy === "item_name" && strSort === "asc"
      ? "Title Asc"
      : strOrderBy === "Brand" && strSort === "asc"
      ? "Brand Asc"
      : strOrderBy === "Brand" && strSort === "desc"
      ? "Brand Desc"
      : strOrderBy === "Unit" && strSort === "asc"
      ? "Unit Asc"
      : strOrderBy === "Calc_Unit2" && strSort === "asc"
      ? "Price Std Asc"
      : ""
  );

  const [exclude_accessory, setExcludeAccessory] = useState(
    urlexclude ? urlexclude : 0
  );
  const [only_discounted, setOnlyDiscounted] = useState(
    urlonlyDiscounted ? urlonlyDiscounted : 0
  );
  const [available_only, setAvailableOnly] = useState(
    urlavailableOnly ? urlavailableOnly : 0
  );
  const [only_pricedroped, setAPriceDroppedOnly] = useState(
    urlPriceDropped ? urlPriceDropped : 0
  );
  const [isShowFilter, setIsShowFilter] = useState(true);
  const [brands, setBrands] = useState([]);
  const [originalBrands, setOriginalBrands] = useState();
  const [selectedBrands, setSelectedBrands] = useState(
    arrBrand ? arrBrand : []
  );
  const [isShowPopup, setIsShowPopup] = useState(false);
  const [minPriceManual, setMinPriceManual] = useState();
  const [maxPriceManual, setMaxPriceManual] = useState();
  const [reset, setReset] = useState(false);
  const token = getCookie("token");

  const handleClose = () => setIsShowPopup(false);
  const handleShow = () => setIsShowPopup(true);

  const [isShowMobFilter, setIsMobShowFilter] = useState(false);
  const handleCloseFilter = () => setIsMobShowFilter(false);
  const handleShowFilter = () => setIsMobShowFilter(true);

  //  for tooltip =/daksha
  const [show1, setShow1] = useState(false);
  const target1 = useRef(null);

  const [show2, setShow2] = useState(false);
  const target2 = useRef(null);

  const [show2m, setShow2m] = useState(false);
  const target2m = useRef(null);

  const [show3, setShow3] = useState(false);
  const target3 = useRef(null);

  const handleApplyFilter = () => {
    setIsMobShowFilter(false);
    fetchProductList(
      searchValue && "" !== searchValue ? searchValue : searchText
    );

    let valuesearch =
      searchValue && "" !== searchValue ? searchValue : searchText;
    let text = "";

    if (value.length > 0) {
      text = "&price_from=" + value[0] + "&price_to=" + value[1];
    }

    let categoryText = "";
    if (selectedCategories && selectedCategories.length > 0) {
      const newselectedCategories = selectedCategories.map((item) => {
        return item
          .replace(/ & /g, "_and_")
          .replace(/&/g, "and")
          .replace(",", " addcomma");
      });

      let category = newselectedCategories.join("|");

      categoryText = "&category=" + category;
    }

    let subcategoryText = "";
    if (selectedSubCategories && selectedSubCategories.length > 0) {
      const newselectedSubCategories = selectedSubCategories.map((item) => {
        return item
          .replace(/ & /g, "_and_")
          .replace(/&/g, "and")
          .replace(",", " addcomma");
      });

      const subcategory = newselectedSubCategories.join("|");
      subcategoryText = "&sub_category=" + subcategory;
    }

    let vendorText = "";
    if (selectedVendors && selectedVendors.length > 0) {
      const vendor = selectedVendors.join(",");
      vendorText = "&vendor=" + vendor;
    }

    let brandText = "";
    if (selectedBrands && selectedBrands.length > 0) {
      const brands = selectedBrands.join(",");

      brandText = "&brand=" + brands;
    }
    let orderByText = "";
    if (OrderBy) {
      orderByText = "&order_by=" + OrderBy;
      // + "&sort=" + sort;
    }
    const country_id = getCookie("country_id")
      ? getCookie("country_id")
      : postCountry_id
      ? postCountry_id
      : 1;

    let country_idText = "&country_id=" + country_id;
    let url = `?query=${valuesearch}&page=1&page_size=${limit}${text}${categoryText}${vendorText}${subcategoryText}${brandText}${orderByText}`;

    if (exclude_accessory) {
      url = url + "&exclude_accessory=" + exclude_accessory;
    }

    if (only_discounted) {
      url = url + "&only_discounted=" + only_discounted;
    }

    if (available_only) {
      url = url + "&available_only=" + available_only;
    }

    if (only_pricedroped) {
      url = url + "&only_pricedroped=" + only_pricedroped;
    }
    history.replace({
      pathname: history.location.pathname,
      search: url,
    });
  };

  const [isActive, setIsActive] = useState(
    arrVendor && arrVendor.length > 0 ? true : false
  );

  const [isActiveBrand, setIsActiveBrand] = useState(
    arrBrand && arrBrand.length > 0 ? true : false
  );

  const [isActiveCategory, setIsActiveCategory] = useState(
    ((arrCategory && arrCategory.length) ||
      (arrSubCategory && arrSubCategory.length > 0)) > 0
      ? true
      : false
  );

  const [activeCategory, setActiveCategory] = useState(
    arrSubCategory && arrSubCategory.length ? [0] : []
  );
  const [isActiveSubCategory, setIsActiveSubcategory] = useState(
    arrSubCategory && arrSubCategory.length > 0 ? true : false
  );

  const [activeIndex, setActiveIndex] = useState(
    arrSubCategory && arrSubCategory.length > 0 ? 0 : undefined
  );

  const [brandCount, setBrandCount] = useState(10);
  const [brandFilter, setBrandFilter] = useState();

  const [actualSubcategoriesCount, setActualSubCategoriesCount] = useState([]);
  const [availableOnlyCount, setAvailableOnlyCount] = useState();
  const [priceDroppedOnlyCount, setPriceDroppedOnlyCount] = useState();
  const [discountedCount, setDiscountedCount] = useState();
  const [excludedCount, setExcludedCount] = useState();

  const [isDataEmpty, setIsDataEmpty] = useState(false);

  const [sortList, setSortList] = useState([]);
  const [postCountry_id, setPostCountryId] = useState(
    postCountry_idPrev ? postCountry_idPrev : 1
  );

  const prevSelectedCategoriesRef = useRef([]);
  const prevSelectedVendorsRef = useRef([]);
  const prevSelectedSubCategoriesRef = useRef([]);
  const prevSelectedBrandsRef = useRef([]);
  const prevLimitRef = useRef(limit);
  const prevOrderByRef = useRef();

  const prevSearchRef = useRef(searchText);
  const prevAvailableOnly = useRef();
  const prevExclude = useRef();
  const prevonly_discounted = useRef();
  const prevonly_pricedroped = useRef();

  const country_name = getCookie("country_name")
    ? getCookie("country_name")
    : "kuwait";

  useEffect(() => {
    document.title = "Offers | Genie Saves";
  }, []);

  useEffect(() => {
    fetchSortList();
  }, []);

  const fetchSortList = () => {
    const Country_ID = getCookie("country_id")
      ? getCookie("country_id")
      : postCountry_idPrev
      ? postCountry_idPrev
      : 1;
    api(baseUrl)
      .get(SORT_LIST + "?country_id=" + Country_ID)
      .then((res) => {
        if (res.data.success) {
          setSortList(res.data.data);
        }
      })
      .catch((e) => console.log(e));
  };

  const loadMoreBrands = (length, text) => {
    let prevBrandCount = brandCount;
    if ("more" == text && prevBrandCount < brands.length) {
      prevBrandCount = prevBrandCount + length;
      setBrandCount(prevBrandCount);
    } else if ("less" == text) {
      setBrandCount(length);
    }
  };

  useEffect(() => {
    fetchCategories();
    fetchSubcategories();
    fetchVendors();
    fetchBrands();
  }, []);

  useEffect(() => {
    history &&
      history.location &&
      history.location.state &&
      history.location.state !== undefined &&
      history.location.state.selectedCategory &&
      setIsShowFilter(true);
  }, [history]);

  useEffect(() => {
    const subCategoriesTemp = [];
    const subCategoriesTempCount = [];

    if (subCategories && subCategories.length > 0) {
      subCategories.forEach((item) => {
        const Category = item.Category;
        subCategoriesTemp[Category] = subCategoriesTemp[Category] || [];
        subCategoriesTemp[Category].push(item.Sub_Category);
        subCategoriesTempCount[Category] =
          subCategoriesTempCount[Category] || [];
        subCategoriesTempCount[Category].push(item.Sub_Category_count);
      });

      const resultKeys = Object.keys(subCategoriesTemp);
      const resultArray = Object.values(subCategoriesTemp);
      // const resultArrayCount = Object.values(subCategoriesTempCount);

      setActualSubCategories(resultArray);
      setActualCategories(resultKeys);
      setActualSubCategoriesCount(subCategoriesTempCount);
    } else {
      setActualSubCategories([]);
      setActualCategories([]);
      setActualSubCategoriesCount([]);
    }
  }, [subCategories]);

  useEffect(() => {
    if ("Mobile" !== device) {
      const prevCategories = prevSelectedCategoriesRef.current;
      const currentCategories = [...selectedCategories];

      const prevVendors = prevSelectedVendorsRef.current;
      const currentVendors = [...selectedVendors];

      const prevSubCategories = prevSelectedSubCategoriesRef.current;
      const currentSubCategories = [...selectedSubCategories];

      const prevBrands = prevSelectedBrandsRef.current;
      const currentBrands = [...selectedBrands];

      const currentAvailableOnly = available_only;
      const currentexclude_accessory = exclude_accessory;
      const currentonly_discounted = only_discounted;
      const currentonly_pricedroped = only_pricedroped;
      if (
        JSON.stringify(prevCategories) !== JSON.stringify(selectedCategories) ||
        JSON.stringify(prevVendors) !== JSON.stringify(selectedVendors) ||
        JSON.stringify(prevSubCategories) !==
          JSON.stringify(selectedSubCategories) ||
        JSON.stringify(prevBrands) !== JSON.stringify(selectedBrands) ||
        prevLimitRef.current !== limit ||
        prevOrderByRef.current !== OrderBy ||
        prevAvailableOnly.current !== available_only ||
        prevExclude.current !== exclude_accessory ||
        prevonly_discounted.current !== only_discounted ||
        prevonly_pricedroped.current !== only_pricedroped
      ) {
        setTimeout(() => {
          fetchProductList(
            searchValue && "" !== searchValue ? searchValue : searchText
          );
        }, 1000);

        prevSelectedCategoriesRef.current = currentCategories;
        prevSelectedVendorsRef.current = currentVendors;
        prevSelectedSubCategoriesRef.current = currentSubCategories;
        prevSelectedBrandsRef.current = currentBrands;

        prevLimitRef.current = limit;
        prevOrderByRef.current = OrderBy;
        prevAvailableOnly.current = currentAvailableOnly;
        prevExclude.current = currentexclude_accessory;
        prevonly_discounted.current = currentonly_discounted;
        prevonly_pricedroped.current = currentonly_pricedroped;

        let valuesearch =
          searchValue && "" !== searchValue ? searchValue : searchText;
        let text = "";

        if (value.length > 0) {
          text = "&price_from=" + value[0] + "&price_to=" + value[1];
        }

        let categoryText = "";
        if (selectedCategories && selectedCategories.length > 0) {
          const newselectedCategories = selectedCategories.map((item) => {
            return item
              .replace(/ & /g, "_and_")
              .replace(/&/g, "and")
              .replace(",", " addcomma");
          });

          let category = newselectedCategories.join("|");

          categoryText = "&category=" + category;
        }

        let subcategoryText = "";
        if (selectedSubCategories && selectedSubCategories.length > 0) {
          const newselectedSubCategories = selectedSubCategories.map((item) => {
            return item
              .replace(/ & /g, "_and_")
              .replace(/&/g, "and")
              .replace(",", " addcomma");
          });

          const subcategory = newselectedSubCategories.join("|");
          subcategoryText = "&sub_category=" + subcategory;
        }

        let vendorText = "";
        if (selectedVendors && selectedVendors.length > 0) {
          const vendor = selectedVendors.join(",");
          vendorText = "&vendor=" + vendor;
        }

        let brandText = "";
        if (selectedBrands && selectedBrands.length > 0) {
          const brands = selectedBrands.join(",");

          brandText = "&brand=" + brands;
        }
        let orderByText = "";
        if (OrderBy) {
          orderByText = "&order_by=" + OrderBy;
          // + "&sort=" + sort;
        }
        const country_id = getCookie("country_id")
          ? getCookie("country_id")
          : postCountry_id
          ? postCountry_id
          : 1;

        let country_idText = "&country_id=" + country_id;

        let url = `?query=${valuesearch}&page=1&page_size=${limit}${text}${categoryText}${vendorText}${subcategoryText}${brandText}${orderByText}`;

        if (exclude_accessory) {
          url = url + "&exclude_accessory=" + exclude_accessory;
        }

        if (only_discounted) {
          url = url + "&only_discounted=" + only_discounted;
        }

        if (available_only) {
          url = url + "&available_only=" + available_only;
        }

        if (only_pricedroped) {
          url = url + "&only_pricedroped=" + only_pricedroped;
        }

        history.replace({
          pathname: history.location.pathname,
          search: url,
        });
      }
    }
  }, [
    selectedCategories,
    selectedSubCategories,
    selectedVendors,
    selectedBrands,
    exclude_accessory,
    only_discounted,
    available_only,
    only_pricedroped,
    OrderBy,
    sort,
    // value,
    limit,
  ]);

  useEffect(() => {
    if ("Mobile" === device) {
      setTimeout(() => {
        fetchProductList(
          searchValue && "" !== searchValue ? searchValue : searchText
        );
      }, 1000);

      let valuesearch =
        searchValue && "" !== searchValue ? searchValue : searchText;
      let text = "";

      if (value.length > 0) {
        text = "&price_from=" + value[0] + "&price_to=" + value[1];
      }

      let categoryText = "";
      if (selectedCategories && selectedCategories.length > 0) {
        const newselectedCategories = selectedCategories.map((item) => {
          return item
            .replace(/ & /g, "_and_")
            .replace(/&/g, "and")
            .replace(",", " addcomma");
        });

        let category = newselectedCategories.join("|");

        categoryText = "&category=" + category;
      }

      let subcategoryText = "";
      if (selectedSubCategories && selectedSubCategories.length > 0) {
        const newselectedSubCategories = selectedSubCategories.map((item) => {
          return item
            .replace(/ & /g, "_and_")
            .replace(/&/g, "and")
            .replace(",", " addcomma");
        });

        const subcategory = newselectedSubCategories.join("|");
        subcategoryText = "&sub_category=" + subcategory;
      }

      let vendorText = "";
      if (selectedVendors && selectedVendors.length > 0) {
        const vendor = selectedVendors.join(",");
        vendorText = "&vendor=" + vendor;
      }

      let brandText = "";
      if (selectedBrands && selectedBrands.length > 0) {
        const brands = selectedBrands.join(",");

        brandText = "&brand=" + brands;
      }
      let orderByText = "";
      if (OrderBy) {
        orderByText = "&order_by=" + OrderBy;
        // + "&sort=" + sort;
      }

      const country_id = getCookie("country_id")
        ? getCookie("country_id")
        : postCountry_id
        ? postCountry_id
        : 1;

      let country_idText = "&country_id=" + country_id;

      let url = `?query=${valuesearch}&page=1&page_size=${limit}${text}${categoryText}${vendorText}${subcategoryText}${brandText}${orderByText}`;

      if (exclude_accessory) {
        url = url + "&exclude_accessory=" + exclude_accessory;
      }

      if (only_discounted) {
        url = url + "&only_discounted=" + only_discounted;
      }

      if (available_only) {
        url = url + "&available_only=" + available_only;
      }

      if (only_pricedroped) {
        url = url + "&only_pricedroped=" + only_pricedroped;
      }
      history.replace({
        pathname: history.location.pathname,
        search: url,
      });
    }
  }, [OrderBy, sort, limit]);

  const baseUrl = process.env.REACT_APP_API_BASEURL;

  const handleVendor = (e) => {
    setPage(1);
    // setLimit(20);
    const prevValues = [...selectedVendors];

    if (e.target.checked) {
      prevValues.push(e.target.value);
      setSelectedVendors(prevValues);
    } else {
      const newArray = prevValues.filter((item) => item !== e.target.value);
      setSelectedVendors(newArray);
    }
    setReset(false);
  };

  const handleBrand = (e) => {
    setPage(1);
    // setLimit(20);
    const prevValues = [...selectedBrands];

    if (e.target.checked) {
      prevValues.push(e.target.value);
      setSelectedBrands(prevValues);
    } else {
      const newArray = prevValues.filter((item) => item !== e.target.value);
      setSelectedBrands(newArray);
    }
    setReset(false);
  };
  const handleCategories = (e) => {
    // setLimit(20);
    const prevValues = [...selectedCategories];

    if (e.target.checked) {
      prevValues.push(e.target.value);
      setSelectedCategories(prevValues);
    } else {
      const newArray = prevValues.filter((item) => item !== e.target.value);
      setSelectedCategories(newArray);
    }
    setReset(false);
    // setExpandedItem(0);
  };

  const handleSubcategories = (e, key) => {
    // setLimit(20);
    const prevValues = [...selectedSubCategories];
    const prevCategories = [...selectedCategories];

    const removeCategory = actualCategories[key];

    if (removeCategory) {
      const newArray = prevCategories.filter((item) => item !== removeCategory);
      setSelectedCategories(newArray);
    }
    if (e.target.checked) {
      prevValues.push(e.target.value);
      setSelectedSubCategories(prevValues);
      setActiveIndex(0);
    } else {
      const newArray = prevValues.filter((item) => item !== e.target.value);
      setSelectedSubCategories(newArray);
      setActiveIndex(undefined);
    }
    setReset(false);
  };
  const fetchCategories = () => {
    const country_id = getCookie("country_id")
      ? getCookie("country_id")
      : postCountry_idPrev
      ? postCountry_idPrev
      : 1;
    api(baseUrl)
      .get(CATEGORIES + "?country_id=" + country_id)
      .then((res) => {
        if (res.data.success) {
          setCategories(res.data.data);
        }
      })
      .catch((e) => console.log(e));
  };

  const fetchSubcategories = () => {
    const country_id = getCookie("country_id")
      ? getCookie("country_id")
      : postCountry_idPrev
      ? postCountry_idPrev
      : 1;
    api(baseUrl)
      .get(SUBCATEGORIES + "?country_id=" + country_id)
      .then((res) => {
        if (res.data.success) {
          setSubcategories(res.data.data);
        }
      })
      .catch((e) => console.log(e));
  };

  const fetchVendors = () => {
    const country_id = getCookie("country_id")
      ? getCookie("country_id")
      : postCountry_idPrev
      ? postCountry_idPrev
      : 1;
    api(baseUrl)
      .get(VENDORS + "?category=Items&country_id=" + country_id)
      .then((res) => {
        if (res.data.success) {
          setVendors(res.data.data);
        }
      })
      .catch((e) => console.log(e));
  };

  const fetchBrands = () => {
    const country_id = getCookie("country_id")
      ? getCookie("country_id")
      : postCountry_idPrev
      ? postCountry_idPrev
      : 1;
    api(baseUrl)
      .get(BRANDS + "?country_id=" + country_id)
      .then((res) => {
        if (res.data.success) {
          setBrands(res.data.data);
          setOriginalBrands(res.data.data);
        }
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    const prevSearch = prevSearchRef.current;
    const currentSearch = searchText;

    if (searchText !== "") {
      setTimeout(() => {
        fetchProductList(searchText);
      }, 1000);

      setSearchValue(searchText.replace("%20", " ").replace(/%20| /g, " "));
    }
  }, [searchText]);

  useEffect(() => {
    const prevSearch = prevSearchRef.current;
    const currentSearch = searchText;

    if (searchText === "" && reset) {
      setTimeout(() => {
        fetchProductList(searchText);
      }, 1000);

      setSearchValue(searchText.replace("%20", " ").replace(/%20| /g, " "));
    }
  }, [reset]);

  const fetchProductList = (searchText) => {
    let user_id = getCookie("user_id");
    setLoading(true);

    const offset_rows = (page - 1) * limit;

    let text = "";
    if (value.length > 0) {
      text = "&price_from=" + value[0] + "&price_to=" + value[1];
    }

    let category = "";
    if (selectedCategories && selectedCategories.length > 0) {
      const newselectedCategories = selectedCategories.map((item) => {
        return item.replace(/ & /g, "_and_").replace(/&/g, "and");
      });

      category = newselectedCategories.join("|");
    }

    let subcategory = "";
    if (selectedSubCategories && selectedSubCategories.length > 0) {
      const newselectedSubCategories = selectedSubCategories.map((item) => {
        return item.replace(/ & /g, "_and_").replace(/&/g, "and");
      });

      subcategory = newselectedSubCategories.join("|");
    }

    let vendor = "";
    if (selectedVendors && selectedVendors.length > 0) {
      vendor = selectedVendors.join(",");
    }

    let brands = "";
    if (selectedBrands && selectedBrands.length > 0) {
      brands = selectedBrands.join(",");
    }
    // const textTemp =
    //   searchValue && "" !== searchValue ? searchValue : searchText;
    if (!user_id) {
      user_id = "";
    }
    const session_id = getCookie("unique_session_id")
      ? getCookie("unique_session_id")
      : null;

    const country_id = getCookie("country_id")
      ? getCookie("country_id")
      : postCountry_idPrev
      ? postCountry_idPrev
      : 1;
    api(baseUrl)
      .get(
        SEARCH +
          "?search_text=" +
          searchText +
          "&offset_rows=" +
          offset_rows +
          "&page_size=" +
          limit +
          "&order_by=" +
          OrderBy +
          "&sort=" +
          sort +
          text +
          "&category=" +
          category +
          "&vendor=" +
          vendor +
          "&exclude_accessory=" +
          exclude_accessory +
          "&only_discounted=" +
          only_discounted +
          "&available_only=" +
          available_only +
          "&sub_category=" +
          subcategory +
          "&brand=" +
          brands +
          "&user_id=" +
          user_id +
          "&session_id=" +
          session_id +
          "&only_pricedroped=" +
          only_pricedroped +
          "&country_id=" +
          country_id
      )
      .then((res) => {
        setLoading(false);
        if (res.data.success) {
          if (res.data.data.length > 0) {
            setProductList(res.data.data);
            setBrands(res.data.brand);
            setOriginalBrands(res.data.brand);
            setSubcategories(res.data.sub_category);
            setVendors(res.data.vendor);

            // setMinPrice(res.data.min_price);
            setMaxPrice(res.data.max_price);
            setCategoryCount(res.data.category);
            setAvailableOnlyCount(
              res.data.available && res.data.available.length > 0
                ? res.data.available[0].Num_of_Items
                : 0
            );
            setExcludedCount(
              res.data.excluded && res.data.excluded.length > 0
                ? res.data.excluded[0].Num_of_Items
                : 0
            );
            setDiscountedCount(
              res.data.discounted && res.data.discounted.length > 0
                ? res.data.discounted[0].Num_of_Items
                : 0
            );

            setPriceDroppedOnlyCount(
              res.data.price_dropped && res.data.price_dropped.length > 0
                ? res.data.price_dropped[0].Num_of_Items
                : 0
            );
          } else {
            setIsDataEmpty(true);
            setProductList([]);
            setBrands(res.data.brand);
            setOriginalBrands(res.data.brand);
            setSubcategories(res.data.sub_category);
            setVendors(res.data.vendor);

            // setMinPrice(res.data.min_price);
            setMaxPrice(res.data.max_price);
            setCategoryCount(res.data.category);
            setAvailableOnlyCount(
              res.data.available && res.data.available.length > 0
                ? res.data.available[0].Num_of_Items
                : 0
            );
            setExcludedCount(
              res.data.excluded && res.data.excluded.length > 0
                ? res.data.excluded[0].Num_of_Items
                : 0
            );
            setDiscountedCount(
              res.data.discounted && res.data.discounted.length > 0
                ? res.data.discounted[0].Num_of_Items
                : 0
            );
            setPriceDroppedOnlyCount(
              res.data.price_dropped && res.data.price_dropped.length > 0
                ? res.data.price_dropped[0].Num_of_Items
                : 0
            );
          }
          setTotalCount(res.data.totalCount);
        }
      })
      .catch((e) => console.log(e));
  };

  const handleLink = (link) => {
    window.open(link, "_blank");
  };

  const handleChange = (e) => {
    setPage(1);
    // setLimit(20);
    setSearchValue(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      setPage(1);
      // setLimit(20);
      const offset_rows = 0;

      let text = "";
      if (value.length > 0) {
        text = "&price_from=" + value[0] + "&price_to=" + value[1];
      }

      let categoryText = "";
      if (selectedCategories && selectedCategories.length > 0) {
        const newselectedCategories = selectedCategories.map((item) => {
          return item.replace(/ & /g, "_and_").replace(/&/g, "and");
        });

        let category = newselectedCategories.join("|");

        categoryText = "&category=" + category;
      }

      let subcategoryText = "";
      if (selectedSubCategories && selectedSubCategories.length > 0) {
        const newselectedSubCategories = selectedSubCategories.map((item) => {
          return item.replace(/ & /g, "_and_").replace(/&/g, "and");
        });

        const subcategory = newselectedSubCategories.join("|");
        subcategoryText = "&sub_category=" + subcategory;
      }

      let vendorText = "";
      if (selectedVendors && selectedVendors.length > 0) {
        const vendor = selectedVendors.join(",");
        vendorText = "&vendor=" + vendor;
      }

      let brandText = "";
      if (selectedBrands && selectedBrands.length > 0) {
        const brands = selectedBrands.join(",");

        brandText = "&brand=" + brands;
      }
      let orderByText = "";
      if (OrderBy) {
        orderByText = "&order_by=" + OrderBy;
        // + "&sort=" + sort;
      }
      const country_id = getCookie("country_id")
        ? getCookie("country_id")
        : postCountry_id
        ? postCountry_id
        : 1;

      let country_idText = "&country_id=" + country_id;
      let url = `?query=${e.target.value}&page=1&page_size=${limit}${text}${categoryText}${vendorText}${subcategoryText}${brandText}${orderByText}`;

      if (exclude_accessory) {
        url = url + "&exclude_accessory=" + exclude_accessory;
      }

      if (only_discounted) {
        url = url + "&only_discounted=" + only_discounted;
      }

      if (available_only) {
        url = url + "&available_only=" + available_only;
      }

      if (only_pricedroped) {
        url = url + "&only_pricedroped=" + only_pricedroped;
      }
      setReset(false);
      history.push({
        // pathname: `${country_name}${routes.BASE_SEARCH_ROUTE}`,
        search: `${url}`,
      });
    }
  };

  const handlePageClick = (currentpage) => {
    let user_id = getCookie("user_id");
    window.scroll(0, 0);

    setPage(currentpage.selected + 1);
    const currentPageSelected = currentpage.selected + 1;
    const offset_rows = (currentPageSelected - 1) * limit;

    setLoading(true);
    let text = "";
    if (value.length > 0) {
      text = "&price_from=" + value[0] + "&price_to=" + value[1];
    }

    let category = "";
    if (selectedCategories && selectedCategories.length > 0) {
      const newselectedCategories = selectedCategories.map((item) => {
        return item.replace(/ & /g, "_and_").replace(/&/g, "and");
      });

      category = newselectedCategories.join("|");
    }

    let subcategory = "";
    if (selectedSubCategories && selectedSubCategories.length > 0) {
      const newselectedSubCategories = selectedSubCategories.map((item) => {
        return item.replace(/ & /g, "_and_").replace(/&/g, "and");
      });

      subcategory = newselectedSubCategories.join("|");
    }

    let vendor = "";
    if (selectedVendors && selectedVendors.length > 0) {
      vendor = selectedVendors.join(",");
    }

    let brands = "";
    if (selectedBrands && selectedBrands.length > 0) {
      brands = selectedBrands.join(",");
    }

    if (!user_id) {
      user_id = "";
    }

    const textTemp =
      searchValue && "" !== searchValue ? searchValue : searchText;

    let categoryText = "";
    if (selectedCategories && selectedCategories.length > 0) {
      const newselectedCategories = selectedCategories.map((item) => {
        return item.replace(/ & /g, "_and_").replace(/&/g, "and");
      });

      let category = newselectedCategories.join("|");

      categoryText = "&category=" + category;
    }

    let subcategoryText = "";
    if (selectedSubCategories && selectedSubCategories.length > 0) {
      const newselectedSubCategories = selectedSubCategories.map((item) => {
        return item.replace(/ & /g, "_and_").replace(/&/g, "and");
      });

      const subcategory = newselectedSubCategories.join("|");
      subcategoryText = "&sub_category=" + subcategory;
    }

    let vendorText = "";
    if (selectedVendors && selectedVendors.length > 0) {
      const vendor = selectedVendors.join(",");
      vendorText = "&vendor=" + vendor;
    }

    let brandText = "";
    if (selectedBrands && selectedBrands.length > 0) {
      const brands = selectedBrands.join(",");

      brandText = "&brand=" + brands;
    }
    let orderByText = "";
    if (OrderBy) {
      orderByText = "&order_by=" + OrderBy;
      // + "&sort=" + sort;
    }
    const country_idSelected = getCookie("country_id")
      ? getCookie("country_id")
      : postCountry_id
      ? postCountry_id
      : 1;

    let country_idText = "&country_id=" + country_idSelected;
    let url = `?query=${textTemp}&page=${currentPageSelected}&page_size=${limit}${text}${categoryText}${vendorText}${subcategoryText}${brandText}${orderByText}`;

    if (exclude_accessory) {
      url = url + "&exclude_accessory=" + exclude_accessory;
    }

    if (only_discounted) {
      url = url + "&only_discounted=" + only_discounted;
    }

    if (available_only) {
      url = url + "&available_only=" + available_only;
    }

    if (only_pricedroped) {
      url = url + "&only_pricedroped=" + only_pricedroped;
    }

    const session_id = getCookie("unique_session_id")
      ? getCookie("unique_session_id")
      : null;

    const country_id = getCookie("country_id")
      ? getCookie("country_id")
      : postCountry_idPrev
      ? postCountry_idPrev
      : 1;

    api(baseUrl)
      .get(
        SEARCH +
          "?search_text=" +
          textTemp +
          "&offset_rows=" +
          offset_rows +
          "&page_size=" +
          limit +
          "&order_by=" +
          OrderBy +
          "&sort=" +
          sort +
          text +
          "&category=" +
          category +
          "&vendor=" +
          vendor +
          "&exclude_accessory=" +
          exclude_accessory +
          "&only_discounted=" +
          only_discounted +
          "&available_only=" +
          available_only +
          "&sub_category=" +
          subcategory +
          "&brand=" +
          brands +
          "&user_id=" +
          user_id +
          "&session_id=" +
          session_id +
          "&only_pricedroped=" +
          only_pricedroped +
          "&country_id=" +
          country_id
      )
      .then((res) => {
        setLoading(false);
        if (res.data.success) {
          if (res.data.data.length > 0) {
            setProductList(res.data.data);
            setBrands(res.data.brand);
            setOriginalBrands(res.data.brand);
            setSubcategories(res.data.sub_category);
            setVendors(res.data.vendor);

            // setMinPrice(res.data.min_price);
            setMaxPrice(res.data.max_price);
            setCategoryCount(res.data.category);
            setAvailableOnlyCount(
              res.data.available && res.data.available.length > 0
                ? res.data.available[0].Num_of_Items
                : 0
            );
            setExcludedCount(
              res.data.excluded && res.data.excluded.length > 0
                ? res.data.excluded[0].Num_of_Items
                : 0
            );
            setDiscountedCount(
              res.data.discounted && res.data.discounted.length > 0
                ? res.data.discounted[0].Num_of_Items
                : 0
            );
            setPriceDroppedOnlyCount(
              res.data.price_dropped && res.data.price_dropped.length > 0
                ? res.data.price_dropped[0].Num_of_Items
                : 0
            );
          } else {
            setIsDataEmpty(true);
            setProductList([]);
            setBrands(res.data.brand);
            setOriginalBrands(res.data.brand);
            setSubcategories(res.data.sub_category);
            setVendors(res.data.vendor);

            // setMinPrice(res.data.min_price);
            setMaxPrice(res.data.max_price);
            setCategoryCount(res.data.category);
            setAvailableOnlyCount(
              res.data.available && res.data.available.length > 0
                ? res.data.available[0].Num_of_Items
                : 0
            );
            setExcludedCount(
              res.data.excluded && res.data.excluded.length > 0
                ? res.data.excluded[0].Num_of_Items
                : 0
            );
            setDiscountedCount(
              res.data.discounted && res.data.discounted.length > 0
                ? res.data.discounted[0].Num_of_Items
                : 0
            );
            setPriceDroppedOnlyCount(
              res.data.price_dropped && res.data.price_dropped.length > 0
                ? res.data.price_dropped[0].Num_of_Items
                : 0
            );
          }
          setTotalCount(res.data.totalCount);
        }
      })
      .catch((e) => console.log(e));
    history.replace({
      pathname: history.location.pathname,
      search: url,
    });
  };

  const handleRemoveCategory = (item) => {
    const prevValues = [...selectedCategories];

    const newArray = prevValues.filter((itemIn) => itemIn !== item);
    setSelectedCategories(newArray);
  };

  const handleRemoveSubCategory = (item) => {
    const prevValues = [...selectedSubCategories];

    const newArray = prevValues.filter((itemIn) => itemIn !== item);
    setSelectedSubCategories(newArray);
  };

  const handleSort = (value, sort, titleValue) => {
    setOrderBy(value);
    setSort(sort);
    setTitle(titleValue);
    setPage(1);
    setReset(false);
  };

  const handleSlider = (value, index) => {
    setValue(value);
    setPage(1);
    setMinPriceManual("");
    setMaxPriceManual("");
    setReset(false);
    // setLimit(20);
  };

  const handleAddToFavourites = (item) => {
    if (!token) {
      setIsShowPopup(true);
    } else {
      const user_id = getCookie("user_id");
      const session_id = getCookie("unique_session_id")
        ? getCookie("unique_session_id")
        : null;
      const data = {
        Country_ID: 1,
        User_ID: user_id,
        Vendor: item.Vendor,
        Item_Key: item.Item_Key,
        Price: item.Selling_Price,
        Item_name: item.Item_name,
        Item_Image_URL: item.Item_Image_URL,
        Item_URL: item.Item_URL,
        session_id: session_id,
      };

      const headers = {
        Authorization: "Bearer " + token,
      };

      api(baseUrl, headers)
        .post(ADD_FAVOURITES, data)
        .then((res) => {
          if (res.data.success) {
            let prevProductList = [...productList];

            prevProductList = prevProductList.map((itemIn) => {
              if (
                itemIn.Vendor == item.Vendor &&
                itemIn.Item_Key == item.Item_Key
              ) {
                itemIn.is_a_Favorite = "1";
              }
              return itemIn;
            });
            setProductList(prevProductList);
          } else if ("Unauthorized." == res.data) {
            deleteCookie("user_id");
            deleteCookie("email");
            deleteCookie("token");
            history.push({ pathname: routes.LOGIN });
          }
        })
        .catch((e) => console.log(e));
    }
  };

  const handleRemoveFavourites = (item) => {
    const COUNTRY_ID = 1;
    const user_id = getCookie("user_id");
    const headers = {
      Authorization: "Bearer " + token,
    };

    const session_id = getCookie("unique_session_id")
      ? getCookie("unique_session_id")
      : null;

    const data = {
      Country_ID: COUNTRY_ID,
      User_ID: user_id,
      Vendor: item.Vendor,
      Item_Key: item.Item_Key,
      session_id: session_id,
    };

    api(baseUrl, headers)
      .post(REMOVE_FAVOURITES, data)
      .then((res) => {
        if (res.data.success) {
          const prevProductList = [...productList];

          prevProductList.map((itemIn) => {
            if (
              itemIn.Vendor == item.Vendor &&
              itemIn.Item_Key == item.Item_Key
            ) {
              itemIn.is_a_Favorite = "0";
            }
            return itemIn;
          });
          setProductList(prevProductList);
        }
      })
      .catch((e) => console.log(e));
  };

  const handleExcludeAccessory = (e) => {
    setPage(1);
    if (e.target.checked) {
      setExcludeAccessory(1);
    } else {
      setExcludeAccessory(0);
    }
    setReset(false);
  };

  const handleOnlyDiscounted = (e) => {
    setPage(1);
    if (e.target.checked) {
      setOnlyDiscounted(1);
    } else {
      setOnlyDiscounted(0);
    }
    setReset(false);
  };

  const handleOnlyAvailable = (e) => {
    setPage(1);
    if (e.target.checked) {
      setAvailableOnly(1);
    } else {
      setAvailableOnly(0);
    }
    setReset(false);
  };

  const handleOnlyPriceDropped = (e) => {
    setPage(1);
    if (e.target.checked) {
      setAPriceDroppedOnly(1);
    } else {
      setAPriceDroppedOnly(0);
    }
    setReset(false);
  };

  const handleReset = () => {
    setReset(true);
    setPage(1);
    setAvailableOnly(0);
    setAPriceDroppedOnly(0);
    setOnlyDiscounted(0);
    setExcludeAccessory(0);
    setSelectedCategories([]);
    setSelectedSubCategories([]);
    setSelectedVendors([]);
    setSelectedBrands([]);
    setSearchValue("");
    setActiveCategory([]);
    setIsActiveCategory(false);
    setLimit(20);
    setValue([]);
    const prevBrands = [...originalBrands];
    setBrands(prevBrands);
    setBrandFilter("");
    if (device == "Mobile") {
      window.location = `${country_name}${routes.BASE_SEARCH_ROUTE}?query=&page=1&page_size=20`;
    } else {
      history.push({
        // pathname: `${country_name}${routes.BASE_SEARCH_ROUTE}`,
        // search: `?query=`,
      });
    }
  };

  const handleProductDetail = (item) => {
    window.open(
      routes.PRODUCTDETAIL +
        "?Vendor=" +
        item.Vendor +
        "&Item_Key=" +
        item.Item_Key,
      "_blank"
    );
  };

  const handleSearch = (e) => {
    setPage(1);
    const offset_rows = 0;

    let text = "";
    if (value.length > 0) {
      text = "&price_from=" + value[0] + "&price_to=" + value[1];
    }

    let categoryText = "";
    if (selectedCategories && selectedCategories.length > 0) {
      const newselectedCategories = selectedCategories.map((item) => {
        return item.replace(/ & /g, "_and_").replace(/&/g, "and");
      });

      let category = newselectedCategories.join("|");

      categoryText = "&category=" + category;
    }

    let subcategoryText = "";
    if (selectedSubCategories && selectedSubCategories.length > 0) {
      const newselectedSubCategories = selectedSubCategories.map((item) => {
        return item.replace(/ & /g, "_and_").replace(/&/g, "and");
      });

      const subcategory = newselectedSubCategories.join("|");
      subcategoryText = "&sub_category=" + subcategory;
    }

    let vendorText = "";
    if (selectedVendors && selectedVendors.length > 0) {
      const vendor = selectedVendors.join(",");
      vendorText = "&vendor=" + vendor;
    }

    let brandText = "";
    if (selectedBrands && selectedBrands.length > 0) {
      const brands = selectedBrands.join(",");

      brandText = "&brand=" + brands;
    }
    let orderByText = "";
    if (OrderBy) {
      orderByText = "&order_by=" + OrderBy;
      // + "&sort=" + sort;
    }
    let url = `?query=${searchValue}&page=1&page_size=${limit}${text}${categoryText}${vendorText}${subcategoryText}${brandText}${orderByText}`;

    if (exclude_accessory) {
      url = url + "&exclude_accessory=" + exclude_accessory;
    }

    if (only_discounted) {
      url = url + "&only_discounted=" + only_discounted;
    }

    if (available_only) {
      url = url + "&available_only=" + available_only;
    }

    if (only_pricedroped) {
      url = url + "&only_pricedroped=" + only_pricedroped;
    }
    history.push({
      // pathname: `${country_name}${routes.BASE_SEARCH_ROUTE}`,
      search: `${url}`,
    });
  };

  const handleBrandFilter = (e) => {
    const searchBrand = e.target.value;
    setBrandFilter(e.target.vaule);
    if (searchBrand) {
      const prevBrands = [...originalBrands];

      const changeFilter = prevBrands.filter((item) => {
        const regex = new RegExp(`${searchBrand}`, "gi");
        return item.Name.match(regex);
      });
      setBrands(changeFilter);
    } else {
      const prevBrands = [...originalBrands];
      setBrands(prevBrands);
      setBrandCount(10);
    }
  };

  const handleInputMin = (e) => {
    setMinPriceManual(e.target.value);
  };

  const handleInputMax = (e) => {
    setMaxPriceManual(e.target.value);
  };

  const handlePriceFilter = () => {
    fetchProductList(
      searchValue && "" !== searchValue ? searchValue : searchText
    );

    let valuesearch =
      searchValue && "" !== searchValue ? searchValue : searchText;
    let text = "";

    if (value.length > 0) {
      text = "&price_from=" + value[0] + "&price_to=" + value[1];
    }
    // if (minPriceManual >= 0 && maxPriceManual) {
    //   text = "&price_from=" + minPriceManual + "&price_to=" + maxPriceManual;
    // }

    let categoryText = "";
    if (selectedCategories && selectedCategories.length > 0) {
      const newselectedCategories = selectedCategories.map((item) => {
        return item
          .replace(/ & /g, "_and_")
          .replace(/&/g, "and")
          .replace(",", " addcomma");
      });

      let category = newselectedCategories.join("|");

      categoryText = "&category=" + category;
    }

    let subcategoryText = "";
    if (selectedSubCategories && selectedSubCategories.length > 0) {
      const newselectedSubCategories = selectedSubCategories.map((item) => {
        return item
          .replace(/ & /g, "_and_")
          .replace(/&/g, "and")
          .replace(",", " addcomma");
      });

      const subcategory = newselectedSubCategories.join("|");
      subcategoryText = "&sub_category=" + subcategory;
    }

    let vendorText = "";
    if (selectedVendors && selectedVendors.length > 0) {
      const vendor = selectedVendors.join(",");
      vendorText = "&vendor=" + vendor;
    }

    let brandText = "";
    if (selectedBrands && selectedBrands.length > 0) {
      const brands = selectedBrands.join(",");

      brandText = "&brand=" + brands;
    }
    let orderByText = "";
    if (OrderBy) {
      orderByText = "&order_by=" + OrderBy;
      // + "&sort=" + sort;
    }
    const country_id = getCookie("country_id")
      ? getCookie("country_id")
      : postCountry_id
      ? postCountry_id
      : 1;

    let country_idText = "&country_id=" + country_id;
    let url = `?query=${valuesearch}&page=1&page_size=${limit}${text}${categoryText}${vendorText}${subcategoryText}${brandText}${orderByText}`;

    if (exclude_accessory) {
      url = url + "&exclude_accessory=" + exclude_accessory;
    }

    if (only_discounted) {
      url = url + "&only_discounted=" + only_discounted;
    }

    if (available_only) {
      url = url + "&available_only=" + available_only;
    }

    if (only_pricedroped) {
      url = url + "&only_pricedroped=" + only_pricedroped;
    }

    history.replace({
      pathname: history.location.pathname,
      search: url,
    });
  };

  return (
    <main className="search-page test">
      <Container>
        <Row>
          <Col className="text-center d-flex1 justify-content-center align-items-center title-wrap pt-sm-2 pt-2 mt-sm-5 mt-4 mb-sm-2 mb-0 col">
            {/* <p>— SEARCH —</p> */}
            <h1 className="section-title mb-1">
              Find the Best Online Offers in Kuwait All in One Place
            </h1>
            <span></span>
          </Col>
        </Row>
      </Container>
      <div className="search-wrap">
        <section id="search-bar" className="mt-0 px-0 ">
          <Container>
            <Row>
              <div className="col-sm-2"></div>
              <div className="col-sm-8">
                <div className="d-flex align-items-center">
                  <Form className="d-flex" onSubmit={(e) => e.preventDefault()}>
                    <Form.Control
                      type="Search product here"
                      placeholder="Search product here"
                      className=""
                      aria-label="Search product here"
                      value={searchValue}
                      onChange={(e) => handleChange(e)}
                      onKeyDown={(e) => handleKeyDown(e)}
                    />
                    <Button
                      onClick={handleSearch}
                      disabled={
                        searchValue && "" !== searchValue ? false : true
                      }
                      type="button"
                    >
                      <img src={images.SearchBack} alt="searchBack" />
                    </Button>
                  </Form>
                  <i
                    className="fa fa-info-circle"
                    aria-hidden="true"
                    ref={target3}
                    onClick={() => setShow3(!show3)}
                  ></i>
                  <Overlay
                    target={target3.current}
                    show={show3}
                    placement="bottom"
                  >
                    {(props) => (
                      <Tooltip id="overlay-for-desktop" {...props}>
                        <p align="left">
                          Search Bar: Type your searching key words, similar to
                          Google Search. You also can exclude by typing with a
                          prefix ‘-‘<br></br>
                          <br></br>
                          Examples:<br></br>
                          Laptop i5 512 GB<br></br>
                          TV 60 Inch<br></br>
                          iPhone 15 -screen -cover
                        </p>
                      </Tooltip>
                    )}
                  </Overlay>
                </div>
              </div>
              <div className="col-sm-2"></div>
            </Row>
          </Container>
        </section>
      </div>
      <Container>
        {loading && <CommunityLoaderCircularDash isbackground={false} />}

        <section className="pb-5">
          <Row>
            <div className="col-sm-2 mb-3 for-desktop">
              <div className="d-flex align-items-center">
                <button
                  className="btn-simple btn-show-filter"
                  type="button"
                  onClick={() => setIsShowFilter(!isShowFilter)}
                >
                  {isShowFilter ? "Hide Filter" : "Show Filter"}
                </button>
                <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  ref={target1}
                  onClick={() => setShow1(!show1)}
                ></i>
                <Overlay
                  target={target1.current}
                  show={show1}
                  placement="right"
                >
                  {(props) => (
                    <Tooltip id="overlay-example" {...props}>
                      <p align="left">
                        Show Filter: Show the Filter pane, which is currently
                        hidden. With that you will have many different filtering
                        options.<br></br>
                        <br></br>
                        Hide Filter: Hide Filter pane, so that you can see the
                        main list with full page. Note that you can bring back
                        the Filter pane anytime when you click the ‘Show Filter’
                        again.
                      </p>
                    </Tooltip>
                  )}
                </Overlay>
              </div>
            </div>
            <div className="col-sm-10">
              <div className="col-sm-12 mb-sm-4 mb-0 search-title-wrapper 1">
                <Row>
                  <div className="col-sm-6 d-flex justify-content-between">
                    {reset ? (
                      ""
                    ) : (
                      <>
                        <h5>
                          {totalCount
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                          {totalCount == 0 || totalCount == 1
                            ? "Product"
                            : "Products"}{" "}
                          found
                        </h5>
                        <div className="cat-for-mobile">
                          <div className="reset-button">
                            <button
                              className="btn btn-custom"
                              type="button"
                              onClick={handleReset}
                            >
                              Reset
                            </button>
                            <i
                              className="fa fa-info-circle"
                              aria-hidden="true"
                              ref={target2m}
                              onClick={() => setShow2m(!show2m)}
                            ></i>

                            <Overlay
                              target={target2m.current}
                              show={show2m}
                              placement="top"
                            >
                              {(props) => (
                                <Tooltip id="overlay-example2" {...props}>
                                  Click this ‘Reset’ button to clear all
                                  filterings applied.
                                </Tooltip>
                              )}
                            </Overlay>
                          </div>
                        </div>
                      </>
                    )}
                  </div>

                  <div className="col-sm-6 cat-for-desktop">
                    <section className=" d-flex justify-content-end">
                      {/* second */}
                      <div className="d-flex align-items-center reset-button">
                        <button
                          className="btn btn-custom"
                          type="button"
                          onClick={handleReset}
                        >
                          Reset
                        </button>
                        <i
                          className="fa fa-info-circle"
                          aria-hidden="true"
                          ref={target2}
                          onClick={() => setShow2(!show2)}
                        ></i>

                        <Overlay
                          target={target2.current}
                          show={show2}
                          placement="top"
                        >
                          {(props) => (
                            <Tooltip id="overlay-example2" {...props}>
                              Click this ‘Reset’ button to clear all filterings
                              applied.
                            </Tooltip>
                          )}
                        </Overlay>
                      </div>

                      <SortFilter
                        title={title}
                        handleSort={handleSort}
                        orderBy={OrderBy}
                        setTitle={setTitle}
                        sortList={sortList}
                      />
                    </section>
                  </div>
                </Row>
              </div>
            </div>
          </Row>

          <Row>
            <div
              className="col-sm-3 cat-left "
              style={{ display: isShowFilter ? "block" : "None" }}
            >
              <section className="cat-for-desktop">
                <CategoryFilter
                  setActiveCategory={setActiveCategory}
                  isActiveCategory={isActiveCategory}
                  isActiveSubCategory={isActiveSubCategory}
                  setIsActiveCategory={setIsActiveCategory}
                  actualCategories={actualCategories}
                  actualSubcategories={actualSubcategories}
                  handleCategories={handleCategories}
                  selectedCategories={selectedCategories}
                  selectedSubCategories={selectedSubCategories}
                  setActiveIndex={setActiveIndex}
                  handleSubcategories={handleSubcategories}
                  activeCategory={activeCategory}
                  setIsActiveSubcategory={setIsActiveSubcategory}
                  actualSubcategoriesCount={actualSubcategoriesCount}
                  categoryCount={categoryCount}
                />
                <PriceFilter
                  maxPrice={maxPrice}
                  handleSlider={handleSlider}
                  handlePriceFilter={handlePriceFilter}
                  selectedSubCategories={selectedSubCategories}
                  selectedCategories={selectedCategories}
                  value={value}
                />

                <VendorFilter
                  isActive={isActive}
                  setIsActive={setIsActive}
                  vendors={vendors}
                  handleVendor={handleVendor}
                  selectedVendors={selectedVendors}
                />
                <BrandFilter
                  isActiveBrand={isActiveBrand}
                  setIsActiveBrand={setIsActiveBrand}
                  brands={brands}
                  brandFilter={brandFilter}
                  handleBrandFilter={handleBrandFilter}
                  handleBrand={handleBrand}
                  brandCount={brandCount}
                  selectedBrands={selectedBrands}
                  loadMoreBrands={loadMoreBrands}
                />
                <OtherFilter
                  exclude_accessory={exclude_accessory}
                  handleExcludeAccessory={handleExcludeAccessory}
                  only_discounted={only_discounted}
                  handleOnlyDiscounted={handleOnlyDiscounted}
                  available_only={available_only}
                  handleOnlyAvailable={handleOnlyAvailable}
                  excludedCount={excludedCount}
                  availableOnlyCount={availableOnlyCount}
                  discountedCount={discountedCount}
                  priceDroppedOnlyCount={priceDroppedOnlyCount}
                  handleOnlyPriceDropped={handleOnlyPriceDropped}
                  only_pricedroped={only_pricedroped}
                />
              </section>
            </div>

            <div
              className={
                isShowFilter ? "col-sm-9 cat-right" : "col-sm-12 cat-right"
              }
            >
              <Row>
                <div className="col-sm-12 mb-4  search-title-wrapper cat-for-mobile">
                  <Row>
                    <div className="col-sm-4 d-flex justify-content-between ">
                      <button
                        className="btn-simple btn-show-filter"
                        onClick={handleShowFilter}
                      >
                        Filter
                      </button>
                      <Modal
                        show={isShowMobFilter}
                        onHide={handleCloseFilter}
                        className="mob-filter-popup"
                      >
                        <Modal.Header closeButton>
                          <Modal.Title>Filter </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <CategoryFilter
                            setActiveCategory={setActiveCategory}
                            isActiveCategory={isActiveCategory}
                            isActiveSubCategory={isActiveSubCategory}
                            setIsActiveCategory={setIsActiveCategory}
                            actualCategories={actualCategories}
                            actualSubcategories={actualSubcategories}
                            handleCategories={handleCategories}
                            selectedCategories={selectedCategories}
                            selectedSubCategories={selectedSubCategories}
                            setActiveIndex={setActiveIndex}
                            handleSubcategories={handleSubcategories}
                            activeCategory={activeCategory}
                            setIsActiveSubcategory={setIsActiveSubcategory}
                            actualSubcategoriesCount={actualSubcategoriesCount}
                            categoryCount={categoryCount}
                          />
                          <PriceFilter
                            maxPrice={maxPrice}
                            handleSlider={handleSlider}
                            handlePriceFilter={handlePriceFilter}
                            selectedSubCategories={selectedSubCategories}
                            selectedCategories={selectedCategories}
                            value={value}
                          />
                          <VendorFilter
                            isActive={isActive}
                            setIsActive={setIsActive}
                            vendors={vendors}
                            handleVendor={handleVendor}
                            selectedVendors={selectedVendors}
                          />
                          <BrandFilter
                            isActiveBrand={isActiveBrand}
                            setIsActiveBrand={setIsActiveBrand}
                            brands={brands}
                            brandFilter={brandFilter}
                            handleBrandFilter={handleBrandFilter}
                            handleBrand={handleBrand}
                            brandCount={brandCount}
                            selectedBrands={selectedBrands}
                            loadMoreBrands={loadMoreBrands}
                          />
                          <OtherFilter
                            exclude_accessory={exclude_accessory}
                            handleExcludeAccessory={handleExcludeAccessory}
                            only_discounted={only_discounted}
                            handleOnlyDiscounted={handleOnlyDiscounted}
                            available_only={available_only}
                            handleOnlyAvailable={handleOnlyAvailable}
                            excludedCount={excludedCount}
                            availableOnlyCount={availableOnlyCount}
                            discountedCount={discountedCount}
                            priceDroppedOnlyCount={priceDroppedOnlyCount}
                            handleOnlyPriceDropped={handleOnlyPriceDropped}
                            only_pricedroped={only_pricedroped}
                          />
                        </Modal.Body>
                        <Modal.Footer>
                          <Button
                            variant="secondary"
                            onClick={handleApplyFilter}
                          >
                            Apply
                          </Button>
                          <Button
                            variant="secondary"
                            onClick={handleCloseFilter}
                          >
                            Close
                          </Button>
                        </Modal.Footer>
                      </Modal>
                      {/* <button
                        className="btn btn-custom"
                        type="button"
                        onClick={handleReset}
                      >
                        Reset1
                      </button> */}

                      <SortFilter
                        title={title}
                        handleSort={handleSort}
                        orderBy={OrderBy}
                        setTitle={setTitle}
                      />
                    </div>
                  </Row>{" "}
                </div>
              </Row>
              <Row>
                <Badges
                  selectedCategories={selectedCategories}
                  selectedSubCategories={selectedSubCategories}
                  handleRemoveCategory={handleRemoveCategory}
                  handleRemoveSubCategory={handleRemoveSubCategory}
                />
              </Row>
              {productList && productList.length > 0 ? (
                <ProductList
                  productList={productList}
                  handleAddToFavourites={handleAddToFavourites}
                  handleRemoveFavourites={handleRemoveFavourites}
                  page={page}
                  limit={limit}
                  totalCount={totalCount}
                  setLimit={setLimit}
                  handlePageClick={handlePageClick}
                  handleProductDetail={handleProductDetail}
                />
              ) : isDataEmpty ? (
                <p className="loading-msg">
                  Hang tight! Genie is searching high and low to find the best
                  results for you. Sit back, relax, and let us do the work. Your
                  wait will be worth it!
                </p>
              ) : (
                <p className="loading-msg">Loading...</p>
              )}
            </div>
          </Row>
        </section>
      </Container>
      <WishlistModal
        isShowPopup={isShowPopup}
        handleClose={handleClose}
        history={history}
      />
    </main>
  );
};
export default SearchDetails;
