import React, { useEffect, useState, useRef } from "react";

import {
  Button,
  Col,
  Container,
  Form,
  Row,
  Tooltip,
  Overlay,
} from "react-bootstrap";

import * as images from "../constant/Assets";
import * as routes from "../constant/Routes";
import Leaflets from "./Leaflets";
import PopularProducts from "./PopularProducts";
import Categories from "./Categories";
import Banners from "./Banners";
import { getCookie, generateSession, getIpAddress } from "../../lib/helpers";
import { Helmet } from "react-helmet";

const Home = ({ history }) => {
  const [searchValue, setSearchValue] = useState("");
  const country_name = getCookie("country_name")
    ? getCookie("country_name")
    : "kuwait";

  const handleChange = (e) => {
    if (e.target.value) {
      setSearchValue(e.target.value);
    } else {
      setSearchValue("");
    }
  };

  const handleSearch = () => {
    history.push({
      pathname: `${country_name}${routes.BASE_SEARCH_ROUTE}`,
      search: `?query=${searchValue}`,
    });
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && searchValue.trim().length > 0) {
      history.push({
        pathname: `${country_name}${routes.BASE_SEARCH_ROUTE}`,
        search: `?query=${searchValue}`,
      });
    }
  };

  //  for tooltip =/daksha
  const [show3, setShow3] = useState(false);
  const target3 = useRef(null);

  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://geniesaves.com/" />
      </Helmet>

      <div className="Home mt-nav">
        <Container className="mt-sm-511 mt-011  ">
          <section id="home__search" className="mt-5 mb-5">
            <Row>
              <Col sm={12} className="d-flex justify-content-center">
                <h2 className="section-title mt-4 mb-4">
                  Search best offer products
                </h2>
              </Col>
            </Row>
            <Row>
              <Col sm={2}></Col>
              <Col sm={8}>
                <div className="d-flex align-items-center">
                  <Form className="d-flex">
                    <Form.Control
                      type="Find the best offer products"
                      placeholder="Find the best offer products"
                      className=""
                      aria-label="Find the best offer products"
                      value={searchValue}
                      onChange={(e) => handleChange(e)}
                      onKeyDown={(e) => handleKeyDown(e)}
                    />
                    <Button
                      onClick={handleSearch}
                      disabled={
                        searchValue && "" !== searchValue ? false : true
                      }
                      aria-label="search button"
                    >
                      <img
                        src={images.searchImage}
                        alt="search"
                        loading="lazy"
                      />
                    </Button>
                  </Form>
                  <i
                    className="fa fa-info-circle"
                    aria-hidden="true"
                    ref={target3}
                    onClick={() => setShow3(!show3)}
                  ></i>
                  <Overlay
                    target={target3.current}
                    show={show3}
                    placement="bottom"
                  >
                    {(props) => (
                      <Tooltip id="overlay-for-desktop" {...props}>
                        <p align="left">
                          Search Bar: Type your searching key words, similar to
                          Google Search. You also can exclude by typing with a
                          prefix ‘-‘<br></br>
                          <br></br>
                          Examples:<br></br>
                          Laptop i5 512 GB<br></br>
                          TV 60 Inch<br></br>
                          iPhone 15 -screen -cover
                        </p>
                      </Tooltip>
                    )}
                  </Overlay>
                </div>
              </Col>
              <Col sm={2}></Col>
            </Row>
          </section>
          <section id="home__banner">
            <div className="container-fluid">
              <Banners />
            </div>
          </section>
        </Container>

        <Container className="mt-sm-5 mt-0">
          <Categories history={history} />
          <PopularProducts history={history} />
          <Leaflets history={history} />
        </Container>
      </div>
    </>
  );
};

export default Home;
