import { useState, useEffect } from "react";
import api from "../../redux/services/api";
import * as images from "../constant/Assets";
import * as routes from "../constant/Routes";
import { Container, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { deleteCookie, getCookie } from "../../lib/helpers";
import { CONTACT } from "../../redux/reduxConstants/EndPoints";
import { Helmet } from "react-helmet";

const Contact = ({ history }) => {
  const baseUrl = process.env.REACT_APP_API_BASEURL;
  const [formData, setFromData] = useState({
    username: "",
    email: "",
    mobileNumber: "",
    phoneNumber: "",
    message: "",
  });
  const [errors, setErrors] = useState({});
  const [success, setSuccess] = useState();
  const [isValidForm, setIsValidForm] = useState(false);

  useEffect(() => {
    document.title = "Contact | Genie Saves";
  }, []);

  const validateFields = (name) => {
    if (name == "username" && formData.username == "") {
      setErrors({ ...errors, [name]: "Name is required" });
    }
    if (name == "message" && formData.message == "") {
      setErrors({ ...errors, [name]: "Message is required" });
    }
  };

  const validateForm = () => {
    if (formData.username !== "" && formData.message !== "") {
      return false;
    } else {
      return true;
    }
  };

  const handleChange = (name) => (event) => {
    event.preventDefault();
    const { value } = event.target;

    setFromData({ ...formData, [name]: value });
    // validateFields(name);
    const isError = validateForm();
    if (!isError) {
      setIsValidForm(true);
    } else {
      setIsValidForm(false);
    }

    setErrors({ ...errors, [name]: null });
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const isError = validateForm();
      if (!isError) {
        setErrors({});
        const session_id = getCookie("unique_session_id")
          ? getCookie("unique_session_id")
          : null;
        api(baseUrl)
          .post(CONTACT, {
            ...formData,
            session_id,
            Country_ID: getCookie("country_id") ? getCookie("country_id") : 1,
          })
          .then((res) => {
            if (res.data.success) {
              setSuccess("Message sent seccussfully");
              setTimeout(() => {
                history.push({ pathname: routes.HOME_ROUTE });
              }, 2000);
            }
          })
          .catch((e) => console.log(e));
      }
    } catch (error) {
      setErrors(error);
    }
  };

  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://geniesaves.com/contact-us/" />
      </Helmet>
      <Container className="mt-nav">
        <Row>
          <Col>
            <h2 className="section-title m-5 text-center">Contact us</h2>
          </Col>
        </Row>
        <Row>
          <div className="col-sm-6 contact-img">
            <img
              src={images.contact}
              alt="contact image"
              className="img-fluid"
            />
          </div>
          {success && <div className="success">{success}</div>}
          <div className="col-sm-6 mb-5">
            <Form onSubmit={(e) => handleSubmit(e)}>
              <Form.Group className="mb-3" controlId="formName">
                <Form.Label>
                  Full Name <span className="required">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Your Full Name"
                  value={formData.username}
                  onChange={handleChange("username")}
                  onBlur={() => validateFields("username")}
                />
                {errors && errors.username && (
                  <p className="error">{errors.username}</p>
                )}
              </Form.Group>

              <Form.Group className="mb-3" controlId="formEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter Your Email Id"
                  value={formData.email}
                  onChange={handleChange("email")}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formMobile">
                <Form.Label>Mobile number</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Your Mobile number"
                  value={formData.mobileNumber}
                  onChange={handleChange("mobileNumber")}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formPhone">
                <Form.Label>Phone number</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Your Phone number"
                  value={formData.phoneNumber}
                  onChange={handleChange("phoneNumber")}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formMessage">
                <Form.Label>
                  Message <span className="required">*</span>
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={formData.message}
                  placeholder="Enter Message Here"
                  onChange={handleChange("message")}
                  onBlur={() => validateFields("message")}
                />
                {errors && errors.message && (
                  <p className="error">{errors.message}</p>
                )}
              </Form.Group>

              <Button
                className="btn btn-submit"
                aria-label="submit"
                type="submit"
                disabled={!isValidForm}
              >
                Submit
              </Button>
            </Form>
          </div>
        </Row>
      </Container>
    </>
  );
};
export default Contact;
