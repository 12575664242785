import React from "react";

import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailShareButton,
  LinkedinShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  EmailIcon,
  LinkedinIcon,
} from "react-share";

const ShareIcon = ({ shareUrl, title, handleShareOnClick }) => {
  const onClickCopyHandler = () => {
    const elem = document.createElement("textarea");

    elem.value = shareUrl;

    document.body.appendChild(elem);
    elem.select();
    document.execCommand("copy");
    document.body.removeChild(elem);
  };

  const onClickShareIcon = (value) => {
    handleShareOnClick();
  };

  return (
    <ul className="socialIcons">
      <li onClick={() => onClickShareIcon("twitter")}>
        <FacebookShareButton url={shareUrl}>
          <FacebookIcon size={32} round={true} />
        </FacebookShareButton>
      </li>{" "}
      <li onClick={() => onClickShareIcon("twitter")}>
        <TwitterShareButton url={shareUrl}>
          <TwitterIcon size={32} round={true} />
        </TwitterShareButton>
      </li>{" "}
      <li onClick={() => onClickShareIcon("twitter")}>
        <WhatsappShareButton url={shareUrl}>
          <WhatsappIcon size={32} round={true} />
        </WhatsappShareButton>
      </li>{" "}
      <li onClick={() => onClickShareIcon("twitter")}>
        {/* <EmailShareButton url={shareUrl}>
          <EmailIcon size={32} round={true} />
        </EmailShareButton> */}
        <a
          className="video-email_button button-hover"
          href={`mailto:?subject=GeinieSaves&body=Check out this link ${shareUrl}`}
          title="Share viaEmail"
        >
          <EmailIcon size={32} round={true} />
        </a>
      </li>{" "}
      {/* <li onClick={() => onClickShareIcon("twitter")}>
        <LinkedinShareButton
          url={shareUrl}
          title={""}
          summary={""}
          source={shareUrl}
        >
          <LinkedinIcon size={32} round={true} />
        </LinkedinShareButton>
      </li> */}{" "}
      <li onClick={onClickCopyHandler} style={{ cursor: "pointer" }}>
        <div className="copy-url-community">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="16.211"
            viewBox="0 0 14 16.211"
          >
            <g id="file_copy" transform="translate(-2 -1)">
              <path
                id="Path_30547"
                data-name="Path 30547"
                d="M12.316,1H3.474A1.478,1.478,0,0,0,2,2.474V12.789H3.474V2.474h8.842Zm-.737,2.947L16,8.368v7.368a1.478,1.478,0,0,1-1.474,1.474H6.414a1.472,1.472,0,0,1-1.466-1.474L4.955,5.421A1.472,1.472,0,0,1,6.421,3.947Zm-.737,5.158h4.053L10.842,5.053Z"
                fill="#a1c057"
              />
            </g>
          </svg>
        </div>
      </li>
    </ul>
  );
};

export default ShareIcon;
