import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import * as images from "../constant/Assets";
import * as routes from "../constant/Routes";
import { Helmet } from "react-helmet";

function About() {
  useEffect(() => {
    document.title = "About | Genie Saves";
  }, []);

  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://geniesaves.com/about-us/" />
      </Helmet>
      <Container className="mt-nav">
        <Row>
          <Col>
            <h2 className="section-title m-5 text-center">About Us</h2>
          </Col>
        </Row>
        <Row>
          <div className="about-content mb-5 col">
            <Row>
              <div className="col-sm-4">
                <img
                  src="/dist/assets/images/about4.png"
                  alt="about image"
                  className="img-fluid about-img"
                />
              </div>
              <div className="col-sm-8">
                <p>
                  <strong>Welcome to Genie Saves! </strong>
                </p>
                <p>
                  At Genie Saves, we are your trusted companion in the bustling
                  markets of Kuwait, making your purchasing decisions faster,
                  smarter, and more efficient. In a landscape here the majority
                  of products grace our shores from across the globe, we
                  understand the value of making every dinar count. Our digital
                  lamp is designed to light up the path to smarter shopping,
                  directly from your screen to your doorstep, highlighting the
                  best <strong>promotions</strong> and{" "}
                  <strong>discounts</strong>.
                </p>
                <br></br>

                <h5>Who We Are? </h5>
                <p>
                  Imagine a genie at your service, not just granting wishes but
                  offering you the power to make informed choices. That's us!
                  Genie Saves is your go-to platform for navigating Kuwait's
                  vast marketplace. We've carefully curated a database featuring
                  the crème de la crème of local vendors and their diverse{" "}
                  <strong>Kuwait offers</strong>. From the latest gadgets to
                  essential household items, we make it simple to search for
                  products, view vibrant{" "}
                  <strong>banner offers in Kuwait</strong>, and explore
                  informative leaflets—all in one place.
                </p>
                <p>
                  With Genie Saves, the magic doesn't stop at discovery. Our
                  treasure trove of price data spanning several months offers
                  invaluable insights. Like a wise sage, we analyze trends to
                  recommend the optimal times for purchase, ensuring you get
                  quality without compromise.
                </p>
              </div>
            </Row>

            <br></br>

            <h5>Our Pre-Launch Promise </h5>

            <p>
              As we unveil the magic carpet, please note that our initial
              journey embarks with a select array of product categories and
              vendors. This is where we need you, our esteemed explorers, to
              guide us further. Your feedback is the lamp's light, guiding us on
              which categories and vendors to welcome into our enchanting
              bazaar. With every suggestion, you help us tailor a marketplace
              that truly reflects your needs and desires.
            </p>
            <br></br>
            <h5>Why Choose Genie Saves? </h5>
            <p>With Genie Saves, every transaction is a wish granted: </p>
            <ul>
              <li>
                <strong>Save Money:</strong> Uncover the best{" "}
                <strong>online deals in Kuwait</strong> and leverage our price
                analytics to make purchases at just the right time.
              </li>
              <li>
                <strong>Save Time:</strong> Forget the hassle of visiting
                multiple websites or stores. Our platform brings the best local{" "}
                <strong>offers in Kuwait</strong> right to your fingertips.
              </li>
              <li>
                <strong>No Regrets:</strong> With our insights and comprehensive
                product range, make purchases knowing you've made the best
                choice available.
              </li>
            </ul>
            <br></br>
            <h5>Join Us on This Magical Journey </h5>

            <p>
              Genie Saves is more than a platform; it's a community. As we
              prepare for our grand unveiling in Kuwait, we invite you to be
              part of our initial adventure. Your feedback and desires will
              shape the future of Genie Saves, ensuring that we continue to
              offer a marketplace as dynamic and vibrant as the people we serve.
            </p>
            <p>Together, let's make every purchase a wish come true. </p>
          </div>
        </Row>
      </Container>
    </>
  );
}
export default About;
