import React from "react";
import { Form } from "react-bootstrap";

const arrowIconSize = {
  width: "15px",
  height: "15px",
};
const BrandFilter = ({
  isActiveBrand,
  setIsActiveBrand,
  brands,
  brandFilter,
  handleBrandFilter,
  handleBrand,
  brandCount,
  selectedBrands,
  loadMoreBrands,
}) => {
  return (
    <div className="mt-4 vendors-filter">
      <div className="accordion">
        <div className="accordion-item">
          <div className="accordion-title">
            <h6>Brands</h6>
            <div
              onClick={() => setIsActiveBrand(!isActiveBrand)}
              style={{ cursor: "pointer" }}
            >
              {isActiveBrand ? (
                <img
                  src="/dist/assets/images/arrow-down.svg"
                  alt="arrow-down"
                  style={arrowIconSize}
                />
              ) : (
                <img
                  src="/dist/assets/images/arrow-up.svg"
                  alt="arrow-up"
                  style={arrowIconSize}
                />
              )}
            </div>
          </div>
          <div className="accordion-content">
            {isActiveBrand ? (
              <>
                {
                  <div className="some-input-field-class">
                    <input
                      type="text"
                      name="filter"
                      id="filter"
                      placeholder="Search Brands"
                      value={brandFilter}
                      onChange={handleBrandFilter}
                    />
                  </div>
                }
                {brands.slice(0, brandCount).map((item, index) => {
                  return (
                    <div className="acc-label-wrap">
                      <Form.Check
                        type="checkbox"
                        id={index}
                        label={item.Name}
                        value={item.Name}
                        onChange={handleBrand}
                        checked={selectedBrands.includes(item.Name)}
                      />
                      <div className="cat-count">
                        (
                        <span>
                          {item.Num_of_Items &&
                            item.Num_of_Items.toString().replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            )}
                        </span>
                        )
                      </div>
                    </div>
                  );
                })}
                {brands && brands.length > 10 && brandCount < brands.length ? (
                  <span
                    className="catge-view"
                    onClick={() => loadMoreBrands(100, "more")}
                  >
                    View More{" "}
                    <i className="fa fa-angle-down" aria-hidden="true"></i>
                  </span>
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default BrandFilter;
