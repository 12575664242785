import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row, Modal } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
import api from "../../redux/services/api";
import Overlay from "react-bootstrap/Overlay";
import {
  PRODUCT,
  ADD_FAVOURITES,
  REMOVE_FAVOURITES,
  TRIGGER,
} from "../../redux/reduxConstants/EndPoints";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import * as images from "../constant/Assets";
import * as routes from "../constant/Routes";
import { deleteCookie, getCookie } from "../../lib/helpers";
import WishlistModal from "../modal/WishlistModal";
import FavIcon from "../icon/FavIcon";
import moment from "moment";
import ShareIcon from "../../uikit/ShareIcon";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import CommunityLoaderCircularDash from "../../uikit/CommunityLoaderCircularDash";

import {
  TRIGGER_ITEM,
  TRIGGER_POPULAR_ITEMS,
  TRIGGER_TOP_DEAL,
} from "../constant/Triggers";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const ProductDetail = ({ history }) => {
  const [product, setProduct] = useState([]);
  const [chartData, setChartData] = useState({});
  const query = new URLSearchParams(history.location.search);

  const [isShowPopup, setIsShowPopup] = useState(false);
  const [showToolTipShare, setshowToolTipShare] = useState(false);
  const handleClose = () => setIsShowPopup(false);
  const handleShow = () => setIsShowPopup(true);

  const [loading, setLoading] = useState(false);

  const HandleToolclickShare = () => {
    setshowToolTipShare(!showToolTipShare);
  };

  const token = getCookie("token");
  const isFromHome = sessionStorage.getItem("isFromHome");

  const currentUrl = window.location.href;
  const splitArray = currentUrl.split("/");
  console.log(splitArray);
  const Vendor =
    history &&
    history.location &&
    history.location.state &&
    history.location.state.Vendor
      ? history.location.state.Vendor
      : splitArray && splitArray.length > 4
      ? splitArray["4"]
      : "";

  const ItemKey =
    history &&
    history.location &&
    history.location.state &&
    history.location.state.ItemKey
      ? history.location.state.ItemKey
      : splitArray && splitArray.length > 4
      ? splitArray["7"]
      : "";

  const mykey = "REACT_APP_HOST_COUNTRY_" + splitArray[3].toUpperCase();
  const Country_ID = getCookie("country_id")
    ? getCookie("country_id")
    : splitArray && splitArray.length > 4
    ? `${process.env[mykey]}`
    : 1;

  useEffect(() => {
    setTimeout(() => {
      Vendor && ItemKey && fetchProduct();
    }, 1000);
  }, [Vendor, ItemKey]);

  const baseUrl = process.env.REACT_APP_API_BASEURL;
  const base_url = process.env.REACT_APP_HOSTNAME;

  const fetchProduct = () => {
    setLoading(true);
    const session_id = getCookie("unique_session_id")
      ? getCookie("unique_session_id")
      : null;
    const country_id = Country_ID
      ? Country_ID
      : getCookie("country_id")
      ? getCookie("country_id")
      : 1;
    api(baseUrl)
      .get(
        PRODUCT +
          "/" +
          Vendor +
          "/" +
          ItemKey +
          "?session_id=" +
          session_id +
          "&country_id=" +
          country_id
      )
      .then((res) => {
        if (res.data.success) {
          setProduct(
            res.data.data && res.data.data.length > 0 ? res.data.data[0] : {}
          );

          const graphData = res && res.data && res.data.graphdata;

          if (
            !graphData ||
            !Array.isArray(graphData) ||
            graphData.length === 0
          ) {
            console.error("Invalid or empty graph data received.");
          } else {
            // Extracting dates and selling prices from the API response
            const dates = graphData.map((item) => item.Date);
            const sellingPrices = graphData.map((item) =>
              parseFloat(item.Selling_Price)
            );

            const highestPrice = Math.max(...sellingPrices);
            const lowestPrice = Math.min(...sellingPrices);

            const chartDataTemp = {
              labels: dates,
              datasets: [
                {
                  label: "Selling Price",
                  data: sellingPrices,
                  fill: false,
                  lineTension: 0.1,
                  backgroundColor: "rgba(75,192,192,0.4)",
                  borderColor: "rgba(75,192,192,1)",
                  borderCapStyle: "butt",
                  borderDash: [],
                  borderDashOffset: 0.0,
                  borderJoinStyle: "miter",
                  pointBorderColor: "rgba(75,192,192,1)",
                  pointBackgroundColor: "#fff",
                  pointBorderWidth: 1,
                  pointHoverRadius: 5,
                  pointHoverBackgroundColor: "rgba(75,192,192,1)",
                  pointHoverBorderColor: "rgba(220,220,220,1)",
                  pointHoverBorderWidth: 2,
                  pointRadius: 1,
                  pointHitRadius: 10,
                  cubicInterpolationMode: "bezier", // Set cubicInterpolationMode to 'monotone' for smooth curves
                },
                // Add a dataset for the highest price line
                {
                  label: "Highest Price",
                  data: Array(dates.length).fill(highestPrice),
                  fill: false,
                  lineTension: 0.1,
                  borderColor: "rgba(255,0,0,1)", // You can customize the color
                  backgroundColor: "rgba(75,192,192,0.4)",
                  borderCapStyle: "butt",
                  borderDash: [],
                  borderDashOffset: 0.0,
                  borderJoinStyle: "miter",
                  pointBorderColor: "rgba(75,192,192,1)",
                  pointBackgroundColor: "#fff",
                  pointBorderWidth: 1,
                  pointHoverRadius: 5,
                  pointHoverBackgroundColor: "rgba(75,192,192,1)",
                  pointHoverBorderColor: "rgba(220,220,220,1)",
                  pointHoverBorderWidth: 2,
                  pointRadius: 1,
                  pointHitRadius: 10,
                  cubicInterpolationMode: "bezier", // Set cubicInterpolationMode to 'monotone' for smooth curves
                },
                // Add a dataset for the lowest price line
                {
                  label: "Lowest Price",
                  data: Array(dates.length).fill(lowestPrice),
                  fill: false,
                  lineTension: 0.1,
                  borderColor: "rgba(0,255,0,1)", // You can customize the color
                  backgroundColor: "rgba(75,192,192,0.4)",
                  borderCapStyle: "butt",
                  borderDash: [],
                  borderDashOffset: 0.0,
                  borderJoinStyle: "miter",
                  pointBorderColor: "rgba(75,192,192,1)",
                  pointBackgroundColor: "#fff",
                  pointBorderWidth: 1,
                  pointHoverRadius: 5,
                  pointHoverBackgroundColor: "rgba(75,192,192,1)",
                  pointHoverBorderColor: "rgba(220,220,220,1)",
                  pointHoverBorderWidth: 2,
                  pointRadius: 1,
                  pointHitRadius: 10,
                  cubicInterpolationMode: "bezier", // Set cubicInterpolationMode to 'monotone' for smooth curves
                },
              ],
            };

            setChartData(chartDataTemp);
          }
          setLoading(false);
        }
      })
      .catch((e) => console.log(e));
  };

  const optionsGraph = {
    // Set the height of the chart
    maintainAspectRatio: false,
    // scales: {
    //   y: {
    //     beginAtZero: true,
    //   },
    // },
  };

  const options = {
    margin: 15,
    items: 4,
    loop: true,
    responsiveClass: true,
    responsive: {
      margin: 15,
      0: {
        items: 2,
      },
      400: {
        items: 2,
      },
      600: {
        items: 3,
      },
      700: {
        items: 3,
      },
      1000: {
        items: 4,
      },
    },
  };

  const handleAddToFavourites = (item) => {
    if (!token) {
      setIsShowPopup(true);
    } else {
      const user_id = getCookie("user_id");
      const session_id = getCookie("unique_session_id")
        ? getCookie("unique_session_id")
        : null;
      const data = {
        Country_ID: Country_ID
          ? Country_ID
          : getCookie("country_id")
          ? getCookie("country_id")
          : 1,
        User_ID: user_id,
        Vendor: item.Vendor,
        Item_Key: item.Item_Key,
        Price: item.Selling_Price,
        Item_name: item.Item_name,
        Item_Image_URL: item.Item_Image_URL,
        Item_URL: item.Item_URL,
        session_id: session_id,
      };

      const headers = {
        Authorization: "Bearer " + token,
      };

      api(baseUrl, headers)
        .post(ADD_FAVOURITES, data)
        .then((res) => {
          if (res.data.success) {
            let prevproduct = { ...item };

            prevproduct.is_a_Favorite = "1";

            setProduct(prevproduct);
          } else if ("Unauthorized." == res.data) {
            deleteCookie("user_id");
            deleteCookie("email");
            deleteCookie("token");
            history.push({ pathname: routes.LOGIN });
          }
        })
        .catch((e) => console.log(e));
    }
  };

  const handleRemoveFavourites = (item) => {
    const COUNTRY_ID = Country_ID
      ? Country_ID
      : getCookie("country_id")
      ? getCookie("country_id")
      : 1;
    const user_id = getCookie("user_id");
    const headers = {
      Authorization: "Bearer " + token,
    };

    const session_id = getCookie("unique_session_id")
      ? getCookie("unique_session_id")
      : null;

    const data = {
      Country_ID: COUNTRY_ID,
      User_ID: user_id,
      Vendor: item.Vendor,
      Item_Key: item.Item_Key,
      session_id: session_id,
    };

    api(baseUrl, headers)
      .post(REMOVE_FAVOURITES, data)
      .then((res) => {
        if (res.data.success) {
          const prevProduct = { ...item };

          prevProduct.is_a_Favorite = "0";

          setProduct(prevProduct);
        }
      })
      .catch((e) => console.log(e));
  };

  const handleTrigger = (item) => {
    const user_id = getCookie("user_id");
    const session_id = getCookie("unique_session_id")
      ? getCookie("unique_session_id")
      : null;
    const data = {
      country_id: Country_ID
        ? Country_ID
        : getCookie("country_id")
        ? getCookie("country_id")
        : 1,
      user_id: user_id,
      vendor: item.Vendor,
      item_key: item.Item_Key ? item.Item_Key : "",
      session_id: session_id,
      subject: "false" === isFromHome ? TRIGGER_ITEM : TRIGGER_TOP_DEAL,
      external_link: item.Item_URL,
      vendor_id: item.Vendor_ID,
    };
    api(baseUrl)
      .post(TRIGGER, data)
      .then((res) => {
        sessionStorage.removeItem("isFromHome");
        if (res.data.success) {
        }
      })
      .catch((e) => console.log(e));
  };

  const handleClick = (item) => {
    handleTrigger(item);
    window.open(item.Item_URL, "_blank");
  };

  return (
    <main className="product-detail-page mt-nav-prodetail">
      {loading && <CommunityLoaderCircularDash isbackground={false} />}
      <section className="prod-slider text-center">
        <Container>
          <section className="prod-title">
            {/* <h4>{product.Item_name}</h4> */}
            <h2>Product Details</h2>
            <div className="breadcrumb">
              {product.Category ? (
                <>
                  <span>{product.Category}</span>
                  {" > "} <span>{product.Sub_Category}</span>
                </>
              ) : (
                ""
              )}
            </div>
          </section>

          <section className="product-details-content-wrapper">
            <Row>
              <div className="col-12 col-sm-4 p-image">
                <section className="wrap-icons">
                  <div onClick={HandleToolclickShare} className="shareicon">
                    <img src={images.sharePost} alt="Share Product" />
                    <div className="all-social-share">
                      {showToolTipShare && (
                        <ShareIcon
                          shareUrl={
                            base_url +
                            routes.PRODUCTDETAIL +
                            "?Vendor=" +
                            product.Vendor +
                            "&Item_Key=" +
                            product.Item_Key +
                            "&country_id=" +
                            Country_ID
                          }
                          title={"Product Detail"}
                          handleShareOnClick={() => {
                            HandleToolclickShare();
                          }}
                        />
                      )}
                    </div>
                  </div>

                  <FavIcon
                    product={product}
                    handleAddToFavourites={handleAddToFavourites}
                    handleRemoveFavourites={handleRemoveFavourites}
                  />
                </section>
                <div className="img-wrap">
                  {/* <div>
                    <i class="fa fa-share-alt" aria-hidden="true"></i>
                  </div> */}

                  <img
                    src={product.Item_Image_URL}
                    alt="prod image"
                    className="img-fluid"
                  />
                </div>
              </div>

              <div className="col-12 col-sm-8 p-content">
                <section className="prod-deatils">
                  <div className="product-name-price-wrapper">
                    <div className="product-name">{product.Item_name}</div>
                    <div className="product-price mb-3">
                      {product.Discount_Percent > 0 ? (
                        <div className="offer-tag">
                          <span>
                            {product.Discount_Percent > 0
                              ? Math.floor(product.Discount_Percent)
                              : 0}
                            % OFF
                          </span>
                        </div>
                      ) : (
                        ""
                      )}
                      <price>
                        KD {Number(product.Selling_Price).toFixed(3)}{" "}
                      </price>{" "}
                      {product.Discount_Percent > 0 ? (
                        <strike>
                          {" "}
                          {Number(product.Regular_Price).toFixed(3)}
                        </strike>
                      ) : (
                        ""
                      )}{" "}
                      {null !== product.Selling_Price_Std &&
                      product.Selling_Price_Std > 0 ? (
                        <small>
                          ({Number(product.Selling_Price_Std).toFixed(3)} /{" "}
                          {product.Calc_Unit2})
                        </small>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="price-incre-decre mb-4">
                      {product.Trend_7day &&
                        product.Trend_7day !== null &&
                        product.Trend_7day.toLowerCase() === "down" && (
                          <div className="p-decre">
                            <i
                              className="fa fa-arrow-circle-down"
                              aria-hidden="true"
                            ></i>
                            Price {product.Price_Change_Percent_7day}
                          </div>
                        )}
                      {product.Trend_7day &&
                        product.Trend_7day !== null &&
                        product.Trend_7day.toLowerCase() === "up" && (
                          <div className="p-incre">
                            <i
                              className="fa fa-arrow-circle-up"
                              aria-hidden="true"
                            ></i>
                            Price {product.Price_Change_Percent_7day}
                          </div>
                        )}
                    </div>
                  </div>
                  <div className="product-vendor-wrapper d-flex">
                    <div className="vendor-logo">
                      <img
                        src={
                          product.Vendor_logo
                            ? product.Vendor_logo
                            : `${process.env.REACT_APP_HOSTNAME}/dist/assets/images/default-logo-sm.png`
                        }
                      />
                    </div>

                    <div className="vendor-name">{product.Vendor}</div>
                  </div>

                  <div className="product-description-wrapper">
                    <div className="more-details">
                      {/* <b>Details: </b> */}
                      <div className="brand-name mb-3">
                        <b>Brand: </b>
                        {product.Brand}
                      </div>
                      {product.is_Item_Available && (
                        <div className="item-available mb-3">
                          <b>Available: </b> Yes
                        </div>
                      )}

                      {moment(product.Discount_Start_Date).year() > 1900 ? (
                        <span>
                          <b className="mb-3">
                            {moment(product.Discount_End_Date).year() > 1900
                              ? "Discount Period:"
                              : "Discount Started:"}{" "}
                          </b>
                          {moment(product.Discount_Start_Date).format(
                            "DD-MM-YYYY"
                          )}
                        </span>
                      ) : (
                        ""
                      )}
                      {moment(product.Discount_End_Date).year() > 1900 ? (
                        <span>
                          {" "}
                          to{" "}
                          {moment(product.Discount_End_Date).format(
                            "DD-MM-YYYY"
                          )}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </section>

                <section className="price-graph-details">
                  <Row>
                    <div className="current pricing col-sm-3 col-5">
                      <text>Current Price: </text>{" "}
                      {/* <span className="not-good">Not Good</span>
                      <span className="good">Good</span> */}
                      <p className="amount">
                        {Number(product.Current_Price).toFixed(3)} KD
                      </p>
                    </div>

                    <div className="col-sm-9 col-7">
                      <div className="wrap-button">
                        <a
                          className="btn btn-buynow"
                          // href={product.Item_URL}
                          // target="_blank"
                          onClick={() => handleClick(product)}
                        >
                          <i className="fa fa-cart-plus" aria-hidden="true"></i>{" "}
                          Buy Now
                        </a>
                        <div className="vendor-logo">
                          <img
                            src={
                              product.Vendor_logo
                                ? product.Vendor_logo
                                : `${process.env.REACT_APP_HOSTNAME}/dist/assets/images/default-logo-sm.png`
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </Row>
                  <Row>
                    <div className="col-sm-3 col-6 lowest pricing ">
                      <label>Lowest Price</label>{" "}
                      <value>
                        {Number(product.Price_Lowest).toFixed(3)} KD
                      </value>
                    </div>

                    <div className="col-sm-3 col-6 highest pricing ">
                      <label>Highest Price</label>{" "}
                      <value>
                        {Number(product.Price_Highest).toFixed(3)} KD
                      </value>
                    </div>

                    <div className="col-sm-3 col-6 recent pricing ">
                      {/* {product.Price_Average > 0 && ( */}
                      <>
                        <label>
                          Price <span>Average</span>
                        </label>{" "}
                        <value className="price-rise ">
                          {Number(product.Price_Average).toFixed(3)} KD
                        </value>
                      </>
                      {/* )} */}
                      {/* {product.Price_Change_Percent_7day > 0 && ( */}

                      {/* )} */}
                    </div>

                    <div className="col-sm-3 col-6 recent pricing ">
                      <label>
                        Recent Price <span>Change</span>
                      </label>{" "}
                      <value
                        className={
                          product.Trend_7day &&
                          product.Trend_7day.toLowerCase() === "up"
                            ? "price-drop color-red"
                            : product.Trend_7day &&
                              product.Trend_7day.toLowerCase() === "down"
                            ? "price-drop color-green"
                            : "price-drop color-grey"
                        }
                      >
                        {product.Price_Change_Percent_7day}
                      </value>
                    </div>
                  </Row>
                  {/* <Row>
                    <div className="col-12 alert-btn">
                      <button className="btn btn-alert">
                        <i class="fa fa-bell-o" aria-hidden="true"></i> Ask for
                        Better Price
                      </button>
                    </div>
                  </Row> */}
                  <Row>
                    <div
                      className="col-12 graph-range"
                      style={{ height: "300px" }}
                    >
                      {chartData &&
                      chartData.labels &&
                      chartData.labels.length > 0 ? (
                        <Line data={chartData} options={optionsGraph} />
                      ) : (
                        <p>Loading chart data...</p>
                      )}
                    </div>
                  </Row>
                </section>
              </div>
            </Row>
          </section>
        </Container>
      </section>

      <Container>
        {/* <section className="related-products">
          <h6>Related products</h6>

          <section className="mt-3 text-center item-design">
            <Row>
              <OwlCarousel
                className="owl-theme"
                loop
                margin={10}
                nav
                responsiveClass={true}
                responsive={{
                  1: {
                    items: 2,
                  },
                  1025: {
                    items: 4,
                  },
                }}
              >
                <div className="item">
                  <div className="main-item-wrap">
                    <div className="img-wrap">
                      <img src="dist/assets/images/image.png" alt="img" />
                    </div>
                    <div className="item-desc">
                      <div className="vendor-logo">
                        <img src="dist/assets/images/Xcite.jpg" alt="img" />
                      </div>
                      <h5>Brand name</h5>
                      <p>item name </p>
                    </div>
                    <div className="price">
                      <span>KD 10</span>
                      <small>
                        <strike>Old Price</strike>
                      </small>
                    </div>
                  </div>
                </div>

                <div className="item">
                  <div className="main-item-wrap">
                    <div className="img-wrap">
                      <img src="dist/assets/images/image.png" alt="img" />
                    </div>
                    <div className="item-desc">
                      <div className="vendor-logo">
                        <img src="dist/assets/images/Xcite.jpg" alt="img" />
                      </div>
                      <h5>Brand name</h5>
                      <p>item name </p>
                    </div>
                    <div className="price">
                      <span>KD 10</span>
                      <small>
                        <strike>Old Price</strike>
                      </small>
                    </div>
                  </div>
                </div>
              </OwlCarousel>
            </Row>
          </section>
        </section> */}
      </Container>
      <WishlistModal
        isShowPopup={isShowPopup}
        handleClose={handleClose}
        history={history}
      />
    </main>
  );
};
export default ProductDetail;
