import { v4 as uuid } from "uuid";
import {
  isBrowser,
  isMobile,
  browserName,
  osName,
  deviceDetect,
} from "react-device-detect";

import api from "../../redux/services/api";
import { Add_SESSION } from "../../redux/reduxConstants/EndPoints";
import axios from "axios";

/**
 * @author Sonali Raut
 * @description getCookie function.
 * This function is return value from cookie
 * Params required - name
 */

export function getCookie(name) {
  let cookieValue = null;
  if (typeof window !== "undefined") {
    if (document.cookie && document.cookie !== "") {
      const cookies = document.cookie.split(";");
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.substring(0, name.length + 1) === name + "=") {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }
  }
  return cookieValue;
}

/**
 * @author Sonali Raut
 * @description setCookie function.
 * This function is used to set cookie with expire time
 * Params required - key, value, expireDays
 */
export function setCookie(key, value, expireDays) {
  if (typeof window !== "undefined") {
    const exdate = new Date();
    exdate.setDate(exdate.getDate() + expireDays);
    const c_value =
      escape(value) +
      (expireDays == null
        ? "; path=/"
        : "; expires=" + exdate.toUTCString() + " ; path=/");

    document.cookie = key + "=" + c_value;
  }
}

/**
 * @author Sonali Raut
 * @description deleteCookie function.
 * This function is used to delete cookie
 */

export function deleteCookie(name) {
  document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;";
}

export const getIpAddress = async () => {
  try {
    const response = await axios
      // .get("https://api.ipify.org?format=json")
      .get("https://ipinfo.io?token=7099c8c05c46be")
      .then((res) => {
        return res;
      });

    return response;
    // Store or use IP address as required
  } catch (error) {
    console.error("Error fetching IP Address:", error);
  }
};

export const generateSession = (ip, location) => {
  const baseUrl = process.env.REACT_APP_API_BASEURL;
  const session_id = getCookie("session_id");
  const genie_id = getCookie("genie_id");
  const deviceDetails = deviceDetect();

  if (!session_id) {
    const unique_id = uuid();
    setCookie("session_id", unique_id);
  }

  if (!genie_id) {
    const unique_id = uuid();
    setCookie("genie_id", unique_id, 365);
  }

  const userAgent = window.navigator.userAgent;
  const platform = window.navigator.platform;
  const randomString =
    Math.random().toString(20).substring(2, 14) +
    Math.random().toString(20).substring(2, 14);

  const deviceID = `${userAgent}-${randomString}`;

  const data = {
    session_id: getCookie("session_id"),
    os: deviceDetails.osName,
    version: deviceDetails.osVersion,
    model: deviceDetails.model ? deviceDetails.model : "",
    make: deviceDetails.vendor ? deviceDetails.vendor : "",
    ip: ip,
    device_unique_id: getCookie("genie_id"),
    location,
    Country_ID: getCookie("country_id") ? getCookie("country_id") : 1,
  };
  api(baseUrl)
    .post(Add_SESSION, data)
    .then((res) => {
      if (res.data.success) {
        setCookie("unique_session_id", res.data.data);
      }
    })
    .catch((e) => console.log(e));
};
