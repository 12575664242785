import React, { useEffect, useState } from "react";

import api from "../../redux/services/api";
import {
  POPULAR_PRODUCTS,
  ADD_FAVOURITES,
  REMOVE_FAVOURITES,
} from "../../redux/reduxConstants/EndPoints";
import * as images from "../constant/Assets";
import * as routes from "../constant/Routes";
import { Col, Row } from "react-bootstrap";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import ProductItem from "../searchDetails/ProductItem";
import { deleteCookie, getCookie } from "../../lib/helpers";
import WishlistModal from "../modal/WishlistModal";

const PopularProducts = ({ history }) => {
  const token = getCookie("token");
  const baseUrl = process.env.REACT_APP_API_BASEURL;
  const country_id = getCookie("country_id") ? getCookie("country_id") : 1;
  const country_name = getCookie("country_name")
    ? getCookie("country_name")
    : "kuwait";
  const [popularProducts, setPopularProducts] = useState([]);
  const [carouselKey, setCarouselKey] = useState(0);
  const [isShowPopup, setIsShowPopup] = useState(false);
  const handleClose = () => setIsShowPopup(false);
  const handleShow = () => setIsShowPopup(true);

  useEffect(() => {
    setTimeout(() => {
      fetchPopularProducts();
    }, 2000);
  }, []);

  const fetchPopularProducts = () => {
    const session_id = getCookie("unique_session_id")
      ? getCookie("unique_session_id")
      : null;
    const user_id = getCookie("user_id") ? getCookie("user_id") : null;
    const country_id = getCookie("country_id") ? getCookie("country_id") : 1;
    api(baseUrl)
      .get(
        POPULAR_PRODUCTS +
          "?session_id=" +
          session_id +
          "&user_id=" +
          user_id +
          "&country_id=" +
          country_id
      )
      .then((res) => {
        if (res.data.success) {
          setPopularProducts(res.data.data);
        }
      })
      .catch((e) => console.log(e));
  };

  const handleRedirect = (item) => {
    window.open(
      routes.PRODUCTDETAIL +
        "?Vendor=" +
        item.Vendor +
        "&Item_Key=" +
        item.Item_Key,
      "_blank"
    );
  };

  const handleAddToFavourites = (item) => {
    if (!token) {
      setIsShowPopup(true);
    } else {
      const user_id = getCookie("user_id");
      const session_id = getCookie("unique_session_id")
        ? getCookie("unique_session_id")
        : null;
      const data = {
        Country_ID: getCookie("country_id") ? getCookie("country_id") : 1,
        User_ID: user_id,
        Vendor: item.Vendor,
        Item_Key: item.Item_Key,
        Price: item.Selling_Price,
        Item_name: item.Item_name,
        Item_Image_URL: item.Item_Image_URL,
        Item_URL: item.Item_URL,
        session_id: session_id,
      };

      const headers = {
        Authorization: "Bearer " + token,
      };

      api(baseUrl, headers)
        .post(ADD_FAVOURITES, data)
        .then((res) => {
          if (res.data.success) {
            let prevProductList = [...popularProducts];

            prevProductList = prevProductList.map((itemIn) => {
              if (
                itemIn.Vendor == item.Vendor &&
                itemIn.Item_Key == item.Item_Key
              ) {
                itemIn.is_a_Favorite = "1";
              }
              return itemIn;
            });
            setPopularProducts(prevProductList);
            setCarouselKey((prevKey) => prevKey + 1); // Toggle the key
          } else if ("Unauthorized." == res.data) {
            deleteCookie("user_id");
            deleteCookie("email");
            deleteCookie("token");
            history.push({ pathname: routes.LOGIN });
          }
        })
        .catch((e) => console.log(e));
    }
  };

  const handleRemoveFavourites = (item) => {
    const COUNTRY_ID = getCookie("country_id") ? getCookie("country_id") : 1;
    const user_id = getCookie("user_id");
    const headers = {
      Authorization: "Bearer " + token,
    };

    const session_id = getCookie("unique_session_id")
      ? getCookie("unique_session_id")
      : null;

    const data = {
      Country_ID: COUNTRY_ID,
      User_ID: user_id,
      Vendor: item.Vendor,
      Item_Key: item.Item_Key,
      session_id: session_id,
    };

    api(baseUrl, headers)
      .post(REMOVE_FAVOURITES, data)
      .then((res) => {
        if (res.data.success) {
          const prevProductList = [...popularProducts];

          prevProductList.map((itemIn) => {
            if (
              itemIn.Vendor == item.Vendor &&
              itemIn.Item_Key == item.Item_Key
            ) {
              itemIn.is_a_Favorite = "0";
            }
            return itemIn;
          });
          setPopularProducts(prevProductList);
        }
      })
      .catch((e) => console.log(e));
  };

  return (
    <>
      <section id="home__popular" className="mt-5 text-center item-design">
        <Row>
          <Col className="d-flex justify-content-between align-items-center1 title-wrap mt-sm-5 mt-0 mb-4">
            <h2 className="section-title mb-1">Top Deals </h2>

            <a
              href={
                country_name +
                routes.BASE_SEARCH_ROUTE +
                "?query=&page=1&page_size=20&order_by=3&country_id=" +
                country_id
              }
            >
              View All
            </a>
          </Col>
        </Row>
        {popularProducts && popularProducts.length > 0 ? (
          <Row>
            <OwlCarousel
              className="owl-theme"
              loop
              nav
              margin={20}
              responsiveClass={true}
              responsive={{
                1: {
                  items: 2,
                },
                1025: {
                  items: 4,
                },
              }}
              key={carouselKey}
            >
              {popularProducts.map((item) => {
                let vendorName = item.Vendor.replace(" ", "-").toLowerCase();
                return (
                  <ProductItem
                    item={item}
                    vendorName={vendorName}
                    handleAddToFavourites={handleAddToFavourites}
                    handleRemoveFavourites={handleRemoveFavourites}
                    isFromHome={true}
                  />
                );
              })}
            </OwlCarousel>
          </Row>
        ) : (
          ""
        )}
      </section>
      <WishlistModal
        isShowPopup={isShowPopup}
        handleClose={handleClose}
        history={history}
      />
    </>
  );
};

export default PopularProducts;
