import React, { useEffect, useState } from "react";
import ReactSlider from "react-slider";
import useDeviceDetection from "../../hooks/useDeviceDetection";

const PriceFilter = ({
  maxPrice,
  handleSlider,
  handlePriceFilter,
  selectedSubCategories,
  selectedCategories,
  value,
}) => {
  const device = useDeviceDetection();
  const [maxValue, setMaxValue] = useState(1);
  const [sliderValue, setSliderValue] = useState(
    value && value.length > 0 ? value : [0, 1]
  );

  useEffect(() => {
    setMaxValue(Math.ceil(maxPrice));
    setSliderValue(
      value && value.length > 0 ? value : [0, Math.ceil(maxPrice)]
    );
  }, [maxPrice, selectedCategories, selectedSubCategories]);

  return (
    <>
      {maxValue &&
      ((selectedCategories && selectedCategories.length > 0) ||
        (selectedSubCategories && selectedSubCategories.length > 0)) ? (
        <div className="price-range-slider mt-4">
          <h6>Price</h6>
          <div className="price-values">
            <lowestprice>
              <small>Min:</small>
              {sliderValue["0"]} KD
            </lowestprice>
            <highestprice>
              <small>Max:</small>
              {sliderValue["1"]} KD
            </highestprice>
          </div>
          {maxValue && (
            <ReactSlider
              className="horizontal-slider"
              thumbClassName="thumb"
              trackClassName="track"
              value={sliderValue}
              ariaLabel={["Lower thumb", "Upper thumb"]}
              ariaValuetext={(state) => `Thumb value ${state.valueNow}`}
              renderThumb={(props, state) => (
                <div {...props}>{state.valueNow}</div>
              )}
              minDistance={maxValue > 1 ? 1 : 0.01}
              max={maxValue}
              step={maxValue > 1 ? 1 : 0.01}
              onAfterChange={(value) => {
                setSliderValue(value);
                handleSlider(value);
              }}
            />
          )}

          {device !== "Mobile" && (
            <>
              <br />
              <button type="button" onClick={handlePriceFilter}>
                Go
              </button>
            </>
          )}
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default PriceFilter;
