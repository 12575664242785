import { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import * as images from "../constant/Assets";
import * as routes from "../constant/Routes";
import { Helmet } from "react-helmet";

function PrivacyPolicy() {
  useEffect(() => {
    document.title = "Privacy Policy | Genie Saves";
  }, []);

  return (
    <>
    <Helmet>
        <link rel="canonical" href="https://geniesaves.com/privacy-policy/" />
    </Helmet>
      <Container className="mt-nav">
        <Row>
          <Col>
            <h2 className="section-title m-5 text-center">
              Privacy Policy for Genie Saves{" "}
            </h2>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="about-content mb-5">
              <p>
                Welcome to Genie Saves, accessible from
                https://geniesaves.com/aboutus ("the Website"). Your privacy is
                critically important to us, and we are committed to protecting
                your personal data. This privacy policy outlines the types of
                information we collect, how we use it, and the measures we take
                to ensure its protection.
              </p>
              <br></br>
              <p>
                {" "}
                1. <strong>Information We Collect</strong>{" "}
              </p>
              <p>
                <ul>
                  <li>
                    <strong>Personal Data:</strong> When you visit our Website,
                    we may ask you to provide us with certain personally
                    identifiable information that can be used to contact or
                    identify you. This may include, but is not limited to, your
                    name, email address, and telephone number.
                  </li>
                  <li>
                    <strong>Usage Data:</strong> We may also collect information
                    on how the Website is accessed and used. This Usage Data may
                    include details such as your computer's Internet Protocol
                    address (IP address), browser type, browser version, the
                    pages you visit on our Website, the time and date of your
                    visit, the time spent on those pages, and other diagnostic
                    data.
                  </li>
                </ul>
              </p>
              <br></br>
              <p>
                {" "}
                2. <strong>Use of Data</strong> We use the collected data for
                various purposes:
              </p>
              <p>
                <ul>
                  <li>To provide and maintain our Website</li>
                  <li>To notify you about changes to our Website</li>
                  <li>
                    To allow you to participate in interactive features of our
                    Website
                  </li>
                  <li>To monitor the usage of our Website </li>
                </ul>
              </p>
              <br></br>
              <p>
                3. <strong>Data Sharing</strong> Genie Saves values your
                privacy. We will not share your data with any third parties
                except as required by law or if we believe that such action is
                necessary to comply with a legal obligation, protect and defend
                our rights or property, prevent fraud, protect the person safety
                of users of the Website, or protect against legal liability.
              </p>
              <br></br>
              <p>
                4. <strong>Data Security</strong> The security of your data is
                important to us. We have implemented appropriate technical and
                organizational measures to prevent your personal data from being
                accidentally lost, used, or accessed in an unauthorized way,
                altered, or disclosed.
              </p>
              <br></br>
              <p>
                5. <strong>Data Retention</strong> We will only retain your
                personal data for as long as necessary to fulfill the purposes
                we collected it for, including for the purposes of satisfying
                any legal, accounting, or reporting requirements.
              </p>
              <br></br>

              <p>
                {" "}
                7. <strong>Children's Privacy</strong> Our Website does not
                target anyone under the age of 18. We do not knowingly collect
                personally identifiable information from children under 18. If
                we become aware that we have collected Personal Data from
                children without verification of parental consent, we take steps
                to remove that information from our servers.{" "}
              </p>
              <br></br>

              <p>
                {" "}
                8. <strong>Changes to This Privacy Policy</strong> We may update
                our Privacy Policy from time to time. We will inform you of any
                changes by posting the new Privacy Policy on this page and
                updating the effective date.{" "}
              </p>
              <br></br>

              <p>
                {" "}
                9. <strong>Contact Us</strong> If you have any questions about
                this Privacy Policy, please contact us by email at
                <strong>
                  {" "}
                  <a href="mailto:support@geniesaves.com">
                    support@geniesaves.com
                  </a>
                </strong>
              </p>
              <br></br>

              <p>
                By using our Website, you agree to the collection and use of
                information in accordance with this policy. This Privacy Policy
                was created for Genie Saves by GenieSaves.Com
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
export default PrivacyPolicy;
