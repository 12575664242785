export const LEAFLETS = "/leaflets";
export const BANNERS = "/banners";
export const SEARCH = "/productList";
export const POPULAR_PRODUCTS = "/popularItems";
export const CATEGORIES = "/categories";
export const SUBCATEGORIES = "/subCategories";
export const LOGIN = "/login";
export const REGISTER = "/create";
export const GOVERNATES = "/governate";
export const CITIES = "/cities";
export const LOGOUT = "/logout";
export const COUNTRIES = "/counrties";
export const FAVOURITES = "/getFavourites";
export const REMOVE_FAVOURITES = "/removeFavourites";
export const DELETE_ACCOUNT = "/deleteAccount";
export const UPDATE_PROFILE = "/updateProfile";
export const PROFILE = "/userDetails";
export const CHANGE_PASSWORD = "/changePassword";
export const PRODUCT = "/product";
export const ADD_FAVOURITES = "/addToFavourites";
export const ALL_LEAFLETS = "/leafletsPaginate";
export const ALL_BANNERS = "/BannersPaginate";
export const VENDORS = "/vendors";
export const FORGOT_PASSWORD = "/forgotPassword";
export const BRANDS = "/brands";

export const Add_SESSION = "/session";
export const CONTACT = "/contact";
export const HOST_COUNTRY = "hostcountry";
export const SORT_LIST = "/sortList";
export const TRIGGER = "/trigger";
