import React from "react";
import { Form } from "react-bootstrap";

const arrowIconSize = {
  width: "15px",
  height: "15px",
};
const CategoryFilter = ({
  setActiveCategory,
  isActiveCategory,
  isActiveSubCategory,
  setIsActiveCategory,
  actualCategories,
  actualSubcategories,
  handleCategories,
  selectedCategories,
  selectedSubCategories,
  setActiveIndex,
  handleSubcategories,
  activeCategory,
  setIsActiveSubcategory,
  actualSubcategoriesCount,
  categoryCount,
}) => {
  return (
    <div className="mt-4 vendors-filter">
      <div className="accordion">
        <div className="accordion-item">
          <div className="accordion-title one">
            <h6>Category</h6>
            <div
              className="main-cat-icon"
              onClick={() => {
                setIsActiveCategory(!isActiveCategory);
              }}
              style={{ cursor: "pointer" }}
            >
              {isActiveCategory || isActiveSubCategory ? (
                <img
                  src="/dist/assets/images/arrow-down.svg"
                  alt="arrow-down"
                  style={arrowIconSize}
                />
              ) : (
                <img
                  src="/dist/assets/images/arrow-up.svg"
                  alt="arrow-up"
                  style={arrowIconSize}
                />
              )}
            </div>
          </div>
          <div className="accordion-content three">
            {isActiveCategory &&
            actualSubcategories &&
            actualSubcategories.length > 0
              ? actualSubcategories.map((item, index) => {
                  return (
                    <>
                      <div className="accordion">
                        <div className="accordion-item">
                          <div className="accordion-title">
                            <div className="acc-label-wrap catfilter">
                              <Form.Check
                                type="checkbox"
                                id={index}
                                label={actualCategories[index]}
                                value={actualCategories[index]}
                                onChange={handleCategories}
                                checked={selectedCategories.includes(
                                  actualCategories[index]
                                )}
                              />
                              <div className="cat-count">
                                (
                                <span>
                                  {categoryCount[actualCategories[index]]
                                    ? categoryCount[actualCategories[index]]
                                        .toString()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                    : "0"}
                                </span>
                                )
                              </div>
                            </div>
                            <div
                              onClick={() => {
                                setActiveIndex(index);
                                setIsActiveSubcategory(!isActiveSubCategory);
                              }}
                              style={{ cursor: "pointer" }}
                              className="cat-icon"
                            >
                              {activeCategory.includes(index) ? (
                                <span
                                  onClick={() => {
                                    let prevCate = [...activeCategory];

                                    prevCate = prevCate.filter(
                                      (t) => t !== index
                                    );

                                    setActiveCategory(prevCate);
                                  }}
                                >
                                  −
                                </span>
                              ) : (
                                <span
                                  onClick={() => {
                                    let prevCate = [...activeCategory];

                                    prevCate.push(index);

                                    setActiveCategory(prevCate);
                                  }}
                                >
                                  +
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="accordion-content">
                            {activeCategory.includes(index)
                              ? item.map((innerItem, idx) => {
                                  return (
                                    <div className="acc-label-wrap catfilter">
                                      <Form.Check
                                        type="checkbox"
                                        id={idx}
                                        label={innerItem}
                                        value={innerItem}
                                        onChange={(e) =>
                                          handleSubcategories(e, index)
                                        }
                                        checked={selectedSubCategories.includes(
                                          innerItem
                                        )}
                                      />
                                      <div className="cat-count sub">
                                        (
                                        <span>
                                          {actualSubcategoriesCount[
                                            actualCategories[index]
                                          ][idx]
                                            ? actualSubcategoriesCount[
                                                actualCategories[index]
                                              ][idx]
                                                .toString()
                                                .replace(
                                                  /\B(?=(\d{3})+(?!\d))/g,
                                                  ","
                                                )
                                            : "0"}
                                        </span>
                                        )
                                      </div>
                                    </div>
                                  );
                                })
                              : ""}
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })
              : ""}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoryFilter;
