import React, { useState, useEffect, useRef } from "react";
import * as images from "../constant/Assets";
import * as routes from "../constant/Routes";
import {
  Col,
  Container,
  Form,
  Row,
  Accordion,
  Carousel,
  Dropdown,
  Modal,
  Button,
  Tooltip,
  Overlay,
} from "react-bootstrap";
import {
  CATEGORIES,
  SUBCATEGORIES,
  ALL_BANNERS,
  VENDORS,
  TRIGGER,
} from "../../redux/reduxConstants/EndPoints";
import api from "../../redux/services/api";
import Pagination from "../../uikit/Paginate";
import useDeviceDetection from "../../hooks/useDeviceDetection";
import { getCookie } from "../../lib/helpers";
import CommunityLoaderCircularDash from "../../uikit/CommunityLoaderCircularDash";
import { TRIGGER_BANNER } from "../constant/Triggers";

const Banner = ({ history }) => {
  const device = useDeviceDetection();

  let fullSlug = "";
  let slugString = "";
  let slug = "";
  let searchArr = "";
  let searchText = "";

  let arrVendor = [];
  let arrCategory = [];
  // let country_id_prev = 1;

  const currentUrl = window.location.href;
  const splitArray = currentUrl.split("/");

  const mykey = "REACT_APP_HOST_COUNTRY_" + splitArray[3].toUpperCase();

  const country_id_prev =
    splitArray && splitArray.length > 4 ? `${process.env[mykey]}` : 1;

  if (window !== undefined && typeof window !== "undefined") {
    const paramArray = window.location.href.split("/");

    fullSlug = paramArray[paramArray.length - 1];

    if (fullSlug.includes("?")) {
      slugString = fullSlug.split("?");
      slug = slugString[1];

      if (slug) {
        searchArr = slug.split("=");
        let searchUrl = searchArr[1];

        if (searchArr[0] == "query" && searchUrl) {
          let searchUrlArr = searchUrl.split("&");
          if (searchUrlArr && searchUrlArr.length > 0) {
            searchText = searchUrlArr[0];
          }
        }
      }
    } else {
      slug = paramArray[paramArray.length - 1];
    }

    const query = new URLSearchParams(history.location.search);

    let vendor = query.get("vendor");

    if (vendor && "" !== vendor) {
      vendor = vendor
        .replace("_", " ")
        .replace("and_", "and ")
        .replace("and", "&");
      arrVendor = vendor.split(",");
    }

    let category = query.get("category");

    if (category && "" !== category) {
      category = category
        .replace("_", " ")
        .replace("and_", "and ")
        .replace("and", "&");

      arrCategory = category.split("|");

      arrCategory = arrCategory.map((item) => {
        if (item.includes("|")) {
          item = item.replace("|", ", ");
        }
        if (item.includes("addcomma")) {
          item = item.replace(" addcomma", ",");
        }
        return item;
      });
    }
  }

  const [banners, setBanners] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [loading, setLoading] = useState(false);

  const [categories, setCategories] = useState([]);
  const [subCategories, setSubcategories] = useState([]);
  const [actualSubcategories, setActualSubCategories] = useState([]);
  const [actualCategories, setActualCategories] = useState([]);

  const [selectedCategories, setSelectedCategories] = useState(
    history &&
      history.location &&
      history.location.state &&
      history.location.state !== undefined &&
      history.location.state.selectedCategory
      ? [history.location.state.selectedCategory]
      : arrCategory
      ? arrCategory
      : []
  );
  const [selectedSubCategories, setSelectedSubCategories] = useState([]);

  const [totalCount, setTotalCount] = useState(0);
  const [vendors, setVendors] = useState([]);

  const [vendorsAll, setVendorsAll] = useState([]);
  const [selectedVendors, setSelectedVendors] = useState(
    arrVendor ? arrVendor : []
  );

  const [results, setResults] = useState([]);
  const [isDataEmpty, setIsDataEmpty] = useState(false);

  const [country_idPrev, setCountryIdPrev] = useState(
    country_id_prev ? country_id_prev : 1
  );
  const [isShowFilter, setIsShowFilter] = useState(true);
  const handleCloseFilter = () => setIsShowFilter(false);
  const handleShowFilter = () => setIsShowFilter(true);

  const [isShowMobFilter, setIsMobShowFilter] = useState(false);
  const handleCloseMobileFilter = () => setIsMobShowFilter(false);
  const handleShowMobileFilter = () => {
    setIsMobShowFilter(true);
  };

  //  for tooltip =/daksha
  const [show1, setShow1] = useState(false);
  const target1 = useRef(null);

  const [show4, setShow4] = useState(false);
  const target4 = useRef(null);

  const [show4m, setShow4m] = useState(false);
  const target4m = useRef(null);

  const prevSelectedCategoriesRef = useRef([]);
  const prevSelectedVendorsRef = useRef([]);
  const prevLimitRef = useRef(limit);

  useEffect(() => {
    document.title = "Banner | Genie Saves";
  }, []);

  useEffect(() => {
    const subCategoriesTemp = [];
    if (subCategories && subCategories.length > 0) {
      subCategories.forEach((item) => {
        const Category = item.Category;
        subCategoriesTemp[Category] = subCategoriesTemp[Category] || [];
        subCategoriesTemp[Category].push(item.Sub_Category);
      });
      const resultKeys = Object.keys(subCategoriesTemp);
      const resultArray = Object.values(subCategoriesTemp);

      setActualSubCategories(resultArray);
      setActualCategories(resultKeys);
    }
  }, [subCategories]);
  const handleCategories = (e) => {
    setPage(1);
    const prevValues = [...selectedCategories];

    if (e.target.checked) {
      prevValues.push(e.target.value);
      setSelectedCategories(prevValues);
    } else {
      const newArray = prevValues.filter((item) => item !== e.target.value);
      setSelectedCategories(newArray);
    }
  };

  const handleSubcategories = (e, key) => {
    const prevValues = [...selectedSubCategories];
    const prevCategories = [...selectedCategories];

    const removeCategory = actualCategories[key];

    if (removeCategory) {
      const newArray = prevCategories.filter((item) => item !== removeCategory);
      setSelectedCategories(newArray);
    }
    if (e.target.checked) {
      prevValues.push(e.target.value);
      setSelectedSubCategories(prevValues);
    } else {
      const newArray = prevValues.filter((item) => item !== e.target.value);
      setSelectedSubCategories(newArray);
    }
  };

  useEffect(() => {
    fetchCategories();
    fetchSubcategories();

    setTimeout(() => {
      fetchBanners();
    }, 1000);

    fetchVendors();
  }, []);

  useEffect(() => {
    if ("Mobile" !== device) {
      const prevCategories = prevSelectedCategoriesRef.current;
      const currentCategories = [...selectedCategories];

      const prevVendors = prevSelectedVendorsRef.current;
      const currentVendors = [...selectedVendors];

      if (
        JSON.stringify(prevCategories) !== JSON.stringify(selectedCategories) ||
        JSON.stringify(prevVendors) !== JSON.stringify(selectedVendors)
      ) {
        setTimeout(() => {
          fetchBanners();
          prevSelectedCategoriesRef.current = currentCategories;
          prevSelectedVendorsRef.current = currentVendors;
          let categoryText = "";
          if (selectedCategories && selectedCategories.length > 0) {
            const newselectedCategories = selectedCategories.map((item) => {
              return item
                .replace(/ & /g, "_and_")
                .replace(/&/g, "and")
                .replace(",", " addcomma");
            });

            let category = newselectedCategories.join("|");

            categoryText = "&category=" + category;
          }

          let vendorText = "";
          if (selectedVendors && selectedVendors.length > 0) {
            const vendor = selectedVendors.join(",");
            vendorText = "&vendor=" + vendor;
          }

          const country_idSelected = getCookie("country_id")
            ? getCookie("country_id")
            : country_id_prev
            ? country_id_prev
            : 1;
          const countryText = "&country_id=" + country_idSelected;

          let url = `?page=1&page_size=${limit}${vendorText}${categoryText}`;
          history.replace({
            pathname: history.location.pathname,
            search: url,
          });
        }, 1000);
      }
    }
  }, [selectedCategories, selectedVendors]);

  useEffect(() => {
    if (prevLimitRef.current !== limit) {
      setTimeout(() => {
        fetchBanners();
        prevLimitRef.current = limit;
        let categoryText = "";
        if (selectedCategories && selectedCategories.length > 0) {
          const newselectedCategories = selectedCategories.map((item) => {
            return item
              .replace(/ & /g, "_and_")
              .replace(/&/g, "and")
              .replace(",", " addcomma");
          });

          let category = newselectedCategories.join("|");

          categoryText = "&category=" + category;
        }

        let vendorText = "";
        if (selectedVendors && selectedVendors.length > 0) {
          const vendor = selectedVendors.join(",");
          vendorText = "&vendor=" + vendor;
        }
        const country_idSelected = getCookie("country_id")
          ? getCookie("country_id")
          : country_id_prev
          ? country_id_prev
          : 1;
        const countryText = "&country_id=" + country_idSelected;

        let url = `?page=1&page_size=${limit}${vendorText}${categoryText}`;
        history.replace({
          pathname: history.location.pathname,
          search: url,
        });
      }, 1000);
    }
  }, [limit]);

  const baseUrl = process.env.REACT_APP_API_BASEURL;

  const fetchVendors = () => {
    const country_id = getCookie("country_id")
      ? getCookie("country_id")
      : country_id_prev
      ? country_id_prev
      : 1;
    api(baseUrl)
      .get(VENDORS + "?category=Banners&country_id=" + country_id)
      .then((res) => {
        if (res.data.success) {
          setVendorsAll(res.data.data);
        }
      })
      .catch((e) => console.log(e));
  };
  const fetchCategories = () => {
    const country_id = getCookie("country_id")
      ? getCookie("country_id")
      : country_id_prev
      ? country_id_prev
      : 1;
    api(baseUrl)
      .get(CATEGORIES + "?country_id=" + country_id)
      .then((res) => {
        if (res.data.success) {
          setCategories(res.data.data);
        }
      })
      .catch((e) => console.log(e));
  };

  const fetchSubcategories = () => {
    const country_id = getCookie("country_id")
      ? getCookie("country_id")
      : country_id_prev
      ? country_id_prev
      : 1;
    api(baseUrl)
      .get(SUBCATEGORIES + "?country_id=" + country_id)
      .then((res) => {
        if (res.data.success) {
          setSubcategories(res.data.data);
        }
      })
      .catch((e) => console.log(e));
  };

  const fetchBanners = () => {
    setLoading(true);

    const offset_rows = (page - 1) * limit;
    let category = "*";
    if (selectedCategories && selectedCategories.length > 0) {
      const newselectedCategories = selectedCategories.map((item) => {
        return item.replace(/ & /g, "_and_").replace(/&/g, "and");
      });
      category = newselectedCategories.join("|");
    }

    setLoading(true);

    let vendor = "*";
    if (selectedVendors && selectedVendors.length > 0) {
      vendor = selectedVendors.join(",");
    }

    const session_id = getCookie("unique_session_id")
      ? getCookie("unique_session_id")
      : null;

    const country_id = getCookie("country_id")
      ? getCookie("country_id")
      : country_id_prev
      ? country_id_prev
      : 1;
    api(baseUrl)
      .get(
        ALL_BANNERS +
          "?num_of_rows_required=" +
          limit +
          "&Start_offset=" +
          offset_rows +
          "&Category=" +
          category +
          "&Vendor=" +
          vendor +
          "&session_id=" +
          session_id +
          "&country_id=" +
          country_id
      )
      .then((res) => {
        setLoading(false);
        if (res.data.success) {
          if (res.data.data.length > 0) {
            setBanners(res.data.data);

            const uniqueIds = res.data.data.map((x) => x.Vendor);

            const uniqueVendors = [...new Set(uniqueIds)];

            setVendors(uniqueVendors);
            let result = [];

            for (const element of res.data.data) {
              if (result[element.Vendor]) {
                result[element.Vendor] = {
                  venodrName: element.Vendor,
                  items: [...result[element.Vendor].items, element],
                };
              } else {
                result[element.Vendor] = {
                  venodrName: element.Vendor,
                  items: [element],
                };
              }
            }

            setResults(Object.values(result));
          } else {
            setIsDataEmpty(true);
            setBanners([]);
            setResults([]);
            setVendors([]);
          }
          setTotalCount(res.data.totalCount);
        }
      })
      .catch((e) => console.log(e));
  };

  const handlePageClick = (currentpage) => {
    window.scroll(0, 0);

    setPage(currentpage.selected + 1);
    const currentPageSelected = currentpage.selected + 1;
    const offset_rows = (currentPageSelected - 1) * limit;

    setLoading(true);
    let category = "*";
    if (selectedCategories && selectedCategories.length > 0) {
      const newselectedCategories = selectedCategories.map((item) => {
        return item.replace(/ & /g, "_and_").replace(/&/g, "and");
      });
      category = newselectedCategories.join("|");
    }
    let vendor = "*";
    if (selectedVendors && selectedVendors.length > 0) {
      vendor = selectedVendors.join(",");
    }

    const session_id = getCookie("unique_session_id")
      ? getCookie("unique_session_id")
      : null;

    const country_id = getCookie("country_id")
      ? getCookie("country_id")
      : country_id_prev
      ? country_id_prev
      : 1;

    api(baseUrl)
      .get(
        ALL_BANNERS +
          "?days_tolerance=-35&num_of_rows_required=" +
          limit +
          "&Start_offset=" +
          offset_rows +
          "&Category=" +
          category +
          "&Vendor=" +
          vendor +
          "&session_id=" +
          session_id +
          "&country_id=" +
          country_id
      )
      .then((res) => {
        setLoading(false);
        if (res.data.success) {
          if (res.data.data.length > 0) {
            setBanners(res.data.data);
            setTotalCount(res.data.totalCount);
            const uniqueIds = res.data.data.map((x) => x.Vendor);

            const uniqueVendors = [...new Set(uniqueIds)];

            setVendors(uniqueVendors);

            let result = [];

            for (const element of res.data.data) {
              if (result[element.Vendor]) {
                result[element.Vendor] = {
                  venodrName: element.Vendor,
                  items: [...result[element.Vendor].items, element],
                };
              } else {
                result[element.Vendor] = {
                  venodrName: element.Vendor,
                  items: [element],
                };
              }
            }

            setResults(Object.values(result));
          } else {
            setIsDataEmpty(true);
            setBanners([]);
            setResults([]);
            setVendors([]);
            setTotalCount(0);
          }
        }
      })
      .catch((e) => console.log(e));
    let categoryText = "";
    if (selectedCategories && selectedCategories.length > 0) {
      const newselectedCategories = selectedCategories.map((item) => {
        return item
          .replace(/ & /g, "_and_")
          .replace(/&/g, "and")
          .replace(",", " addcomma");
      });

      let category = newselectedCategories.join("|");

      categoryText = "&category=" + category;
    }

    let vendorText = "";
    if (selectedVendors && selectedVendors.length > 0) {
      const vendor = selectedVendors.join(",");
      vendorText = "&vendor=" + vendor;
    }
    let url = `?page=${currentPageSelected}&page_size=${limit}${vendorText}${categoryText}`;
    history.replace({
      pathname: history.location.pathname,
      search: url,
    });
  };

  const handleVendor = (e) => {
    // setSelectedVendors([e.target.value]);
    setPage(1);
    const prevValues = [...selectedVendors];

    if (e.target.checked) {
      prevValues.push(e.target.value);
      setSelectedVendors(prevValues);
    } else {
      const newArray = prevValues.filter((item) => item !== e.target.value);
      setSelectedVendors(newArray);
    }
  };

  const handleLink = (item) => {
    handleTrigger(item);
    window.open(item.Banner_Link, "_blank");
  };

  const handleApplyFilter = () => {
    if ("Mobile" === device) {
      handleCloseMobileFilter();
      fetchBanners();

      let categoryText = "";
      if (selectedCategories && selectedCategories.length > 0) {
        const newselectedCategories = selectedCategories.map((item) => {
          return item
            .replace(/ & /g, "_and_")
            .replace(/&/g, "and")
            .replace(",", " addcomma");
        });

        let category = newselectedCategories.join("|");

        categoryText = "&category=" + category;
      }

      let vendorText = "";
      if (selectedVendors && selectedVendors.length > 0) {
        const vendor = selectedVendors.join(",");
        vendorText = "&vendor=" + vendor;
      }
      let url = `?page=1&page_size=${limit}${vendorText}${categoryText}`;
      history.replace({
        pathname: history.location.pathname,
        search: url,
      });
    }
  };

  const handleTrigger = (item) => {
    const user_id = getCookie("user_id");
    const session_id = getCookie("unique_session_id")
      ? getCookie("unique_session_id")
      : null;
    const data = {
      country_id: getCookie("country_id")
        ? getCookie("country_id")
        : country_id_prev
        ? country_id_prev
        : 1,
      user_id: user_id,
      vendor: item.Vendor,
      item_key: item.Item_Key ? item.Item_Key : "",
      session_id: session_id,
      subject: TRIGGER_BANNER,
      external_link: item.Banner_Link,
      vendor_id: item.Vendor_ID,
    };
    api(baseUrl)
      .post(TRIGGER, data)
      .then((res) => {
        if (res.data.success) {
        }
      })
      .catch((e) => console.log(e));
  };

  return (
    <div className="Banners mb-5 mt-nav">
      {loading && <CommunityLoaderCircularDash isbackground={false} />}
      <Container className="mt-5">
        <section id="home__leaflet" className="mt-5 text-center">
          <Row>
            <Col className="d-flex1 justify-content-center align-items-center title-wrap mt-sm-4 mt-2 mb-sm-5 mb-0 col">
              <p>— BANNERS —</p>
              <h1 className="section-title mb-1">
                Explore Ecommerce Banner Offers Across Kuwait in One Place
              </h1>
              <span></span>
            </Col>
          </Row>
          <Row>
            <div className="col-sm-2 mb-3 for-desktop">
              <div className="d-flex align-items-center">
                <button
                  className="btn-simple btn-show-filter"
                  aria-label="show filter"
                  type="button"
                  onClick={() => setIsShowFilter(!isShowFilter)}
                >
                  {isShowFilter ? "Hide Filter" : "Show Filter"}
                </button>

                <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  ref={target1}
                  onClick={() => setShow1(!show1)}
                ></i>
                <Overlay
                  target={target1.current}
                  show={show1}
                  placement="right"
                >
                  {(props) => (
                    <Tooltip id="overlay-example" {...props}>
                      <p align="left">
                        Show Filter: Show the Filter pane, which is currently
                        hidden. With that you will have many different filtering
                        options.<br></br>
                        <br></br>
                        Hide Filter: Hide Filter pane, so that you can see the
                        main list with full page. Note that you can bring back
                        the Filter pane anytime when you click the ‘Show Filter’
                        again.
                      </p>
                    </Tooltip>
                  )}
                </Overlay>
              </div>
            </div>
          </Row>

          <Row className="mt-4">
            {isShowFilter && (
              <section className="col-sm-3 cat-left">
                <section className="cat-for-desktop">
                  <div className="filter-layout">
                    <h6>
                      Vendors with Category{" "}
                      <i
                        className="fa fa-info-circle"
                        aria-hidden="true"
                        ref={target4}
                        onClick={() => setShow4(!show4)}
                      ></i>
                      <Overlay
                        target={target4.current}
                        show={show4}
                        placement="right"
                      >
                        {(props) => (
                          <Tooltip id="overlay-example" {...props}>
                            <p>
                              when you select one (or more) category, the vendor
                              list will be changed with who sells such Category
                              of products. With that the listed items will
                              change accordingly.
                            </p>
                          </Tooltip>
                        )}
                      </Overlay>
                    </h6>
                    {categories && categories.length > 0 ? (
                      <>
                        {categories.map((item, index) => {
                          return (
                            <>
                              <Form.Check
                                type="checkbox"
                                id={index}
                                label={item.Category}
                                value={item.Category}
                                onChange={handleCategories}
                                checked={selectedCategories.includes(
                                  item.Category
                                )}
                              />
                            </>
                          );
                        })}
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="mt-4 vendors-filter">
                    <h6>Vendors</h6>
                    {vendorsAll && vendorsAll.length > 0
                      ? vendorsAll.map((item, index) => {
                          return (
                            <Form.Check
                              type="checkbox"
                              id={index}
                              label={item.Vendor}
                              value={item.Vendor}
                              onChange={handleVendor}
                              checked={selectedVendors.includes(item.Vendor)}
                            />
                          );
                        })
                      : ""}
                  </div>
                </section>
              </section>
            )}

            <section className="cat-for-mobile">
              <div
                className="catfilter-mob mb-4"
                onClick={handleShowMobileFilter}
              >
                <button className="btn" aria-label="show filter image">
                  <img src="dist/assets/images/filter.png"></img> Filter
                </button>
              </div>
              <Modal
                show={isShowMobFilter}
                onHide={handleCloseMobileFilter}
                className="mob-filter-popup"
              >
                <Modal.Header closeButton>
                  <Modal.Title>Filter </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="filter-layout">
                    <h6>
                      Vendors with Category{" "}
                      <i
                        className="fa fa-info-circle"
                        aria-hidden="true"
                        ref={target4m}
                        onClick={() => setShow4m(!show4m)}
                      ></i>
                      <Overlay
                        target={target4m.current}
                        show={show4m}
                        placement="bottom"
                      >
                        {(props) => (
                          <Tooltip id="overlay-example" {...props}>
                            <p>
                              when you select one (or more) category, the vendor
                              list will be changed with who sells such Category
                              of products. With that the listed items will
                              change accordingly.
                            </p>
                          </Tooltip>
                        )}
                      </Overlay>
                    </h6>
                    {categories && categories.length > 0 ? (
                      <>
                        {categories.map((item, index) => {
                          return (
                            <>
                              <Form.Check
                                type="checkbox"
                                id={index}
                                label={item.Category}
                                value={item.Category}
                                onChange={handleCategories}
                                checked={selectedCategories.includes(
                                  item.Category
                                )}
                              />
                            </>
                          );
                        })}
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="mt-4 vendors-filter">
                    <h6>Vendors</h6>
                    {vendorsAll && vendorsAll.length > 0
                      ? vendorsAll.map((item, index) => {
                          return (
                            <Form.Check
                              type="checkbox"
                              id={index}
                              label={item.Vendor}
                              value={item.Vendor}
                              onChange={handleVendor}
                              checked={selectedVendors.includes(item.Vendor)}
                            />
                          );
                        })
                      : ""}
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleApplyFilter}>
                    Apply
                  </Button>
                  <Button variant="secondary" onClick={handleCloseMobileFilter}>
                    Close
                  </Button>
                </Modal.Footer>
              </Modal>
            </section>

            <section className="col-sm-9 banner-list">
              <>
                {results &&
                selectedCategories &&
                selectedCategories.length == 0 &&
                selectedVendors &&
                selectedVendors.length == 0
                  ? results.map((item) => {
                      return (
                        <>
                          <div className="seller-name text-left mb-3">
                            <h3>{item.venodrName}</h3>
                          </div>
                          <Row className="mt-3 mb-5 pb-3 banner-1">
                            <Carousel>
                              {item.items.map((inItem) => {
                                return (
                                  <Carousel.Item>
                                    <a
                                      // href={inItem.Banner_Link}
                                      // target="_blank"
                                      onClick={() => handleLink(inItem)}
                                    >
                                      <img
                                        src={inItem.Banner_Image}
                                        alt="banner"
                                      />
                                    </a>
                                  </Carousel.Item>
                                );
                              })}
                            </Carousel>
                          </Row>
                        </>
                      );
                    })
                  : vendors &&
                    ((selectedCategories && selectedCategories.length !== 0) ||
                      (selectedVendors && selectedVendors.length !== 0)) &&
                    vendors.map((ve) => {
                      return (
                        <div className="inner-wrapper mb-4">
                          <Row>
                            <div className="seller-name text-left mb-3">
                              <h3>{ve}</h3>
                            </div>
                          </Row>
                          <Row>
                            {banners && banners.length > 0
                              ? banners.map((item) => {
                                  return (
                                    <>
                                      {item.Vendor == ve ? (
                                        <div className="col-12 mb-4">
                                          <div
                                            className="seller-banner"
                                            onClick={() => handleLink(item)}
                                          >
                                            <img
                                              src={
                                                item.Banner_Image
                                                  ? item.Banner_Image
                                                  : "/dist/assets/images/banner.png"
                                              }
                                              alt="banner image"
                                            ></img>
                                          </div>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </>
                                  );
                                })
                              : ""}
                          </Row>
                        </div>
                      );
                    })}

                {vendors &&
                  ((selectedCategories && selectedCategories.length !== 0) ||
                    (selectedVendors && selectedVendors.length !== 0)) &&
                  banners &&
                  banners.length == 0 &&
                  isDataEmpty && (
                    <span className="nodata 1">No data found </span>
                  )}

                {results &&
                  selectedCategories &&
                  selectedCategories.length == 0 &&
                  selectedVendors &&
                  selectedVendors.length == 0 &&
                  results &&
                  results.length == 0 &&
                  isDataEmpty && (
                    <span className="nodata 2">No data found</span>
                  )}
                {(selectedCategories && selectedCategories.length !== 0) ||
                (selectedVendors && selectedVendors.length !== 0) ? (
                  <Row className="">
                    <Col md={9} xs={12} className="pagination">
                      <Pagination
                        totalCount={totalCount}
                        limitValue={limit}
                        currentPage={page}
                        handlePageClick={handlePageClick}
                      />
                    </Col>
                    <Col>
                      {totalCount > 20 ? (
                        <Dropdown className="mx-2 d-flex justify-content-end page-size">
                          <span className="text">Page Size</span>
                          <Dropdown.Toggle variant="success" id="dropdown-sort">
                            {limit}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={() => {
                                setPage(1);
                                setLimit(20);
                              }}
                            >
                              20
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => {
                                setPage(1);
                                setLimit(30);
                              }}
                            >
                              30
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => {
                                setPage(1);
                                setLimit(40);
                              }}
                            >
                              40
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => {
                                setPage(1);
                                setLimit(50);
                              }}
                            >
                              50
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => {
                                setPage(1);
                                setLimit(100);
                              }}
                            >
                              100
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      ) : (
                        ""
                      )}
                    </Col>
                  </Row>
                ) : (
                  ""
                )}
              </>
            </section>
          </Row>
        </section>
      </Container>
    </div>
  );
};

export default Banner;
