/**
 * @author Sonali Raut
 */

/**
 * @description Routes File.
 * It include all required routes path constants.
 */

export const ROOT_ROUTE = "/";
export const HOME_ROUTE = "/";
export const SEARCH_ROUTE = "/:countryname/offers";
export const LEAFLETS = "/:countryname/leaflets";
export const BANNERS = "/:countryname/banners";
export const CATEGORY = "/category";
export const LOGIN = "/login";
export const REGISTER = "/register";
export const BASE_PRODUCTDETAIL = "/product-detail";
export const PRODUCTDETAIL =
  "/:countryname/:vendorname/product-detail/:productName/:itemkey";
export const FAVOURITES = "/favourites";
export const MY_PROFILE = "/profile";
export const CHANGE_PASSWORD = "/change-password";
export const FORGOT_PASSWORD = "/forgot-password";
export const CONTACT_US = "/contact-us";
export const ABOUT = "/about-us";
export const PRIVACYPOLICY = "/privacy-policy";
export const TERMSANDCONDITIONS = "/terms-and-conditions";
export const FAQ = "/faq";
export const DISCLAIMER = "/disclaimer";
export const BASE_SEARCH_ROUTE = "/offers";
export const BASE_BANNER_ROUTE = "/banners";
export const BASE_LEAFLET_ROUTE = "/leaflets";
