import React, { useState, useEffect } from "react";
import { Col, Container, Row, Modal, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as routes from "../../constant/Routes";
import * as images from "../../constant/Assets";
import { deleteCookie, getCookie } from "../../../lib/helpers";
import api from "../../../redux/services/api";
import {
  LOGOUT,
  DELETE_ACCOUNT,
  CATEGORIES,
} from "../../../redux/reduxConstants/EndPoints";

const Footer = ({ history }) => {
  const baseUrl = process.env.REACT_APP_API_BASEURL;
  const token = getCookie("token");
  const email = getCookie("email");

  const [show, setShow] = useState(false);

  const handleShow = () => {
    setShowAccount(false);
    setShow(true);
  };

  const handleClose = () => setShow(false);

  const [showAccount, setShowAccount] = useState(false);

  const handleShowAccount = () => setShowAccount(true);
  const handleCloseAccount = () => setShowAccount(false);

  const [showCat, setShowCat] = useState(false);

  const handleCloseCat = () => setShowCat(false);
  const handleShowCat = () => setShowCat(true);

  const [categories, setCategories] = useState([]);

  const country_name = getCookie("country_name")
    ? getCookie("country_name")
    : "kuwait";
  const links = [
    // {
    //   name: "Home",
    //   link: routes.HOME_ROUTE,
    // },
    // {
    //   name: "Search",
    //   link: routes.SEARCH_ROUTE,
    // },

    // {
    //   name: "Banners",
    //   link: routes.BANNERS,
    // },
    // {
    //   name: "Leaflets",
    //   link: routes.LEAFLETS,
    // },

    {
      name: "About",
      link: routes.ABOUT,
    },
    // {
    //   name: "FAQ",
    //   link: routes.FAQ,
    // },
    {
      name: "Contact",
      link: routes.CONTACT_US,
    },
    {
      name: "Privacy Policy",
      link: routes.PRIVACYPOLICY,
    },
    {
      name: "Terms & Conditions",
      link: routes.TERMSANDCONDITIONS,
    },
    {
      name: "Disclaimer",
      link: routes.DISCLAIMER,
    },
  ];

  const linktwo = [
    // {
    //   name: "Privacy Policy",
    //   link: routes.PRIVACYPOLICY,
    // },
    // {
    //   name: "Terms and conditions",
    //   link: routes.TERMSANDCONDITIONS,
    // },
  ];

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = () => {
    const country_id = getCookie("country_id") ? getCookie("country_id") : 1;
    api(baseUrl)
      .get(CATEGORIES + "?country_id=" + country_id)
      .then((res) => {
        if (res.data.success) {
          setCategories(res.data.data);
        }
      })
      .catch((e) => console.log(e));
  };

  const handleLogout = () => {
    const headers = {
      Authorization: "Bearer " + token,
    };
    api(baseUrl, headers)
      .get(LOGOUT)
      .then((res) => {
        if (res.data.success) {
          deleteCookie("token");
          deleteCookie("user_id");
          deleteCookie("email");
          window.location.replace(routes.HOME_ROUTE);
        }
      })
      .catch((e) => console.log(e));
  };

  const deleteAccount = () => {
    handleClose();
    const headers = {
      Authorization: "Bearer " + token,
    };
    api(baseUrl, headers)
      .post(DELETE_ACCOUNT, { email })
      .then((res) => {
        if (res.data.success) {
          deleteCookie("token");
          deleteCookie("user_id");
          deleteCookie("email");
          window.location.replace(routes.HOME_ROUTE);
        }
      })
      .catch((e) => console.log(e));
  };

  const handleCategorySearch = (item) => {
    handleCloseCat();
    window.open(
      country_name +
        routes.BASE_SEARCH_ROUTE +
        `?category=${item.Category.replace(/ & /g, "_and_").replace(
          /&/g,
          "and"
        )}`,
      "_self"
    );
  };
  const footerlogowidth = {
    width: "100px",
    height: "auto",
  };

  const socialMediaIcons = {
    width: "20px",
    height: "auto",
  };
  const socialMediaLindedin = {
    width: "27px",
    height: "auto",
  };
  return (
    <>
      <div className="footer-section">
        <Container>
          <Row>
            <div className="col-12 col-sm-3 footer-logo">
              <img
                src={`${process.env.REACT_APP_HOSTNAME}/dist/assets/images/genie-saves-logo2.svg`}
                alt="footer logo"
                style={footerlogowidth}
              />
              <ul className="social-media mt-3">
                <li>
                  <a
                    href="https://www.instagram.com/geniesaves/"
                    aria-label="link for instagram"
                    target="_blank"
                  >
                    <i className="fa fa-instagram" aria-hidden="true"></i>
                    <img
                      src="/dist/assets/images/icon-insta.svg"
                      alt="instagram icon"
                      style={socialMediaIcons}
                    />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.facebook.com/geniesaves/"
                    aria-label="link for fb"
                    target="_blank"
                  >
                    {/* <i className="fa fa-facebook" aria-hidden="true"></i> */}
                    <img
                      src="/dist/assets/images/icon-fb.svg"
                      alt="fv icon"
                      style={socialMediaIcons}
                    />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/company/genie-saves/"
                    aria-label="link for linkedin"
                    target="_blank"
                  >
                    {/* <i className="fa fa-twitter" aria-hidden="true"></i> */}
                    <img
                      src="/dist/assets/images/icons-linkedin.svg"
                      alt="linkedin icon"
                      style={socialMediaLindedin}
                    />
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-sm-9 footer-menu">
              <ul>
                {links &&
                  links.map((item) => {
                    return (
                      <li>
                        <a href={item.link} key={item.name + "footer"}>
                          {item.name}
                        </a>
                      </li>
                    );
                  })}
              </ul>
            </div>
            {/* <div className="col-sm-3 footer-menu two">
              <ul>
                {linktwo &&
                  linktwo.map((item) => {
                    return (
                      <li>
                        <a href={item.link} key={item.name + "footer"}>
                          {item.name}
                        </a>
                      </li>
                    );
                  })}
              </ul>
            </div> */}
          </Row>
        </Container>
      </div>

      <div className="copywrite">
        <Container>
          <Row>
            <Col>© 2024 Genie Saves. All rights reserved.</Col>
          </Row>
        </Container>
      </div>

      <div className="mobile-menu">
        <Container>
          <Row>
            <Col className="text-center">
              <a href={routes.ROOT_ROUTE}>
                <img src="/dist/assets/images/m-home.png" alt="img" />
                <p>Home</p>
              </a>
            </Col>
            <Col className="text-center">
              <a onClick={handleShowCat}>
                <img src="/dist/assets/images/m-cat.png" alt="img" />
                <p>Category</p>
              </a>
            </Col>
            <Col className="text-center">
              <a href={country_name + routes.BASE_SEARCH_ROUTE}>
                <img src="/dist/assets/images/m-search.png" alt="img" />
                <p>Search</p>
              </a>
            </Col>
            <Col className="text-center">
              {!token ? (
                <a href={routes.LOGIN}>
                  <img src="/dist/assets/images/m-user.png" alt="img" />
                  <p>Login</p>
                </a>
              ) : (
                <a onClick={handleShowAccount}>
                  <img src="/dist/assets/images/m-user.png" alt="img" />
                  <p>My Account</p>
                </a>
              )}
            </Col>
          </Row>
        </Container>
      </div>

      <Modal show={showCat} onHide={handleCloseCat} className="mobile-menu-cat">
        <Modal.Header closeButton>
          <Modal.Title>Categories List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="all-categories-list" id="categories">
            <ul className="row">
              {categories.map((item, index) => {
                const imageName = item.Category.replace(",", "")
                  .replace(" ", "_")
                  .replace("&", "and")
                  .replace(" ", "_")
                  .replace(" ", "_")
                  .replace(",", "")
                  .replace(".", "");
                return (
                  <li
                    className="item col-3"
                    onClick={() => handleCategorySearch(item)}
                    style={{ cursor: "pointer" }}
                    key={"category_modal" + index}
                  >
                    <div className="cat-item mobile">
                      <div className="cat-img">
                        <img
                          src={
                            imageName && images[imageName]
                              ? images[imageName]
                              : `${process.env.REACT_APP_HOSTNAME}/dist/assets/images/Smartphones,_Tablets_&_Wearables.svg`
                          }
                          alt="img"
                        />
                      </div>
                      <div className="cat-txt">
                        <span>{item.Category}</span>
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseCat}>
            Close
          </Button>
        </Modal.Footer> */}
      </Modal>

      <Modal show={show} onHide={handleClose} className="postFullscreen">
        <Modal.Header closeButton>
          <Modal.Title>Delete Account</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>Are you sure to delete account?</div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={deleteAccount}>
            Delete
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showAccount}
        onHide={handleCloseAccount}
        className="postFullscreen my-account-mobile-popup"
      >
        <Modal.Header closeButton>
          <Modal.Title>My Account</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <ul>
              <li>
                <a href={routes.MY_PROFILE}>My Profile</a>
              </li>
              <li>
                <a href={routes.FAVOURITES}>My Favourites</a>
              </li>
              <li>
                <a href={routes.CHANGE_PASSWORD}>Change Password</a>
              </li>
              <li>
                <a onClick={handleShow}>Unregister</a>
              </li>
              <li>
                <a onClick={handleLogout}>Logout</a>
              </li>
            </ul>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Footer;
