/**
 * @author Sonali Raut
 */
// Importing React's component
import React from "react";
import { Link } from "react-router-dom";
import * as routes from "../../constant/Routes";
import {
  Col,
  Container,
  Form,
  Row,
  Dropdown,
  Modal,
  Button,
  Tooltip,
  Overlay,
} from "react-bootstrap";

/**
 * @description This UI Component for NoPageFound.
 */

export const NoPageFound = ({ isFor }) => (
  <div className="no-page-found" id="mainContainer" data-testid="no-page-found">
    <div className="messg-info mb-5 mt-nav">
      <Container className="mt-5">
        <Row>
          <Col className="text-center">
            <h1 className="error-title">404</h1>
            <h4>Ooops!</h4>
            <p>
              We can't find the page
              <span>you are looking for</span>
            </p>
            <Link to={routes.HOME_ROUTE}>
              <button className="btn btn-custom mt-5">Back to Homepage</button>{" "}
            </Link>
          </Col>
        </Row>
      </Container>
    </div>
  </div>
);

export default NoPageFound;
