import React from "react";
import { Form } from "react-bootstrap";

const OtherFilter = ({
  exclude_accessory,
  handleExcludeAccessory,
  only_discounted,
  handleOnlyDiscounted,
  available_only,
  handleOnlyAvailable,
  excludedCount,
  availableOnlyCount,
  discountedCount,
  priceDroppedOnlyCount,
  handleOnlyPriceDropped,
  only_pricedroped,
}) => {
  return (
    <div className="mt-4 filter-layout">
      <div className="mt-0">
        <div className="acc-label-wrap">
          <Form.Check
            type="checkbox"
            id="exclude_accessory"
            label="Exclude Accessory"
            value={exclude_accessory}
            onChange={handleExcludeAccessory}
            checked={exclude_accessory}
          />
          <div className="cat-count">
            (
            <span>
              {excludedCount
                ? excludedCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                : 0}
            </span>
            )
          </div>
        </div>
      </div>
      <div className="mt-4">
        <div className="acc-label-wrap">
          <Form.Check
            type="checkbox"
            id="only_discounted"
            label="Only Discounted"
            value={only_discounted}
            onChange={handleOnlyDiscounted}
            checked={only_discounted}
          />
          <div className="cat-count">
            (
            <span>
              {discountedCount
                ? discountedCount
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                : 0}
            </span>
            )
          </div>
        </div>
      </div>
      <div className="mt-4">
        <div className="acc-label-wrap">
          <Form.Check
            type="checkbox"
            id="available_only"
            label="Available Only"
            value={available_only}
            onChange={handleOnlyAvailable}
            checked={available_only}
          />
          <div className="cat-count">
            (
            <span>
              {availableOnlyCount
                ? availableOnlyCount
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                : 0}
            </span>
            )
          </div>
        </div>
      </div>
      <div className="mt-4">
        <div className="acc-label-wrap">
          <Form.Check
            type="checkbox"
            id="only_pricedroped"
            label="Price Drops"
            value={only_pricedroped}
            onChange={handleOnlyPriceDropped}
            checked={only_pricedroped}
          />
          <div className="cat-count">
            (
            <span>
              {priceDroppedOnlyCount
                ? priceDroppedOnlyCount
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                : 0}
            </span>
            )
          </div>
        </div>
      </div>
    </div>
  );
};

export default OtherFilter;
