const imageBasePath = process.env.REACT_APP_CDN_URL; //"/assets/images"

export const homeLeafletImage = `${imageBasePath}/placeholder-image.png`;
export const homeBannerImage = `${imageBasePath}/banner-placeholder.png`;
export const searchImage = `${imageBasePath}/search.svg`;
export const LogoImage = `${imageBasePath}/logo.png`;
export const SearchBack = `${imageBasePath}/search-black.svg`;
export const vendor = `${imageBasePath}/vendor.png`;

export const hakim = `${imageBasePath}/Hakim.png`;
export const hakimicomputers = `${imageBasePath}/HakimiComputers.png`;
export const best = `${imageBasePath}/Best.png`;
export const carrefour = `${imageBasePath}/Carrefour.png`;
export const citycenter = `${imageBasePath}/CityCenter.png`;
export const eureka = `${imageBasePath}/Eureka.jpg`;
export const grandhyper = `${imageBasePath}/GrandHyper.png`;

export const jarir = `${imageBasePath}/Jarir Book Store.png`;
export const lulu = `${imageBasePath}/Lulu.jpg`;
export const sultan = `${imageBasePath}/Sultan Center.png`;
export const xcite = `${imageBasePath}/Xcite.jpg`;

export const alphastore = `${imageBasePath}/AlphaStore.jpg`;
export const bloomingdales = `${imageBasePath}/Bloomingdales.jpg`;
export const gait = `${imageBasePath}/GaitKuwait.png`;
export const microless = `${imageBasePath}/microless.jpg`;
export const mobile2000 = `${imageBasePath}/Mobile2000.png`;
export const ounass = `${imageBasePath}/Ounass.png`;
export const perfumeskuwaigt = `${imageBasePath}/PerfumesKuwaigt.jpg`;
export const taw9eel = `${imageBasePath}/Taw9eel.png`;

export const Smartphones_Tablets_and_Wearables = `${imageBasePath}/category-icons/Smartphones,_Tablets_&_Wearables.svg`;
export const SmartPhone_and_Gadgets = `${imageBasePath}/category-icons/Smartphones,_Tablets_&_Wearables.svg`;
export const Fresh_Foods = `${imageBasePath}/category-icons/Fresh_Foods.svg`;
export const Fashion_and_Clothing = `${imageBasePath}/category-icons/Fashion_and_Clothing.svg`;
export const Personal_Care = `${imageBasePath}/category-icons/Personal_Care.svg`;
export const Health_and_Beauty = `${imageBasePath}/category-icons/Health_&_Beauty.svg`;
export const Appliances = `${imageBasePath}/category-icons/Appliances.svg`;
export const Books_Art_and_Craft = `${imageBasePath}/category-icons/Books_Art_&_Craft.svg`;
export const Computers_Laptops_and_Accessories = `${imageBasePath}/category-icons/Computers_Laptops_&_Accessories.svg`;
export const Computers_Laptop_etc = `${imageBasePath}/category-icons/Computers_Laptops_&_Accessories.svg`;
export const Grocery = `${imageBasePath}/category-icons/Grocery.svg`;
export const Home_Entertinements = `${imageBasePath}/category-icons/Home_Entertinements.svg`;
export const Office_and_School_Needs = `${imageBasePath}/category-icons/Office_&_School_Needs.svg`;
export const Home_Needs = `${imageBasePath}/category-icons/Home_Needs.svg`;
export const Pet_Care = `${imageBasePath}/category-icons/Pet_Care.svg`;
export const Baby_Care = `${imageBasePath}/category-icons/Baby_Care.svg`;
export const sharePost = `${imageBasePath}/timeline-sharepost.svg`;

export const filter = `${imageBasePath}/filter.png`;
export const contact = `${imageBasePath}/contact.png`;
