import React from "react";
import { Col, Row, Dropdown } from "react-bootstrap";
import FavIcon from "../icon/FavIcon";
import Pagination from "../../uikit/Paginate";
import * as routes from "../constant/Routes";
import * as images from "../constant/Assets";
import ProductItem from "./ProductItem";

const ProductList = ({
  productList,
  handleAddToFavourites,
  handleRemoveFavourites,
  handleProductDetail,
  totalCount,
  limit,
  page,
  handlePageClick,
  setLimit,
}) => {
  return (
    <section className="cat-products item-design">
      <Row>
        {productList &&
          productList.map((item, index) => {
            let vendorName = item.Vendor.replace(" ", "-").toLowerCase();

            return (
              <div
                className="col-6 col-sm-3 mb-4"
                style={{
                  cursor: "pointer",
                }}
                key={"result" + index}
              >
                {/* <div className="item">
                  <div className="main-item-wrap">
                    <div className="img-wrap">
                      {item.Discount_Percent > 0 ? (
                        <div className="offer-tag">
                          {item.Discount_Percent > 0
                            ? Math.floor(item.Discount_Percent)
                            : 0}
                          % OFF
                        </div>
                      ) : (
                        ""
                      )}

                      <FavIcon
                        product={item}
                        handleAddToFavourites={handleAddToFavourites}
                        handleRemoveFavourites={handleRemoveFavourites}
                      />
                      <a
                        href={
                          routes.PRODUCTDETAIL +
                          "?Vendor=" +
                          item.Vendor +
                          "&Item_Key=" +
                          item.Item_Key
                        }
                        target="_blank"
                      >
                        <img
                          src={item.Item_Image_URL}
                          alt="img"
                          className="img-fluid"
                        />
                      </a>
                    </div>
                    <div
                      className="item-desc"
                      onClick={() => handleProductDetail(item)}
                    >
                      <img
                        src={
                          item.Vendor
                            ? images[vendorName]
                            : "./dist/assets/images/default-logo-sm.png"
                        }
                        alt="img"
                      />
                      <h5>{item.Brand}</h5>
                      <p>{item.Item_name}</p>
                    </div>
                    <div
                      className="price"
                      onClick={() => handleProductDetail(item)}
                    >
                      <span>KD {item.Selling_Price} </span>
                      {item.Discount_Percent > 0 ? (
                        <small>
                          <strike>{item.Regular_Price}</strike>
                        </small>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div> */}
                <ProductItem
                  item={item}
                  handleAddToFavourites={handleAddToFavourites}
                  handleRemoveFavourites={handleRemoveFavourites}
                  vendorName={vendorName}
                  isFromHome={false}
                />
              </div>
            );
          })}
      </Row>
      <Row className="">
        <Col md={9} xs={12} className="pagination">
          <Pagination
            totalCount={totalCount}
            limitValue={limit}
            currentPage={page}
            handlePageClick={handlePageClick}
          />
        </Col>
        <Col>
          {totalCount > 20 ? (
            <Dropdown className="mx-2 d-flex justify-content-end page-size">
              <span className="text">Page Size</span>
              <Dropdown.Toggle variant="success" id="dropdown-sort">
                {limit}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => setLimit(20)}>20</Dropdown.Item>
                <Dropdown.Item onClick={() => setLimit(40)}>40</Dropdown.Item>
                <Dropdown.Item onClick={() => setLimit(60)}>60</Dropdown.Item>
                <Dropdown.Item onClick={() => setLimit(80)}>80</Dropdown.Item>
                <Dropdown.Item onClick={() => setLimit(100)}>100</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          ) : (
            ""
          )}
        </Col>
      </Row>
    </section>
  );
};

export default ProductList;
