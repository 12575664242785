import React from "react";
import { Form } from "react-bootstrap";

const arrowIconSize = {
  width: "15px",
  height: "15px",
};

const VendorFilter = ({
  isActive,
  setIsActive,
  vendors,
  handleVendor,
  selectedVendors,
}) => {
  return (
    <div className="mt-4 vendors-filter">
      <div className="accordion">
        <div className="accordion-item">
          <div className="accordion-title 4">
            <h6>Vendors</h6>
            <div
              onClick={() => setIsActive(!isActive)}
              style={{ cursor: "pointer" }}
            >
              {isActive ? (
                // <i className="fa fa-angle-down" aria-hidden="true"></i>
                <img
                  src="/dist/assets/images/arrow-down.svg"
                  alt="arrow-down"
                  style={arrowIconSize}
                />
              ) : (
                <img
                  src="/dist/assets/images/arrow-up.svg"
                  alt="arrow-up"
                  style={arrowIconSize}
                />
              )}
            </div>
          </div>
          <div className="accordion-content">
            {isActive && vendors && vendors.length > 0
              ? vendors.map((item, index) => {
                  return (
                    <div className="acc-label-wrap">
                      <Form.Check
                        type="checkbox"
                        id={index}
                        label={item.Name}
                        value={item.Name}
                        onChange={handleVendor}
                        checked={selectedVendors.includes(item.Name)}
                      />
                      <div className="cat-count">
                        (
                        <span>
                          {item &&
                            item.Num_of_Items &&
                            item.Num_of_Items.toString().replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            )}
                        </span>
                        )
                      </div>
                    </div>
                  );
                })
              : ""}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VendorFilter;
