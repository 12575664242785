import React from "react";

const FavIcon = ({
  product,
  handleAddToFavourites,
  handleRemoveFavourites,
}) => {
  return (
    <div
      className="heart-icon"
      onClick={() =>
        product.hasOwnProperty("is_a_Favorite") &&
        product.is_a_Favorite !== "0" &&
        product.is_a_Favorite !== null
          ? handleRemoveFavourites(product)
          : handleAddToFavourites(product)
      }
    >
      {product.hasOwnProperty("is_a_Favorite") &&
      product.is_a_Favorite !== "0" &&
      product.is_a_Favorite !== null ? (
        <i className="fa fa-heart" aria-hidden="true"></i>
      ) : (
        <i className="fa fa-heart-o" aria-hidden="true"></i>
      )}
    </div>
  );
};
export default FavIcon;
