import React, { useEffect, useState, useRef } from "react";
import * as images from "../constant/Assets";
import moment from "moment";
import { TRIGGER_LEAFLET } from "../constant/Triggers";
import api from "../../redux/services/api";

import { TRIGGER } from "../../redux/reduxConstants/EndPoints";
import { getCookie } from "../../lib/helpers";

const LeafletItem = ({ item, vendorName, history, index }) => {
  const baseUrl = process.env.REACT_APP_API_BASEURL;

  const handleTrigger = (item) => {
    const user_id = getCookie("user_id");
    const session_id = getCookie("unique_session_id")
      ? getCookie("unique_session_id")
      : null;
    const data = {
      country_id: getCookie("country_id") ? getCookie("country_id") : 1,
      user_id: user_id,
      vendor: item.vendor,
      item_key: item.Item_Key ? item.Item_Key : "",
      session_id: session_id,
      subject: TRIGGER_LEAFLET,
      external_link: item.leaflet_link,
      vendor_id: item.Vendor_ID,
    };
    api(baseUrl)
      .post(TRIGGER, data)
      .then((res) => {
        if (res.data.success) {
        }
      })
      .catch((e) => console.log(e));
  };

  const handleClick = (item) => {
    handleTrigger(item);
    window.open(item.leaflet_link, "_blank");
  };

  return (
    <div className="main-item-wrap" key={item.leaflet_name + index}>
      <div className="item-wrap" onClick={() => handleClick(item)}>
        <div className="img-wrap">
          <img
            src={
              item.leaflet_image && "" !== item.leaflet_image
                ? item.leaflet_image
                : images.homeLeafletImage
            }
            alt="img"
            loading="lazy"
          />
        </div>
        <div className="item-desc">
          <div className="vendor-logo">
            <img
              src={
                item.Vendor_logo
                  ? item.Vendor_logo
                  : `${process.env.REACT_APP_HOSTNAME}/dist/assets/images/v2.png`
              }
              alt="img"
            />
          </div>
          <h5>{item.leaflet_name}</h5>
          {"" !== item.offer_start_date && moment(item.offer_start_date) ? (
            <offerdate>
              Offer date: {moment(item.offer_start_date).format("DD/MM/YYYY")}{" "}
              to {moment(item.offer_end_date).format("DD/MM/YYYY")}
            </offerdate>
          ) : (
            <p></p>
          )}
          {/* <p>Created: {moment(item.data_asof).format("MM/DD/YYYY")}</p> */}
        </div>
        <div className="view">
          <span>
            <a
            // href={item.leaflet_link} target="_blank"
            >
              View Leaflet
            </a>

            <img
              src={`${process.env.REACT_APP_HOSTNAME}/dist/assets/images/arrow.svg`}
              alt="arrow"
              className="angle-right-icon"
            />
          </span>
        </div>
      </div>
    </div>
  );
};

export default LeafletItem;
